import * as React from "react";

function Icon(props) {
  return (
    <svg
      width={22}
      height={14}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M14.892 13.774l-1.558-1.55 4.088-4.08H.542v-2.27h16.88l-4.088-4.087L14.892.243l6.765 6.766-6.765 6.765z"
        fill="#0C1525"
      />
    </svg>
  );
}

export default Icon;