import * as React from "react";

function Icon(props) {
  return (
    <svg
      width={17}
      height={14}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.39 13.209c5.896 0 9.121-4.81 9.121-8.98 0-.137 0-.273-.01-.408.627-.446 1.168-1 1.6-1.635a6.464 6.464 0 01-1.84.497A3.179 3.179 0 0015.67.937a6.487 6.487 0 01-2.036.766 3.245 3.245 0 00-4.536-.137 3.13 3.13 0 00-.927 3.016 9.154 9.154 0 01-6.607-3.297 3.129 3.129 0 00.993 4.212A3.22 3.22 0 011.1 5.103v.04c.001 1.502 1.077 2.796 2.572 3.093a3.238 3.238 0 01-1.447.054 3.203 3.203 0 002.995 2.192 6.496 6.496 0 01-4.745 1.31 9.183 9.183 0 004.914 1.415"
        fill="#1DA1F2"
      />
    </svg>
  );
}

export default Icon;