import React from 'react';
import {uid} from 'react-uid';
import styles from './Action.module.css';

export const Action = (props) => {
  //props.array props.booleans
  let list = (
    <div className={styles.wrapper}>
    </div>
  );
  const options = [];
  for(let i=0; i<props.items.length; i++){
    options.push(
      <div onClick={()=>{props.items[i].func?props.items[i].func(props.index):console.log('nope')}} key={uid(i)} className={styles.option}>
          {props.items[i].image}
          <span className={styles.span}>{props.items[i].text}</span>
        </div>
    );
    list = (
      <div className={styles.Action} id='action'>
          <div className={styles.wrapper}>
            {options}
          </div>
      </div>
    );
  }
  return <div>{list}</div>;
}
