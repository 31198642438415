import React from 'react';
import styles from './Attention.module.css';

export const Attention = (props) => {
  return (
    <div className={styles.Attention}>
        {props.icon}
        <div className={styles.content}>
            {props.content}
        </div>
    </div>
  )
}
