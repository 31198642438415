import * as React from "react";

function Icon(props) {
  return (
    <svg
      width={26}
      height={25}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect
        x={0.475}
        y={0.339}
        width={24.53}
        height={24.53}
        rx={8}
        fill="#1DA1F2"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.615 17.185c4.322 0 6.686-3.525 6.686-6.582 0-.1 0-.2-.007-.3a4.733 4.733 0 001.172-1.198c-.43.188-.884.31-1.35.365a2.33 2.33 0 001.034-1.28c-.462.27-.967.46-1.492.561a2.378 2.378 0 00-3.325-.1 2.295 2.295 0 00-.68 2.21 6.71 6.71 0 01-4.842-2.416 2.293 2.293 0 00.728 3.088c-.374-.011-.74-.11-1.067-.29v.03c0 1.1.789 2.049 1.885 2.267a2.375 2.375 0 01-1.06.04 2.348 2.348 0 002.194 1.606 4.761 4.761 0 01-3.477.96 6.731 6.731 0 003.601 1.037"
        fill="#fff"
      />
    </svg>
  );
}

export default Icon;
