import * as React from "react";

function Icon(props) {
  return (
    <svg
      width={14}
      height={16}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M4.682 6.096a.5.5 0 01.5.5v6a.5.5 0 01-1 0v-6a.5.5 0 01.5-.5zm2.5 0a.5.5 0 01.5.5v6a.5.5 0 01-1 0v-6a.5.5 0 01.5-.5zm3 .5a.5.5 0 10-1 0v6a.5.5 0 001 0v-6z"
        fill="#FF3F19"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.682 3.596a1 1 0 01-1 1h-.5v9a2 2 0 01-2 2h-6a2 2 0 01-2-2v-9h-.5a1 1 0 01-1-1v-1a1 1 0 011-1h3.5a1 1 0 011-1h2a1 1 0 011 1h3.5a1 1 0 011 1v1zm-10.382 1l-.118.06v8.94a1 1 0 001 1h6a1 1 0 001-1v-8.94l-.118-.06H3.3zm-1.618-1v-1h11v1h-11z"
        fill="#FF3F19"
      />
    </svg>
  );
}

export default Icon;