import * as React from "react";

function Icon(props) {
  return (
    <svg
      width={22}
      height={34}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M11.268 33.755c-5.792 0-10.502-4.71-10.502-10.502V8.551a1.4 1.4 0 112.8 0v14.702c0 4.246 3.455 7.702 7.702 7.702s7.701-3.456 7.701-7.702V7.85c0-2.702-2.198-4.9-4.9-4.9a4.906 4.906 0 00-4.902 4.9v14.003c0 1.158.943 2.1 2.1 2.1 1.159 0 2.101-.942 2.101-2.1V8.551a1.4 1.4 0 112.8 0v13.302c0 2.703-2.198 4.9-4.9 4.9a4.906 4.906 0 01-4.901-4.9V7.85C6.367 3.605 9.82.15 14.068.15S21.77 3.605 21.77 7.85v15.403c0 5.792-4.71 10.502-10.502 10.502z"
        fill="#0C1525"
        fillOpacity={0.25}
      />
    </svg>
  );
}

export default Icon;