import React from 'react';
import styles from './MainInfo.module.css';
import ReactMde from "react-mde";
import * as Showdown from "showdown";
import "react-mde/lib/styles/css/react-mde-all.css";
import "./mde-styles.css";
import {uid} from 'react-uid';

function loadSuggestions(text) {
    return new Promise((accept, reject) => {
      setTimeout(() => {
        const suggestions = [].filter((i) => i.preview.toLowerCase().includes(text.toLowerCase()));
        accept(suggestions);
      }, 250);
    });
}
const converter = new Showdown.Converter({
    tables: true,
    simplifiedAutoLink: true,
    strikethrough: true,
    tasklists: true
});

export const MainInfo = (props) => {
    const errors = props.errors;
    const register = props.register;
    const [description, setDescription] = [props.description, props.setDescription];
    const [selectedTab, setSelectedTab] = React.useState("write");
    const currencies = [
        'USD', 'EUR', 'JPY', 'GBP', 'AUD', 'CAD', 'CHF', 'CNY', 'SEK', 'NZD',
        'PLN', 'ILS', 'HKD', 'ISK', 'PHP', 'DKK', 'HUF', 'CZK', 'RON', 'IDR',
        'INR', 'BRL', 'RUB', 'HRK', 'THB', 'MYR', 'BGN', 'NOK', 'ZAR', 'MXN',
        'SGD', 'KRW'
    ];
    const currencyOptions = currencies.map((item)=>{
        return <option key={uid(item)} value={item} >{item}</option>
    });
    return (
        <div className={styles.MainInfo}>
            <div className={styles.mainInfo}>
                <div className={styles.title}>
                    <h3 className={styles.h3}>Title</h3>
                    <input {...register("title", { required: true })} maxLength="64" defaultValue={props.product?props.product.title:''} placeholder='Product title' className={`${errors.title && 'error'} ${styles.input}`} />
                    {errors.title && <p>Title is required</p>}
                </div>
                <div className={styles.price}>
                    <h3 className={styles.h3}>Price</h3>
                    <div className={`${errors.price && 'error'} ${styles.priceInput}`}>
                        <select {...register('currency')}>
                            {currencyOptions}
                        </select>
                        <input type='number' {...register("price", { valueAsNumber: true, required: true})} placeholder='0.00' step="0.01" min="0" max="99999999" className={styles.input} />
                    </div>
                    {errors.price && <p>Price is required</p>}
                </div>
            </div>
            <div className={`${description==='' && styles.error} ${styles.editor}`}>
                <h3 className={styles.h3}>Description</h3>
                <ReactMde
                    value={description}
                    onChange={setDescription}
                    selectedTab={selectedTab}
                    onTabChange={setSelectedTab}
                    generateMarkdownPreview={(markdown) =>
                        Promise.resolve(converter.makeHtml(markdown))
                    }
                    loadSuggestions={loadSuggestions}
                    childProps={{
                        writeButton: {
                            tabIndex: -1
                        }
                    }}
                />
            </div>
            {description==='' && <p className={styles.pError}>Description is required</p>}
        </div>
    )
}
