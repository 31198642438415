import * as React from "react";

function Icon(props) {
  return (
    <svg
      width={12}
      height={13}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M6.755 1.574L7.97.734l3.645 5.73-3.645 5.685-1.215-.84 2.7-4.155H.275V5.699h9.15l-2.67-4.125z"
        fill="#0C1525"
      />
    </svg>
  );
}

export default Icon;
