import React from 'react';
import styles from './Heading.module.css';
import { Link } from 'react-router-dom';

export const Heading = (props) => {
    let title = (props.title!=='Dashboard'?(
        <div>
            <h1 className={styles.h1}>{props.pathTitle?props.pathTitle:props.title}</h1>
            <h2 className={styles.h2}><Link to='/'>Dashboard</Link>&nbsp;/&nbsp;{<Link to={props.link?props.link:'/'}>{props.title}</Link>}&nbsp;{`${props.pathTitle?' / '+props.pathTitle:''}`}</h2>
        </div>
    )
    :(
        <div>
            <h1 className={styles.h1}>{props.title}</h1>
        </div>
    )
    );
  return (
    <div className={styles.Heading}>
        {title}
    </div>
  )
}
