import * as React from "react";

function Icon(props) {
  return (
    <svg
      width={11}
      height={17}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M3.157.413a.5.5 0 01.48-.36h4a.5.5 0 01.474.659l-1.78 5.342h3.806a.5.5 0 01.395.807l-7 9a.5.5 0 01-.873-.454L4.46 9.554H1.137a.5.5 0 01-.48-.641l2.5-8.5z"
        fill="#FF3F19"
      />
    </svg>
  );
}

export default Icon;
