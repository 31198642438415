import * as React from "react";

function Icon(props) {
  return (
    <svg
      width={18}
      height={18}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M7.421.446a.849.849 0 01.089.948 7.967 7.967 0 00-.97 3.825c0 4.444 3.622 8.043 8.088 8.043.583 0 1.15-.06 1.695-.177a.87.87 0 01.895.35.81.81 0 01-.034.987 9.227 9.227 0 01-7.172 3.402C4.916 17.824.79 13.72.79 8.661c0-3.807 2.337-7.072 5.664-8.456a.831.831 0 01.968.24zM6.16 1.588A8.035 8.035 0 001.922 8.66c0 4.443 3.625 8.042 8.09 8.042a8.086 8.086 0 005.754-2.39c-.373.047-.752.07-1.138.07-5.096 0-9.222-4.105-9.222-9.163 0-1.29.268-2.518.753-3.632z"
        fill="#0C1525"
      />
    </svg>
  );
}

export default Icon;