import * as React from "react";

function Icon(props) {
  return (
    <svg
      width={12}
      height={17}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M9.456.143a.5.5 0 01.227.58l-1.8 5.852h3.322a.5.5 0 01.364.843l-8 8.5a.5.5 0 01-.842-.49l1.801-5.853H1.205a.5.5 0 01-.364-.843l8-8.5a.5.5 0 01.615-.09v.001zM2.362 8.575h2.843a.5.5 0 01.478.647l-1.368 4.443 5.732-6.09H7.205a.5.5 0 01-.478-.647l1.368-4.443-5.733 6.09z"
        fill="#000"
      />
    </svg>
  );
}

export default Icon;