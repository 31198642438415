import * as React from "react";

function Icon(props) {
  return (
    <svg
      width={17}
      height={16}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M16.484 2.139a2 2 0 00-2-2h-12a2 2 0 00-2 2v8a2 2 0 002 2h9.586a1 1 0 01.707.293l2.853 2.853a.5.5 0 00.854-.353V2.139zm-12.5 1h9a.5.5 0 110 1h-9a.5.5 0 010-1zm0 2.5h9a.5.5 0 110 1h-9a.5.5 0 010-1zm0 2.5h5a.5.5 0 010 1h-5a.5.5 0 010-1z"
        fill="#0C1525"
      />
    </svg>
  );
}

export default Icon;