import * as React from "react";

function Icon(props) {
  return (
    <svg
      width={16}
      height={15}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M13 1.732a1.5 1.5 0 113 0v11a1.5 1.5 0 11-3 0v-.214c-2.162-1.24-4.49-1.843-6.912-2.083l.405 2.712a1 1 0 01-.983 1.185h-.548a1 1 0 01-.916-.599l-1.85-3.49a68.03 68.03 0 00-.202-.003A2.014 2.014 0 010 8.232v-2A2.02 2.02 0 011.992 4.22a74.66 74.66 0 002.483-.075C7.518 3.99 10.623 3.295 13 1.945v-.213zm1 0v11a.5.5 0 001 0v-11a.5.5 0 00-1 0zm-1 1.35c-2.344 1.205-5.209 1.842-8 2.033v4.233c.18.01.359.022.537.036 2.568.19 5.093.744 7.463 1.993V3.082zM4 9.297v-4.13c-.664.024-1.328.042-1.992.052A1.02 1.02 0 001 6.232v2c0 .55.448 1.002 1.006 1.01.665.007 1.33.026 1.994.055zm-.657.975l1.609 3.037.01.024h.548l-.002-.014-.443-2.966a67.781 67.781 0 00-1.722-.082v.001z"
        fill="#0C1525"
      />
    </svg>
  );
}

export default Icon;