import React, { useEffect, useState } from 'react';
import styles from './Orders.module.css';
import listStyles from './OrdersList.module.css';
import { Heading } from '../Heading/Heading';
import {uid} from 'react-uid';
import flag from '../../../images/flag.png';
import { Cancelled, Completed, Pending, New, Expired } from '../../StyledComponents/Statuses/Statuses';
import { SearchInput } from '../../FunctionComponents/Search/Search';
import { Route, useRouteMatch, Switch, Link } from 'react-router-dom';
import { NoMatch } from '../../../App';
import { View } from './View/View';
import { Pagination } from '../../FunctionComponents/Pagination/Pagination';
import RequestService from '../../../api/post';
import { useDispatch, useSelector } from 'react-redux';
import getSymbolFromCurrency from 'currency-symbol-map';
import moment from 'moment';


export const OrdersList = (props) =>{
    const statuses = [<New />, <Pending />, <Completed />, <Cancelled />, <Expired />];
    const array = props.items;
    const items = array.map((item, i) => {
        let items_;
        if(i >= props.minIndex && i < props.maxIndex){
            let status = '';
            switch(item.status){
                case(0):
                    status = statuses[0];
                    break;
                case(1):
                    status = statuses[1];
                    break;
                case(3):
                    status = statuses[2];
                    break;
                case(10):
                    status = statuses[3];
                    break;
                case(11):
                    status = statuses[4];
                    break;
                default:
                    status = '';
            }
            items_ = (
                <Link to={`/invoices/${item.id}`} className={`${listStyles.item} ${i+1<props.items.length?listStyles.notFinal:''}`} key={uid(i)}>
                    <div className={listStyles.item_info}>
                    <img src={flag} alt="flag" />
                    <div className={listStyles.item_info_text}>
                        <span>{item.currency} - {item.customerInfo.customerForShop.customer.email}</span>
                        <span>{item.id} - {item.partInvoices.length>0?item.partInvoices[0].product.title:'nothing'}</span>
                    </div>
                    </div>
                    <div className={listStyles.value}>
                        <span>{getSymbolFromCurrency(item.currency)} {item.endPrice} {item.currency}</span>
                        {/* <span>BTC - 0.000180432200</span> */}
                    </div>
                    <div className={listStyles.value}>
                        {status}
                    </div>
                    <div className={listStyles.value}>
                        <span>{moment(item.timeline[0].time).format("HH:mm DD MMM")}</span>
                    </div>
                </Link>
            )
        }
        return items_
    });
    return(
        <div className={listStyles.OrdersList}>
            <div className={listStyles.title}>
                <h4 className={listStyles.info}>INFO</h4>
                <h4>VALUE</h4>
                <h4>STATUS</h4>
                <h4>TIME</h4>
            </div>
            <div className={listStyles.items}>
                {items}
            </div>
        </div>
    )
}
export const Orders = (props) => {
    const message = useSelector(state => state.message);
    const dispatch = useDispatch();
    const [pageSize, setPageSize] = useState(10);
    const [current, setCurrent] = useState(1);
    const [minIndex, setMinIndex] = useState(0);
    const [maxIndex, setMaxIndex] = useState(pageSize);
    const [invoices, setInvoices] = useState({});
    async function getInvoices(){
        const response = await RequestService.InvoicesGet(JSON.parse(localStorage.getItem('shop')).id);
        try{
            if(!response.data.data){
                dispatch({
                    type: "SET_MESSAGE", 
                    payload: {isShow: !message.isShow}
                });
            }else{
                setInvoices(response.data.data?response.data.data:{});
            }
        }
        catch{
            console.log('error')
        }
    }
    const {path} = useRouteMatch();
    useEffect(()=>{
        getInvoices()
    }, []);
    return (
        <div className={styles.Orders}>
            <Switch>
                <Route exact path='/invoices'>
                    <div className={styles.header}>
                        <Heading title={props.title} link={'/invoices'}/>
                        <div className={styles.func}>
                            <SearchInput placeholder='Search for an order...' func={(e)=>{console.log(e)}} />
                        </div>
                    </div>
                    <div className={styles.content}>
                        {Object.keys(invoices).length>0?<div className={styles.content}>
                        <OrdersList 
                            items={invoices} 
                            path={path}
                            maxIndex={maxIndex}
                            minIndex={minIndex}
                        />
                        <Pagination
                            setCurrent={setCurrent}
                            maxIndex={maxIndex}
                            minIndex={minIndex}
                            setMaxIndex={setMaxIndex}
                            setMinIndex={setMinIndex}
                            setPageSize={setPageSize}
                            content={invoices}
                            current={current}
                            pageSize={pageSize}
                            />
                    </div>:
                    <h2 className={styles.h2}>No orders</h2>}
                    </div>
                </Route>
                <Route path={`/invoices/:id`}>
                    {Object.keys(invoices).length>0?<View title={props.title} />:''}
                </Route>
                <Route path='*'>
                    <NoMatch />
                </Route>
            </Switch>
        </div>
  )
}
