import React, { useState, useEffect } from 'react';
import styles from './InfoButton.module.css';
import Info from '../../../../images/Info'
import { Select } from '../../../FunctionComponents/Select/Select';
import Twitter from '../../../../images/Twitter';
import Discord from '../../../../images/Discord';
import Cloud from '../../../../images/Cloud';
import Bullphone from '../../../../images/Bullphone';

export const InfoButton = () => {
  const items = [
    {
			text: 'Follow @SellpassHQ',
			image: <Twitter viewBox="0 0 18 18" />
		},
    {
			text: 'Join our Discord',
			image: <Discord viewBox="0 0 18 18" />
		},
    {
			text: 'Status Page',
			image: <Cloud viewBox="0 0 18 18" />
		},
    {
			text: 'Contact Support',
			image: <Bullphone viewBox="0 0 18 18" />
		}
  ];
  const [isShow, setIsShow] = useState(false);
  function openList(target){
    if(target.id==='list'){
      setIsShow(!isShow);
    }
    else{
      setIsShow(false);
    }
  }
  useEffect(()=>{
    document.addEventListener('click', e => {
    if (!(e.target).closest('#list')) {
      openList(e.target);
    }
      e.stopPropagation();
    });
  });
  return (
      <div onClick={(e)=>{openList(e.currentTarget)}} id='list' className={styles.InfoButton}>
          <Info viewBox="0 0 18 18" />
          <Select items={items} isShow={isShow} />
      </div>
  )
}
