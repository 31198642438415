import React, { useState, useEffect } from 'react';
import styles from './Account.module.css';
import { Select } from '../../../FunctionComponents/Select/Select';
import Basket from '../../../../images/Basket';
import Cup from '../../../../images/Cup';
import Flash from '../../../../images/Flash';
import Gear from '../../../../images/Gear';
import Out from '../../../../images/Out';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

export const Account = () => {
  const state = useSelector(state=>state);
  const history = useHistory();
  const shopName = localStorage.getItem('shop')&&localStorage.getItem('shop')!=='undefined'?JSON.parse(localStorage.getItem('shop')).name:'shopName';
  const [logoImg, setLogoImg] = useState(localStorage.getItem('shop')&&localStorage.getItem('shop')!=='undefined'?JSON.parse(localStorage.getItem('shop')).design.cfImageId:'');
  function signOut(){
    localStorage.removeItem('data');
    localStorage.removeItem('shop');
    localStorage.removeItem('settings');
    window.location = "http://auth.sellpass.mistersandman.ru/";
    //window.location = "http://localhost:3001";
  }
  const [isShow, setIsShow] = useState(false);
  function openList(target){
    if(target.id==='account'){
      setIsShow(!isShow);
    }
    else{
      setIsShow(false);
    }
  }
  const items = [
    {
			text: 'Your Shop',
			image: <Basket viewBox="0 0 18 18" />,
      func: ()=>{window.open(`http://shop.sellpass.mistersandman.ru/${shopName}`, '_blank')}
		},
    {
			text: 'Subscription',
			image: <Cup viewBox="0 0 18 18" />,
      func: ()=>{window.open(`http://sellpass.mistersandman.ru/pricing`, '_blank')}
		},
    {
			text: 'Orders',
			image: <Flash viewBox="0 0 18 18" />,
      func: ()=>{history.push('/invoices')}
		},
    {
			text: 'Settings',
			image: <Gear viewBox="0 0 18 18" />,
      func: ()=>{history.push('/settings')}
		},
    {
			text: 'Sign Out',
			image: <Out viewBox="0 0 18 18" />,
      func: signOut
		}
  ];
  useEffect(()=>{
    setLogoImg(localStorage.getItem('shop')&&localStorage.getItem('shop')!=='undefined'?JSON.parse(localStorage.getItem('shop')).design.cfImageId:'');
    // eslint-disable-next-line 
  }, []);
  useEffect(()=>{
    document.addEventListener('click', e => {
    if (!(e.target).closest('#account')) {
      openList(e.target);
    }
      e.stopPropagation();
    });
    setLogoImg(localStorage.getItem('shop')&&localStorage.getItem('shop')!=='undefined'?JSON.parse(localStorage.getItem('shop')).design.cfImageId:'');
    // eslint-disable-next-line 
  }, [state.message, state]);
  return (
      <div onClick={(e)=>{openList(e.currentTarget)}} id='account' className={styles.Account}>
        <div className={styles.content}>
          <div className={styles.avatar}>
            <div className={styles.avatarWrapper}>
              {logoImg?<img src={`https://imagedelivery.net/${logoImg}/1600X1200`} alt=""/>:''}
            </div>
          </div>
          <span className={styles.name}>{shopName}</span>
        </div>
        <Select items={items} signOut={signOut} isShow={isShow} />
      </div>
  )
}
