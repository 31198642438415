import * as React from "react";

function Icon(props) {
  return (
    <svg
      width={26}
      height={26}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.696.7c2.47 0 3.693 0 5.035.419.72.264 1.367.671 1.907 1.21.54.54.959 1.188 1.21 1.907.42 1.33.42 2.554.42 5.023v7.41c0 2.457 0 3.692-.42 5.023a5.225 5.225 0 01-1.21 1.907c-.54.539-1.187.959-1.907 1.21-1.33.42-2.553.42-5.023.42H9.275c-2.458 0-3.693 0-5.024-.42a5.227 5.227 0 01-1.906-1.21 5.074 5.074 0 01-1.21-1.907c-.408-1.33-.408-2.541-.408-5.023V9.247c0-2.457 0-3.692.42-5.023a5.226 5.226 0 011.21-1.906c.54-.54 1.187-.96 1.906-1.211C5.583.699 6.817.699 9.275.699h7.421z"
        fill="#00D54B"
      />
      <path
        d="M13.615 8.744c1.21 0 2.374.432 3.297 1.21a.522.522 0 00.348.133c.131 0 .251-.06.347-.144l.947-.947a.503.503 0 00.144-.36c0-.072-.024-.132-.048-.192a.388.388 0 00-.12-.156A7.425 7.425 0 0016 6.874l.288-1.403a.651.651 0 000-.216.416.416 0 00-.095-.192.431.431 0 00-.168-.132.47.47 0 00-.216-.048h-1.822a.493.493 0 00-.312.108.616.616 0 00-.18.276l-.252 1.247c-2.434.12-4.496 1.319-4.496 3.788 0 2.135 1.703 3.046 3.501 3.693 1.703.636 2.602.863 2.602 1.763 0 .887-.9 1.45-2.23 1.45a4.914 4.914 0 01-3.465-1.354.48.48 0 00-.348-.144.48.48 0 00-.347.143l-1.02.996a.455.455 0 00-.143.347c0 .072.012.132.036.192.024.06.06.12.107.156a6.615 6.615 0 002.938 1.607l-.288 1.294a.651.651 0 000 .216.365.365 0 00.096.192c.048.06.108.108.168.144a.47.47 0 00.216.048h1.834a.493.493 0 00.312-.108.517.517 0 00.18-.288l.263-1.235c2.914-.18 4.688-1.75 4.688-4.04 0-2.11-1.774-2.997-3.932-3.729-1.235-.467-2.302-.767-2.302-1.678.012-.9 1.007-1.223 2.002-1.223z"
        fill="#fff"
      />
    </svg>
  );
}

export default Icon;