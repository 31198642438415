import * as React from "react";

function Icon(props) {
  return (
    <svg
      width={17}
      height={16}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M5.962.146a.5.5 0 01.172.686L3.588 5.075h9.234L10.275.832a.5.5 0 11.858-.514l2.855 4.757h1.717a.5.5 0 01.5.5v1a.5.5 0 01-.5.5h-15a.5.5 0 01-.5-.5v-1a.5.5 0 01.5-.5h1.717L5.275.318a.5.5 0 01.686-.172h.001zM3.6 14.075l-1.48-6h-.97L2.674 14.5a.75.75 0 00.73.574h9.605a.75.75 0 00.73-.574l1.522-6.426h-.972l-1.479 6H3.6z"
        fill="#000"
      />
    </svg>
  );
}

export default Icon;