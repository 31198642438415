import * as React from "react";

function Icon(props) {
  return (
    <svg
      width={18}
      height={14}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M14.866 1.807a.042.042 0 00-.022-.02A14.473 14.473 0 0011.4.771a.055.055 0 00-.032.004.051.051 0 00-.024.02c-.157.273-.3.553-.429.84a13.523 13.523 0 00-3.868 0 8.323 8.323 0 00-.435-.84.054.054 0 00-.024-.02.057.057 0 00-.032-.004 14.43 14.43 0 00-3.444 1.017.049.049 0 00-.022.018C.896 4.926.295 7.97.59 10.974a.053.053 0 00.022.038c1.277.9 2.706 1.588 4.225 2.033a.055.055 0 00.06-.019c.326-.422.615-.87.864-1.338a.049.049 0 00-.01-.06.055.055 0 00-.02-.011 9.453 9.453 0 01-1.32-.599.05.05 0 01-.022-.066.052.052 0 01.017-.02c.089-.063.178-.129.262-.195a.054.054 0 01.055-.007c2.769 1.203 5.767 1.203 8.503 0a.054.054 0 01.055.006c.085.067.174.133.263.196a.052.052 0 01.017.02.05.05 0 01-.003.048.052.052 0 01-.019.018 8.872 8.872 0 01-1.32.598.053.053 0 00-.031.03.05.05 0 00.002.042c.253.465.542.912.864 1.338a.053.053 0 00.026.018c.011.004.022.004.033 0a14.266 14.266 0 004.232-2.032.052.052 0 00.022-.037c.353-3.475-.59-6.493-2.502-9.168zM6.174 9.144c-.833 0-1.52-.728-1.52-1.623s.673-1.624 1.52-1.624c.854 0 1.534.735 1.52 1.624 0 .895-.673 1.623-1.52 1.623zm5.622 0c-.833 0-1.52-.728-1.52-1.623s.673-1.624 1.52-1.624c.854 0 1.534.735 1.52 1.624 0 .895-.666 1.623-1.52 1.623z"
        fill="#5865F2"
      />
    </svg>
  );
}

export default Icon;