import * as React from "react";

function Icon(props) {
  return (
    <svg
      width={23}
      height={24}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M11.171.846c6.14 0 11.117 4.977 11.117 11.117 0 6.14-4.977 11.116-11.117 11.116-6.14 0-11.116-4.976-11.116-11.116S5.03.846 11.17.846z"
        fill="#0052FF"
      />
      <path
        d="M11.173 15.87a3.907 3.907 0 01-3.908-3.907 3.907 3.907 0 017.758-.652h3.936a7.817 7.817 0 100 1.303h-3.938a3.907 3.907 0 01-3.848 3.257z"
        fill="#fff"
      />
    </svg>
  );
}

export default Icon;