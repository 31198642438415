import * as React from "react";

function Icon(props) {
  return (
    <svg
      width={20}
      height={18}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M19.246 8.37c0-.784-.427-1.462-1.055-1.83V1.465c0-.29-.23-1.063-1.056-1.063-.235 0-.468.079-.66.233l-2.804 2.259a7.973 7.973 0 01-4.98 1.758H2.357a2.118 2.118 0 00-2.11 2.125v3.187c0 1.174.944 2.125 2.11 2.125H3.47a8.12 8.12 0 00-.072 1.063c0 1.32.305 2.568.843 3.683.171.355.545.567.937.567h2.45c.86 0 1.375-.99.854-1.679a4.246 4.246 0 01-.862-2.571c0-.369.053-.724.145-1.063h.927c1.803 0 3.571.625 4.98 1.758l2.805 2.26c.187.15.42.232.659.232.822 0 1.055-.756 1.055-1.062V10.2a2.118 2.118 0 001.056-1.83zm-3.167 4.696l-1.09-.878a10.078 10.078 0 00-6.298-2.224V6.777c2.28 0 4.517-.79 6.298-2.224l1.09-.878v9.391z"
        fill="#0C1525"
      />
    </svg>
  );
}

export default Icon;