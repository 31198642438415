import * as React from "react";

function Icon(props) {
  return (
    <svg
      width={14}
      height={16}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.264.535h-8c-.733 0-1.333.6-1.333 1.333v9.334h1.333V1.868h8V.535zm2 2.667H4.931c-.733 0-1.333.6-1.333 1.333v9.333c0 .734.6 1.334 1.333 1.334h7.333c.734 0 1.334-.6 1.334-1.334V4.535c0-.733-.6-1.333-1.334-1.333zM4.931 13.868h7.333V4.535H4.931v9.333z"
        fill="#0C1525"
      />
    </svg>
  );
}

export default Icon;
