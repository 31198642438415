import React, { useEffect, useState } from 'react';
import styles from './GroupsEdit.module.css';
import { Heading } from '../../../../Heading/Heading';
import Clip from '../../../../../../images/Clip';
import RequestService from '../../../../../../api/post';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';

export const GroupsEdit = () => {
    const location = useLocation();
    const PageType = location.pathname.match(/\/groups\/(.*)\//)?location.pathname.match(/\/groups\/(.*)\//)[1]==='edit':false;
    const [group, setGroup] = useState({
        title: "",
        productIds: [],
        priority: 0,
        type: 0
    });
    const [products, setProducts] = useState([]);
    function setProductIds(data){
        let array = [];
        data.forEach(item => {
            array.push(item.value);
        });
        setGroup({...group, productIds: array});
        setProducts(data);
    }
    const message = useSelector(state => state.message);
    const dispatch = useDispatch();
    const [options, setOptions] = useState([
        { value: 1, label: 'string' }
    ]);
    async function getProducts(){
        const response = await RequestService.ProductsGet(JSON.parse(localStorage.getItem('shop')).id);
        if(response){
            let array = [];
            response.data.data.forEach(item => {
                array.push({value: item.id, label: item.title});
            });
            setOptions(array);
        }
    }
    async function newGroup(){
        console.log(group);
        if(PageType){
            const response = await RequestService.GroupEdit(JSON.parse(localStorage.getItem('shop')).id, location.pathname.match(/\/.*\/([0-9]*)/)[1], group);
            if(response){
                dispatch({
                    type: "SET_MESSAGE", 
                    payload: {isShow: true, status: true, text: 'Group created successfully'}
                });
            }
            else{
                dispatch({
                    type: "SET_MESSAGE", 
                    payload: {isShow: !message.isShow}
                });
            }
        }else{
            const response = await RequestService.GroupNew(JSON.parse(localStorage.getItem('shop')).id, group);
            console.log(response);
            if(response){
                dispatch({
                    type: "SET_MESSAGE", 
                    payload: {isShow: true, status: true, text: 'Group created successfully'}
                });
            }
            else{
                dispatch({
                    type: "SET_MESSAGE", 
                    payload: {isShow: !message.isShow}
                });
            }
        }
    }
    async function getGroup(groupId){
        const response = await RequestService.GroupGet(JSON.parse(localStorage.getItem('shop')).id, groupId);
        console.log(response.data.data);
        const groupInfo = response.data.data;
        let array = [];
        let arrayProducts = [];
        groupInfo.products.forEach(item => {
            array.push(item.id);
            arrayProducts.push({value: item.id, label: item.title});
        });
        setProducts(arrayProducts);
        setGroup({
            title: groupInfo.title,
            priority: groupInfo.priority,
            type: groupInfo.type,
            productIds: array
        });
    }
    const handleChange = e => {
        const file = document.getElementById('file');
        const fileName_ = document.getElementById('fileName');
        file.innerText = '';
        fileName_.innerText = e.target.value.replace('C:\\fakepath\\', '');
    }
    useEffect(()=>{
        if(PageType){
            getGroup(location.pathname.match(/\/.*\/([0-9]*)/)[1]);
        }
        getProducts();
    }, []);
    return (
        <div className={styles.GroupsEdit}>
            <div className={styles.header}>
                <Heading title='Groups' pathTitle={PageType?'Edit Group':'New Group'}  link='/products/groups'/>
                <div className={styles.func}>
                    <button className={styles.submit} onClick={()=>{newGroup()}}>{
                    (PageType)?
                    'Edit product group'
                    :
                    '+ Create product group'}</button>
                </div>
            </div>
            <div className={styles.GroupsEdit_container}>
                <div className={styles.form}>
                    <h3 className={styles.h3}>Title</h3>
                    <input placeholder='Group title' value={group.title} className={styles.input} onChange={(e)=>{setGroup({...group, title: e.target.value})}} />
                    <h3 className={styles.h3}>Products</h3>
                    <div className={styles.select}>
                        <Select options={options} isMulti value={products} onChange={(e)=>{setProductIds(e)}} />
                    </div>
                    <h3 className={styles.h3}>Priority</h3>
                    <input placeholder='Priority' type='number' value={group.priority} className={styles.input} onChange={(e)=>{setGroup({...group, priority: parseInt(e.target.value)})}} />
                    <h3 className={styles.h3}>Additional info</h3>
                    <div className={styles.groupTypes}>
                        <div className={styles.groupType} onClick={()=>{setGroup({...group, type: 0})}}>
                            <input id="groupType1" type='radio' checked={group.type===0?true:false} name='groupType'/>
                            <label htmlFor="groupType1" className={styles.typeBlock}>
                                <h2>Public group</h2>
                                <span>Can be seen and accessed by anyone</span>
                            </label>
                        </div>
                        <div className={styles.groupType} onClick={()=>{setGroup({...group, type: 1})}}>
                            <input id="groupType2" type='radio' checked={group.type===1?true:false} name='groupType'/>
                            <label htmlFor="groupType2" className={styles.typeBlock}>
                                <h2>Hidden group</h2>
                                <span>Can only be accessed via link</span>
                            </label>
                        </div>
                    </div>
                </div>
                <div className={styles.ImageInput}>
                    <h3 className={styles.h3}>Image</h3>
                    <div className={styles.input_file}>
                        <label className={styles.content}>
                            <div id='file' className={styles.clip}>
                                <Clip viewBox='0 0 22 34' />
                                <span>Only accepts png, jpg, or gif</span>
                            </div>
                            <span className={styles.name} id='fileName'></span>
                            <input onChange={handleChange} type="file" name="image" />
                        </label>
                    </div>
                </div>
            </div>
        </div>
    )
}
