import React, { useState, useEffect } from 'react';
import styles from './AccountNav.module.css';
import ArrowDownLong from '../../../../images/ArrowDownLong';
import RequestService from '../../../../api/post';
import { NavAccounts } from '../../../FunctionComponents/NavAccounts/NavAccounts';
import { useDispatch, useSelector } from 'react-redux';

export const AccountNav = () => {
  const state = useSelector(state => state);
  const dispatch = useDispatch();
  const [isShow, setIsShow] = useState(false);
  const [isLoad, setIsLoad] = useState(false);
  const [logoImg, setLogoImg] = useState(localStorage.getItem('shop')&&localStorage.getItem('shop')!=='undefined'?JSON.parse(localStorage.getItem('shop')).design.cfImageId:'');
  function openList(target){
    if(target.id==='account_nav'){
      setIsShow(!isShow);
    }
    else{
      setIsShow(false);
    }
  }
  function setShop(i){
    localStorage.setItem('shop', JSON.stringify(state.shops[i]));
    dispatch({
        type: "SET_SHOP", 
        payload: state.shops[i]
    });
    localStorage.removeItem('settings');
    window.location.reload();
  }
  async function getShops(){
    const response = await RequestService.ShopsGet();
    const shops = response.data.data.map(item => {
      return(
        item.shop
      )
    });
    if(!localStorage.getItem('shop')){
      localStorage.setItem('shop', JSON.stringify(shops[0]));
    }
    dispatch({
        type: "SET_SHOPS", 
        payload: shops
    });
  }
  useEffect(()=>{
      if(!isLoad){
        if(localStorage.getItem('shop')&&localStorage.getItem('shop')!=='undefined'){
          setLogoImg(JSON.parse(localStorage.getItem('shop')).design.cfImageId);
          setIsLoad(true);
        }
        else{
          setIsLoad(false);
        }
      }
  });
  useEffect(()=>{
    document.addEventListener('click', e => {
    if (!(e.target).closest('#account_nav')) {
      openList(e.target);
    }
      e.stopPropagation();
    });
    getShops();
    setLogoImg(localStorage.getItem('shop')&&localStorage.getItem('shop')!=='undefined'?JSON.parse(localStorage.getItem('shop')).design.cfImageId:'');
    // eslint-disable-next-line
  }, []);
  return (
    <div onClick={(e)=>{openList(e.currentTarget)}} id='account_nav' className={styles.AccountNav}>
      <div className={styles.wrapper}>
        <div className={styles.content}>
          <div className={styles.avatar}>
            <div className={styles.avatarWrapper}>
              {logoImg?<img src={`https://imagedelivery.net/${logoImg}/1600X1200`} alt=""/>:''}
            </div>
          </div>
          <div className={styles.text}>
            <span className={styles.name}>{localStorage.getItem('shop')&&localStorage.getItem('shop')!=='undefined'?JSON.parse(localStorage.getItem('shop')).name:'shopName'}</span>
            <span className={styles.email}>{localStorage.getItem('data')?JSON.parse(localStorage.getItem('data')).email:'demotest@gmail.com'}</span>
          </div>
        </div>
          <ArrowDownLong viewBox="0 0 10 10" className={styles.arrowDownLong} />
      </div>
      <NavAccounts items={state.shops} isShow={isShow} setShop={setShop}/>
    </div>
  )
}
