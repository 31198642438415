import * as React from "react";

function Icon(props) {
  return (
    <svg
      width={21}
      height={17}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M10.044 4.954l-6.443 5.307v5.727a.56.56 0 00.56.559l3.915-.01a.559.559 0 00.557-.56v-3.344a.56.56 0 01.559-.56h2.236a.559.559 0 01.56.56v3.342a.561.561 0 00.558.56l3.915.012a.559.559 0 00.559-.56v-5.73l-6.441-5.303a.426.426 0 00-.535 0zm10.177 3.607L17.3 6.153v-4.84a.42.42 0 00-.42-.42h-1.957a.42.42 0 00-.419.42V3.85l-3.129-2.574a1.677 1.677 0 00-2.131 0L.398 8.56a.42.42 0 00-.056.59l.891 1.084a.42.42 0 00.591.057l8.22-6.77a.426.426 0 01.535 0l8.22 6.77a.42.42 0 00.59-.056l.891-1.084a.419.419 0 00-.06-.591z"
        fill="#0C1525"
      />
    </svg>
  );
}

export default Icon;