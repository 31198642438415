import React, { useEffect, useState } from 'react';
import styles from './BlacklistsEdit.module.css';
import { Heading } from '../../Heading/Heading';
import RequestService from '../../../../api/post';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';

export const BlacklistsEdit = (props) => {
    const location = useLocation();
    const id = location.pathname.match(/\/.*\/([0-9]*)/)[1];
    const PageType = location.pathname.match(/\/blacklists\/(.*)\//)?location.pathname.match(/\/blacklists\/(.*)\//)[1]==='edit':false;
    const [blacklist, setBlacklist] = useState({
        data: "",
        note: "",
        type: 0
    });
    const message = useSelector(state => state.message);
    const dispatch = useDispatch();
    async function getBlacklist(blacklistId){
        const response = await RequestService.BlacklistItemGet(JSON.parse(localStorage.getItem('shop')).id, blacklistId);
        const blacklistInfo = response.data.data;
        setBlacklist({
            type: blacklistInfo.type,
            data: blacklistInfo.data,
            note: blacklistInfo.note
        });
    }
    async function onSubmit(){
        let data = blacklist
        if(!blacklist.note){
            delete data.note;
        }
        console.log(data)
        if(PageType){
            const response = await RequestService.BlacklistItemPut(JSON.parse(localStorage.getItem('shop')).id, id, data);
            if(response){
                dispatch({
                    type: "SET_MESSAGE", 
                    payload: {isShow: true, status: true, text: 'Blacklist edited successfully'}
                });
            }
            else{
                dispatch({
                    type: "SET_MESSAGE", 
                    payload: {isShow: !message.isShow}
                });
            }
        }else{
            const response = await RequestService.BlacklistNew(JSON.parse(localStorage.getItem('shop')).id, data);
            if(response){
                dispatch({
                    type: "SET_MESSAGE", 
                    payload: {isShow: true, status: true, text: 'Blacklist created successfully'}
                });
            }
            else{
                dispatch({
                    type: "SET_MESSAGE", 
                    payload: {isShow: !message.isShow}
                });
            }
        }
    }
    useEffect(()=>{
        if(PageType){
            getBlacklist(id);
        }
    }, []);
    return (
        <div className={styles.BlacklistsEdit}>
            <div className={styles.header}>
                <Heading title={props.title} pathTitle={PageType?'Edit Blacklist':'Create Blacklist'}  link='/blacklists'/>
                <div className={styles.func}>
                    <button className={styles.submit} onClick={()=>{onSubmit()}}>{PageType?'Edit rule':'Create rule'}</button>
                </div>
            </div>
            <div className={styles.BlacklistsEdit_container}>
                <div className={styles.form}>
                    <h3 className={styles.h3}>Type</h3>
                    <div className={styles.select}>
                        <select value={blacklist.type} onChange={(e)=>{setBlacklist({...blacklist, type: parseInt(e.target.value)})}}>
                            <option value='0'>IP</option>
                            <option value='1'>Country</option>
                            <option value='2'>Email</option>
                        </select>
                    </div>
                    <h3 className={styles.h3}>Blocked Data</h3>
                    <input value={blacklist.data} onChange={(e)=>{setBlacklist({...blacklist, data: e.target.value})}} placeholder='Blocked Data' className={styles.input} />
                    <h3 className={styles.h3}>Note (optional)</h3>
                    <div className={styles.textarea_wrapper}>
                        <textarea value={blacklist.note} onChange={(e)=>{setBlacklist({...blacklist, note: e.target.value})}} placeholder='Leave a note here' />
                    </div>
                </div>
            </div>
        </div>
    )
}
