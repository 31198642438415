import React from 'react';
import { Pagination as PagintaionANTD } from 'antd';
import styles from './Pagination.module.css';

export const Pagination = (props) => {
  return (
    <div className={styles.Pagination}>
        <div className={styles.pageSize}>
            <span>Showing</span>
            <select onChange={e=>{
                props.setPageSize(e.target.value);
                props.setCurrent(1);
                props.setMinIndex(0);
                props.setMaxIndex(e.target.value);
                }}>
                <option value='10'>10/{props.content.length}</option>
                <option value='20'>20/{props.content.length}</option>
                <option value='30'>30/{props.content.length}</option>
                <option value='40'>40/{props.content.length}</option>
                <option value='50'>50/{props.content.length}</option>
            </select>
            <span>Items</span>
        </div>
        <PagintaionANTD 
            onChange={(page)=>{
                props.setMinIndex((page - 1) * props.pageSize);
                props.setMaxIndex(page * props.pageSize);
                props.setCurrent(page)
            }}
            pageSize={props.pageSize}
            current={props.current}
            total={props.content.length}
        />
    </div>
  )
}
