import React, { useState, useEffect } from 'react';
import styles from './Notifications.module.css';
import Bell from '../../../../images/Bell'
import { List } from '../../../FunctionComponents/List/List';
import Close from '../../../../images/Close';
import RequestService from '../../../../api/post';

export const Notifications = () => {
  const [items, setItems] = useState([]);
  const image = <Close viewBox="0 0 10 10" />;
  const [isShow, setIsShow] = useState(false);
  const [notifies, setNotifies] = useState(items.length?<span className={styles.count}>{items.length}</span>:<></>);
  async function getNotifications(){
    if(localStorage.getItem('shop')){
      const response = await RequestService.NotificationsGet(JSON.parse(localStorage.getItem('shop')).id);
      setItems(Object.keys(response.data).length>0?response.data.data:{
        title: 'A new order has been created',
        text: <span>An order of <b>$9.99</b> has been created for <b>Sell anything product</b></span>,
        time: <span>1 hour ago</span>
      });
      setNotifies(Object.keys(response.data).length>0?<span className={styles.count}>{response.data.data.length}</span>:<></>);
    }
  }
  async function readNotification(id){
      await RequestService.NotificationsRead(JSON.parse(localStorage.getItem('shop')).id, id);
  }
  async function deleteInvoices(){
      await RequestService.NotificationsDelete(JSON.parse(localStorage.getItem('shop')).id);
  }
  function openList(target){
    setNotifies(items.length?<span className={styles.count}>{items.length}</span>:<></>);
    if(target.id==='notifications'){
      setIsShow(!isShow);
    }
  }
  useEffect(()=>{
    getNotifications();
  }, []);
  useEffect(()=>{
    document.addEventListener('click', e => {
    if (!(e.target).closest('#notifications') && !(e.target).closest('#notifications_list')) {
      setIsShow(false);
    }
    else if((e.target).closest('#notifications_list')){
      setIsShow(true);
    }
    e.stopPropagation();
    });
  });
  return (
    <div>
      <div onClick={(e)=>{openList(e.currentTarget)}} id='notifications' className={styles.Notifications}>
        <Bell viewBox="0 0 18 18" />
        {notifies}
      </div>
      <div onClick={(e)=>{openList(e.currentTarget)}} id='notifications_list'>
        <List
          items={items}
          readNotification={(id)=>{readNotification(id)}}
          id='notifications_list'
          image={image}
          setIsShow={setIsShow}
          isShow={isShow}
          deleteInvoices={deleteInvoices}
        />
      </div>
    </div>
  )
}
