import React from 'react';

import styles from './Button.module.css';

export const Button = (props) => {
  return (
    <button type={props.type} onClick={props.func} className={`${styles.Button}
        ${props.styleB?(props.styleB==='orange'?styles.orange:styles.light):styles.light}
        ${props.size?(props.size==='large'?styles.large:styles.small):styles.small}
        `}
    >
    <span>{props.content}</span>
    </button>
  )
}
