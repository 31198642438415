import React from 'react';
import styles from './Billing.module.css';
import { Button } from '../../../FunctionComponents/Button/Button';
import {uid} from 'react-uid';
import { Completed } from '../../../StyledComponents/Statuses/Statuses';

export const Billing = () => {
    const itemsData = [
        {   
            purchase: 'Monthly Pro Subscription',
            type: 'BTC',
            email: 'chairmantest@gmail.com',
            value: '$5.50',
            status: <Completed />,
            time: '11:00 06 Jan',
        }
    ];
    const items = itemsData.map((item, i) => {
        return(
            <div className={`${styles.item} ${i+1<itemsData.length?styles.notFinal:''}`} key={uid(i)}>
                <div className={styles.item_info}>
                {item.flag}
                <div className={styles.item_info_text}>
                    <h4>{item.purchase}</h4>
                    <span>{item.type} - {item.email}</span>
                </div>
                </div>
                <div className={styles.value}>
                    <span>{item.value}</span>
                    <span>{item.converted_value}</span>
                </div>
                <div className={styles.value}>
                    {item.status}
                </div>
                <div className={styles.value}>
                    <span>{item.time}</span>
                </div>
            </div>
        );
    });
    return (
        <div className={styles.Billing}>
            <div className={`${styles.block} ${styles.name}`}>
                <h3>Purchase History</h3>
                <span className={styles.span}>Here is where you can view the history of invoices billed for premium subscriptions</span>
                <div className={styles.purchaseList}>
                    <div className={styles.title}>
                        <h4 className={styles.info}>INFO</h4>
                        <h4>VALUE</h4>
                        <h4>STATUS</h4>
                        <h4>TIME</h4>
                    </div>
                    <div className={styles.items}>
                        {items}
                    </div>
                </div>
            </div>
            <div className={`${styles.block} ${styles.name}`}>
                <h3>Invoice email</h3>
                <span className={styles.span}>If you want to use a custom email address specifically for receiving invoices, enter it here</span>
                <input type='text' />
                <div className={styles.bottom}>
                    <span className={styles.span}>Please use a valid email</span>
                    <Button styleB='orange' content='Save' />
                </div>
            </div>
        </div>
    )
}
