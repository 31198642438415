import React from 'react';
import styles from './Categories.module.css';
import Heart from '../../../../../images/Heart'

export const Categories = () => {
  return (
    <div className={styles.Categories}>
        <div className={styles.content}>
            <Heart viewBox='0 0 54 48' />
            <h3>Categories are coming soon</h3>
            <span>We understand the popularity of categories and we can’t wait to ship them soon :)</span>
        </div>
    </div>
  )
}
