import * as React from "react";

function Icon(props) {
  return (
    <svg
      width={17}
      height={17}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M15.527 16.338l-4.95-5m1.65-4.166c0 .766-.15 1.524-.44 2.232a5.837 5.837 0 01-1.252 1.893A5.77 5.77 0 018.66 12.56a5.724 5.724 0 01-4.42 0 5.77 5.77 0 01-1.873-1.264 5.838 5.838 0 01-1.252-1.893 5.884 5.884 0 011.252-6.357A5.746 5.746 0 016.45 1.338c1.532 0 3 .615 4.084 1.709a5.863 5.863 0 011.691 4.125z"
        stroke="#0C1525"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default Icon;