import * as React from "react";

function Icon(props) {
  return (
    <svg
      width={54}
      height={48}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M27.002 18.546l-3.011-6.026c-.748-1.492-2.045-3.638-3.88-5.372-1.806-1.708-3.969-2.87-6.583-2.87-5.645 0-10.105 4.466-10.105 9.835 0 4.08 1.866 6.96 6.292 11.352 1.136 1.125 2.43 2.341 3.86 3.682 3.733 3.503 8.375 7.862 13.427 13.69 5.053-5.828 9.695-10.187 13.427-13.69 1.432-1.34 2.728-2.56 3.86-3.682 4.426-4.392 6.293-7.272 6.293-11.351 0-5.37-4.46-9.836-10.106-9.836-2.617 0-4.776 1.162-6.582 2.87-1.836 1.734-3.132 3.88-3.88 5.372l-3.012 6.026zm1.32 27.932a1.727 1.727 0 01-2.64 0C20.289 40.07 15.408 35.49 11.39 31.72 4.434 25.19.055 21.084.055 14.114.055 6.82 6.085.909 13.528.909c5.39 0 9.16 3.537 11.467 6.764a25.435 25.435 0 012.007 3.342 25.427 25.427 0 012.008-3.342C31.317 4.443 35.087.909 40.476.909 47.92.91 53.95 6.821 53.95 14.113c0 6.97-4.379 11.076-11.335 17.608-4.018 3.772-8.9 8.353-14.292 14.753v.004z"
        fill="#FF3F19"
      />
    </svg>
  );
}

export default Icon;