import * as React from "react";

function Icon(props) {
  return (
    <svg
      width={30}
      height={30}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M29.337 14.78a14.5 14.5 0 11-29 0 14.5 14.5 0 0129 0zM22.14 9.29a1.359 1.359 0 00-1.957.04l-6.295 8.02-3.794-3.796a1.36 1.36 0 00-1.921 1.922l4.796 4.797a1.358 1.358 0 001.955-.036l7.236-9.044a1.36 1.36 0 00-.018-1.903h-.002z"
        fill="#2DB04A"
      />
    </svg>
  );
}

export default Icon;
