import React, { useEffect, useState } from 'react';
import { Heading } from '../../Heading/Heading';
import styles from './View.module.css';
import currency from '../../../../images/currency.png';
import { useParams } from 'react-router-dom';
import { Button } from '../../../FunctionComponents/Button/Button';
import {uid} from 'react-uid';
import { Modal } from '../../../FunctionComponents/Modal/Modal';
import Close from '../../../../images/Close';
import flag from '../../../../images/flag.png';
import { useDispatch, useSelector } from 'react-redux';
import RequestService from '../../../../api/post';
import { Cancelled, Completed, Pending, New, Expired } from '../../../StyledComponents/Statuses/Statuses';
import getSymbolFromCurrency from 'currency-symbol-map';
import moment from 'moment';

export const View = (props) => {
    const message = useSelector(state => state.message);
    const [invoice, setInvoice] = useState({});
    const dispatch = useDispatch();
    const { id } = useParams();
    const [modalShow, setModalShow] = useState(false);
    const [blacklistNote, setBlacklistNote] = useState('');
    const [modalMessage, setModalMessage] = useState(
        <div className={styles.modal}>
        </div>
    );
    async function blacklist(email){
        const data = {
            customerForShopId: invoice.customerInfo.customerForShop.id,
            note: blacklistNote?blacklistNote:null
        };
        const response = await RequestService.BlacklistNew(JSON.parse(localStorage.getItem('shop')).id, data);
        if(response){
            dispatch({
                type: "SET_MESSAGE", 
                payload: {isShow: true, status: true, text: 'Blacklist added successfully'}
            });
        }
        else{
            dispatch({
                type: "SET_MESSAGE", 
                payload: {isShow: !message.isShow}
            });
        }
    }
    async function getInvoice(){
        const response = await RequestService.InvoiceGet(JSON.parse(localStorage.getItem('shop')).id, id);
        console.log(response.data.data)
        if(!response.data.data){
            dispatch({
                type: "SET_MESSAGE", 
                payload: {isShow: !message.isShow}
            });
        }else{
            setInvoice(response.data.data?response.data.data:{});
        }
    }
    function getGoods(items){
        let goods = [];
        items.forEach(item => {
            item.deliveredGoods.forEach(good => {
                goods.push(<span>{good}</span>);
                goods.push(<br/>);
            });
        });
        return goods;
    }console.log(blacklistNote)
    function openModal(func){
        console.log('aasd')
        switch(func) {
            case 'blacklist':
                setModalMessage(
                    <div className={styles.modal}>
                        <div className={styles.top}>
                            <h3 className={styles.h3_modal}>Blacklist customer</h3>
                            <Close onClick={()=>{setModalShow(false)}} viewBox='0 0 10 15' />
                        </div>
                        <div className={styles.text}>
                            <span className={styles.span}>You are about to blacklist <b>{invoice.customerInfo.customerForShop.customer.email}</b>. Are you sure you want to blacklist them?</span>
                        </div>
                        <div className={styles.text}>
                            <span className={styles.span}>Note</span>
                        </div>
                        <input onChange={(e)=>{setBlacklistNote(e.target.value)}} className={styles.input} />
                        <div className={styles.buttons}>
                            <Button styleB='light' size='small' func={()=>{setModalShow(false)}} content='Cancel' />
                            <Button styleB='orange' size='small' func={()=>{blacklist(invoice.customerInfo.customerForShop.customer.email);setModalShow(false)}} content='Blacklist customer' />
                        </div>
                    </div>
                );
                break;
            case 'issue':
                setModalMessage(
                    <div className={styles.modal}>
                        <div className={styles.top}>
                            <h3 className={styles.h3_modal}>Issue replacement</h3>
                            <Close onClick={()=>{setModalShow(false)}} viewBox='0 0 10 15' />
                        </div>
                        <div className={styles.text}>
                            <span className={styles.span}>This order has successfully been completed. </span><br/><br/>
                            <span className={styles.span}>By issuing a product replacement, we’ll send what’s available in stock to your customer. This is irreversible.</span>
                        </div>
                        <div className={styles.buttons}>
                            <Button styleB='orange' size='small' func={()=>{setModalShow(false)}} content='Issue full replacement' />
                        </div>
                        <div className={styles.text}>
                            <span className={styles.span}>By issuing a partial replacement, we’ll only send the quantity you set of the stock to your customer.</span><br/><br/>
                            <span className={styles.span}>Quantity</span>
                        </div>
                        <input placeholder='0' className={styles.input} />
                        <div className={styles.buttons}>
                            <Button styleB='orange' size='small' func={()=>{setModalShow(false)}} content='Issue partial replacement' />
                        </div>
                    </div>
                );
                break;
            case 'resend':
                setModalMessage(
                    <div className={styles.modal}>
                        <div className={styles.top}>
                            <h3 className={styles.h3_modal}>Resend order</h3>
                            <Close onClick={()=>{setModalShow(false)}} viewBox='0 0 10 15' />
                        </div>
                        <div className={styles.text}>
                            <span className={styles.span}>This order has successfully been completed. </span><br/><br/>
                            <span className={styles.span}>If your customer has never received the delivery, feel free to resend it below.</span>
                        </div>
                        <div className={styles.text}>
                            <span className={styles.span}>Customer email</span>
                        </div>
                        <input placeholder='Customer email' className={styles.input} />
                        <div className={styles.buttons}>
                            <Button styleB='light' size='small' func={()=>{setModalShow(false)}} content='Cancel' />
                            <Button styleB='orange' size='small' func={()=>{setModalShow(false)}} content='Resend order' />
                        </div>
                    </div>
                );
                break;
            case 'process':
                setModalMessage(
                    <div className={styles.modal}>
                        <div className={styles.top}>
                            <h3 className={styles.h3_modal}>Process order</h3>
                            <Close onClick={()=>{setModalShow(false)}} viewBox='0 0 10 15' />
                        </div>
                        <div className={styles.text}>
                            <span className={styles.span}>You are about to <b>process this order manually</b>. Once you confirm below, we’ll mark this order paid and deliver the product to the customer. </span>
                        </div>
                        <div className={styles.buttons}>
                            <Button styleB='light' size='small' func={()=>{setModalShow(false)}} content='Cancel' />
                            <Button styleB='orange' size='small' func={()=>{setModalShow(false)}} content='Process order' />
                        </div>
                    </div>
                );
                break;
            default:
                console.log('none');
          }
          setModalShow(true);
    };
    const buttons = {
        issue: {
            title: 'Issue Replacement',
            func: 'issue'
        },
        resend: {
            title: 'Resend Order',
            func: 'resend'
        },
        process: {
            title: 'Process Order',
            func: 'process'
        }
    }

    const statuses = [<New />, <Pending />, <Completed />, <Cancelled />, <Expired />];
    let status = '';
    switch(invoice.status){
        case(0):
            status = statuses[0];
            break;
        case(1):
            status = statuses[1];
            break;
        case(3):
            status = statuses[2];
            break;
        case(10):
            status = statuses[3];
            break;
        case(11):
            status = statuses[4];
            break;
        default:
            status = '';
    }

    const buttonsList = Object.keys(buttons).map(item =>{
        return(
            <Button key={uid(item)} styleB='light' size='large' func={()=>{openModal(buttons[item].func)}} content={buttons[item].title} />
        )
    });
    const gateways = ['Coinbase Commerce', 'Stripe', 'PayPal', 'CashApp'];
    useEffect(()=>{
        getInvoice()
    }, []);
    return(
        <div className={styles.View}>
            {modalShow?<Modal component={modalMessage}/>:''}
            <div className={styles.header}>
                <Heading title={props.title} pathTitle='View Order' link='/invoices'/>
            </div>
            {Object.keys(invoice).length>0?
            <div className={styles.content}>
                <div className={styles.main}>
                    <div className={styles.info}>
                        <img src={currency} alt='currency' />
                        <div className={styles.buyer_info}>
                            <span className={styles.email}>{invoice.customerInfo.customerForShop.customer.email}</span>
                            <span>{invoice.id}</span>
                        </div>
                        <div className={styles.status}>
                            {status}
                        </div>
                    </div>
                    <div className={styles.functions}>
                        <button className={styles.functions__button} onClick={()=>{openModal('blacklist')}}>
                            <h3 className={`${styles.h3} ${styles.orange}`}>Blacklist Customer</h3>
                        </button>
                        {buttonsList}
                    </div>
                </div>
                <div className={styles.infoBlock}>
                    <div className={styles.content}>
                        <div className={styles.infoItem}>
                            <h3 className={styles.h3_infoItem}>Product</h3>
                            <span>{invoice.partInvoices[0].product.title}</span>
                        </div>
                        <div className={styles.infoItem}>
                            <h3 className={styles.h3_infoItem}>Quantity</h3>
                            <span>{invoice.partInvoices[0].quantity}</span>
                        </div>
                        <div className={styles.ip}>
                            <div className={styles.infoItem__ip}>
                                <h3 className={styles.h3_infoItem}>IP Address:&nbsp;</h3>
                                <span>{invoice.customerInfo.currentIp.ip}</span>
                            </div>
                            <div className={styles.infoItem__ip}>
                                <h3 className={styles.h3_infoItem}>Fraud Score:&nbsp;</h3>
                                <span>100 / 100</span>
                            </div>
                            <div className={styles.infoItem__ip}>
                                <h3 className={styles.h3_infoItem}>ISP:&nbsp;</h3>
                                <span>{invoice.customerInfo.currentIp.isp}</span>
                            </div>
                        </div>
                        <div className={styles.infoItem}>
                            <h3 className={styles.h3_infoItem}>Price</h3>
                            <span>{getSymbolFromCurrency(invoice.currency)} {invoice.endPrice}</span>
                        </div>
                        <div className={styles.infoItem}>
                            <h3 className={styles.h3_infoItem}>Coupon</h3>
                            <span>No Coupon</span>
                        </div>
                        <div className={styles.infoItem}>
                            <h3 className={styles.h3_infoItem}>Country</h3>
                            <div className={styles.infoItem__country}>
                                <img src={flag} alt="flag" />
                                <span>{invoice.customerInfo.currentIp.country}</span>
                            </div>
                        </div>
                        <div className={styles.infoItem}>
                            <h3 className={styles.h3_infoItem}>Created At</h3>
                            <span>{moment(invoice.customerInfo.currentIp.dateTime).format("DD, MMM YYYY HH:mm")}</span>
                        </div>
                        <div className={styles.infoItem}>
                            <h3 className={styles.h3_infoItem}>Gateway</h3>
                            <span>{gateways[invoice.gateway.gatewayName]}</span>
                        </div>
                        <div className={styles.infoItem__device}>
                            <h3 className={styles.h3_infoItem}>Device</h3>
                            <span>{invoice.customerInfo.useragent}</span>
                        </div>
                    </div>
                </div>
                {invoice.status===3?<div className={styles.goods}>
                    <h3 className={styles.h3}>Delivered Goods</h3>
                    <div className={styles.goods__container}>
                        {getGoods(invoice.partInvoices)}
                    </div>
                </div>:''}
            </div>:''}
        </div>
    )
}
