import React, { useState } from 'react';
import styles from './Customers.module.css';
import listStyles from './CustomersList.module.css';
import { Heading } from '../Heading/Heading';
import {uid} from 'react-uid';
import flag from '../../../images/flag.png'
import { Cancelled, Completed, Pending } from '../../StyledComponents/Statuses/Statuses';
import { SearchInput } from '../../FunctionComponents/Search/Search';
import { Pagination } from '../../FunctionComponents/Pagination/Pagination';

export const CustomersList = (props) =>{
    const items = props.items.map((item, i) => {
        return(
            <div to={`/orders/order/${item.id}`} className={`${listStyles.item} ${i+1<props.items.length?listStyles.notFinal:''}`} key={uid(i)}>
                <div className={listStyles.item_info}>
                    {item.flag}
                    <div className={listStyles.item_info_text}>
                        <span>{item.country} - {item.email}</span>
                    </div>
                </div>
                <div className={listStyles.value}>
                    <span>{item.value}</span>
                </div>
                <div className={listStyles.value}>
                    {item.social}
                </div>
            </div>
        );
    });
    return(
        <div className={listStyles.CustomersList}>
            <div className={listStyles.title}>
                <h4 className={listStyles.info}>INFO</h4>
                <h4>LIFETIME VALUE</h4>
                <h4>SOCIAL</h4>
            </div>
            <div className={listStyles.items}>
                {items}
            </div>
        </div>
    )
}
export const Customers = (props) => {
    const [pageSize, setPageSize] = useState(10);
    const [current, setCurrent] = useState(1);
    const [minIndex, setMinIndex] = useState(0);
    const [maxIndex, setMaxIndex] = useState(pageSize);
    const items = [
        {
            id: 0,
            flag: <img src={flag} alt="flag" />,
            email: 'customeremail@gmail.com',
            country: 'UC',
            value: '$215.50',
            social: 'customer#1257'
        },
        {
            id: 1,
            flag: <img src={flag} alt="flag" />,
            email: 'customeremail@gmail.com',
            country: 'UC',
            value: '$215.50',
            social: 'customer#1257'
        },
        {
            id: 2,
            flag: <img src={flag} alt="flag" />,
            email: 'customeremail@gmail.com',
            country: 'UC',
            value: '$215.50',
            social: 'customer#1257'
        }
    ];
    return (
        <div className={styles.Customers}>
            <div className={styles.header}>
                <Heading title={props.title} link={'/customers'}/>
                <div className={styles.func}>
                    <SearchInput placeholder='Search for a customer...' func={(e)=>{console.log(e)}} />
                </div>
            </div>
            <div className={styles.content}>
                <CustomersList items={items} />
                <Pagination
                    setCurrent={setCurrent}
                    maxIndex={maxIndex}
                    minIndex={minIndex}
                    setMaxIndex={setMaxIndex}
                    setMinIndex={setMinIndex}
                    setPageSize={setPageSize}
                    content={items}
                    current={current}
                    pageSize={pageSize}
                    />  
            </div>
        </div>
  )
}
