import React, { useState } from 'react';
import styles from './Security.module.css';
import { Button } from '../../../FunctionComponents/Button/Button';
import Google from '../../../../images/Google';
import Discord from '../../../../images/Discord';
import RequestService from '../../../../api/post';
import { useDispatch, useSelector } from 'react-redux';

export const Security = () => {
    const message = useSelector(state => state.message);
    const dispatch = useDispatch();
    const [passwordPair, setPasswordPair] = useState({
        oldPassword: "",
        password: ""
      });
    const [secondaryPassword, setSecondaryPassword] = useState("");
    console.log(passwordPair)
    async function onSubmit(){
        if(passwordPair.password===secondaryPassword){
            const response = await RequestService.PasswordNew(passwordPair);
            if(response){
                dispatch({
                    type: "SET_MESSAGE", 
                    payload: {isShow: true, status: true, text: 'Password changed successfully'}
                });
            }else{
                dispatch({
                    type: "SET_MESSAGE", 
                    payload: {isShow: !message.isShow}
                });
            }
        }else{
            dispatch({
                type: "SET_MESSAGE", 
                payload: {isShow: !message.isShow}
            });
        }
    }
    return (
        <div className={styles.Security}>
            <div className={`${styles.block} ${styles.name}`}>
                <h3>Account email</h3>
                <span className={styles.span}>We'll use this email address to contact you. If you want to change it, you’ll receive a verification email to your original email</span>
                <input type='text' />
                <div className={styles.bottom}>
                    <span className={styles.span}>Please use a valid email</span>
                    <Button styleB='orange' content='Save' />
                </div>
            </div>
            <div className={`${styles.block} ${styles.name}`}>
                <h3>Current password</h3>
                <span className={styles.span}>In order to change your password, you’ll first have to enter your original password</span>
                <input placeholder='Current password' value={passwordPair.oldPassword} onChange={(e)=>{
                    setPasswordPair({...passwordPair, oldPassword: e.target.value});
                }} type='password' />
                <div className={styles.newPassword}>
                    <div className={styles.half}>
                        <h3>New password</h3>
                        <span className={styles.span}>Ensure your account is using a long, random password to stay secure</span>
                        <input placeholder='New password' value={passwordPair.password} onChange={(e)=>{
                            setPasswordPair({...passwordPair, password: e.target.value});
                        }} type='password' />
                    </div>
                    <div className={styles.half}>
                        <h3>Confirm new password</h3>
                        <span className={styles.span}>To complete setting up a new password, please confirm it</span>
                        <input placeholder='Confirm password' value={secondaryPassword} onChange={(e)=>{
                            setSecondaryPassword(e.target.value);
                        }} type='password' />
                    </div>
                </div>
                <div className={styles.bottom}>
                    <Button styleB='orange' func={onSubmit} type='submit' content='Save' />
                </div>
            </div>
            <div className={`${styles.block} ${styles.name}`}>
                <h3>Connect social account</h3>
                <span className={styles.span}>This is where you can disconnect and connect a new social account</span>
                <div className={styles.link_input}>
                    <Discord viewBox='0 0 18 14' />
                    <Button styleB='orange' content='Connect new Discord' />
                </div>
                <div className={styles.link_input}>
                    <Google viewBox='0 0 16 17' />
                    <Button styleB='orange' content='Connect new Google' />
                </div>
                <div className={styles.bottom}>
                    <Button styleB='orange' content='Save' />
                </div>
            </div>
        </div>
    )
}
