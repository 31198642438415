import React, { useEffect, useState } from 'react';
import styles from './App.module.css';
import { Route, Link, Switch } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { Header, openMenu } from './components/Layouts/Header/Header';
import { NavBar } from './components/Layouts/NavBar/NavBar';
import { Dashboard } from './components/Layouts/Dashboard/Dashboard';
import { Orders } from './components/Layouts/Orders/Orders';
import { Products } from './components/Layouts/Products/Products';
import { Reviews } from './components/Layouts/Reviews/Reviews';
import { Tickets } from './components/Layouts/Tickets/Tickets';
import { Blacklists } from './components/Layouts/Blacklists/Blacklists';
import travolta from './images/travolta.gif'
import { Settings } from './components/Layouts/Settings/Settings';
import { Customers } from './components/Layouts/Customers/Customers';
import { Message } from './components/FunctionComponents/Message/Message';
import RequestService from './api/post';

export const NoMatch = () =>{
  return(
    <div className={styles.someComponent}>
      <Link to='/'><img src={travolta} alt='travolta'/></Link>
      <b>Nothing found</b>
      <span>Sorry, but there’s nothing for you to see here.</span>
    </div>
  )
}

function App() {
  const state = useSelector(state => state);
  const message = useSelector(state => state.message);
  const dispatch = useDispatch();
  async function getSettings(){
      if(localStorage.getItem('shop')){
        const response = await RequestService.SettingsGet(JSON.parse(localStorage.getItem('shop')).id);
        if(!response.data.data){
          dispatch({
            type: "SET_MESSAGE", 
            payload: {isShow: !message.isShow}
          });
        }else{
          localStorage.setItem('settings', JSON.stringify(response.data.data));
          dispatch({
              type: "SET_SETTINGS", 
              payload: response.data.data
          });
        }
      }
  }
  const location = useSelector(state => state.location);
  useEffect(()=>{
    setTimeout(()=>{
      getSettings();
    }, 500);
  }, []);
  return (
        <div className={styles.App}>
          <Message />
          <Header />
          <div className={styles.content}>
            <div id='back' onClick={openMenu} className={styles.back}></div>
            <NavBar />
            <div className={styles.main}>
              <div className={styles.main_content}>
                <Switch>
                  <Route exact path="/"><Dashboard title={location.title} /></Route>
                  <Route path="/products"><Products title={location.title} /></Route>
                  <Route path="/invoices"><Orders title={location.title} /></Route>
                  <Route path="/customers"><Customers title={location.title} /></Route>
                  <Route path="/reviews"><Reviews title={location.title} /></Route>
                  <Route path="/tickets"><Tickets title={location.title} /></Route>
                  <Route path="/blacklists"><Blacklists title={location.title} /></Route>
                  <Route path="/settings"><Settings title={location.title} /></Route>
                  <Route path="*"><NoMatch /></Route>
                </Switch>
              </div>
            </div>
          </div>
        </div>
  );
}

export default App;
