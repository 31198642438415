import React from 'react';
import styles from './Statuses.module.css'

export const Completed = () => {
  return (
    <div className={`${styles.Completed} ${styles.status}`}>Completed</div>
  )
}
export const Pending = () => {
    return (
        <div className={`${styles.Pending} ${styles.status}`}>Pending</div>
    )
}
export const New = () => {
    return (
        <div className={`${styles.Pending} ${styles.status}`}>New</div>
    )
}
export const Cancelled = () => {
    return (
    <div className={`${styles.Cancelled} ${styles.status}`}>Cancelled</div>
    )
}
export const Expired = () => {
    return (
    <div className={`${styles.Cancelled} ${styles.status}`}>Expired</div>
    )
}
export const Closed = () => {
    return (
    <div className={`${styles.Cancelled} ${styles.status}`}>Closed</div>
    )
}
export const Answered = () => {
  return (
    <div className={`${styles.Answered} ${styles.status}`}>Answered</div>
  )
}
export const AwaitingReply = () => {
  return (
    <div className={`${styles.Pending} ${styles.status}`}>Awaiting Reply</div>
  )
}
export const PendingM = () => {
    return (
        <div className={`${styles.PendingM} ${styles.status}`}>Pending</div>
    )
}
export const CustomerReply = () => {
    return (
    <div className={`${styles.CustomerReply} ${styles.status}`}>Customer Reply</div>
    )
}
export const Custom = (props) => {
  return (
    <div className={`${styles.Completed} ${styles.status}`}>{props.text}</div>
  )
}