import React, { useEffect, useState } from 'react';
import styles from './CouponsEdit.module.css';
import { Heading } from '../../../../Heading/Heading';
import { Button } from '../../../../../FunctionComponents/Button/Button';
import { Switch } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import {uid} from 'react-uid';
import moment from 'moment';
import RequestService from '../../../../../../api/post';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import Select from 'react-select';

export const CouponsEdit = () => {
    const settings = useSelector(state => state.settings);
    const state = useSelector(state => state);
    const message = useSelector(state => state.message);
    const dispatch = useDispatch();
    const [type, setType] = useState(['$', 'Amount off']);
    const [data, setData] = useState({
        name: makeid(11),
        discount: 1,
        isFixed: true,
        startDate: "2022-07-03T15:39:19.221Z",
        endDate: "",
        restrictToProducts: [
          0
        ],
        maxUses: 0
      });
    const [options, setOptions] = useState([
        { value: 1, label: 'string' }
    ]);
    const location = useLocation();
    const PageType = location.pathname.match(/\/coupons\/(.*)\//)?location.pathname.match(/\/coupons\/(.*)\//)[1]==='edit':false;
    const [products, setProducts] = useState([]);
    function setProductIds(data){
        let array = [];
        data.forEach(item => {
            array.push(item.value);
        });
        setData({...data, productIds: array});
        setProducts(data);
    }
    async function getProducts(){
        const response = await RequestService.ProductsGet(JSON.parse(localStorage.getItem('shop')).id);
        if(response){
            let array = [];
            response.data.data.forEach(item => {
                array.push({value: item.id, label: item.title});
            });
            console.log(array)
            setOptions(array);
        }
    }
    async function getCoupon(couponId){
        const response = await RequestService.CouponGet(JSON.parse(localStorage.getItem('shop')).id, couponId);
        console.log(response.data.data);
        const couponInfo = response.data.data;
        let array = [];
        let arrayProducts = [];
        couponInfo.restrictToProducts.forEach(item => {
            array.push(item.id);
            arrayProducts.push({value: item.id, label: item.title});
        });
        setProducts(arrayProducts);
        setData({...data, 
            name: couponInfo.name,
            discount: couponInfo.discount,
            isFixed: couponInfo.isFixed,
            maxUses: couponInfo.maxUses
          });
    }
    const [switches, setSwitches] = useState({
        payments: false,
        storewide: false,
        limit: false,
        expire: false
    });
    function makeid(length) {
        var result = '';
        var characters = 'abcdefghijklmnopqrstuvwxyz0123456789';
        var charactersLength = characters.length;
        for ( var i = 0; i < length; i++ ) {
          result += characters.charAt(Math.floor(Math.random() * 
     charactersLength));
       }
       return result;
    }
    async function onSubmit(){
        if(!switches.storewide){delete data.restrictToProducts};
        if(!switches.limit){delete data.maxUses};
        if(!switches.expire){delete data.startDate;delete data.endDate};
        console.log(data)
        const response = await RequestService.CouponNew(JSON.parse(localStorage.getItem('shop')).id, data);
        if(response){
            dispatch({
                type: "SET_MESSAGE", 
                payload: {isShow: true, status: true, text: 'Coupon edited successfully'}
            });
        }
        else{
            dispatch({
                type: "SET_MESSAGE", 
                payload: {isShow: !message.isShow}
            });
        }
    }
    let payments = Object.keys(settings.gateways).map((item, i)=>{
        let payment = '';
        switch (item){
            case 'coinbaseCommerce':
                payment = 'Coinbase Commerce';
                break;
            case 'stripeConnect':
                payment = 'Stripe';
                break;
            case 'payPalEmail':
                payment = 'PayPal';
                break;
            case 'cashappConnect':
                payment = 'Cash App';
                break;
        }
        return <option value={i} key={uid(item)}>{payment}</option>
    });
    useEffect(()=>{
        const isoStr = new Date().toISOString();
        setData({...data, startDate: isoStr});
        setData({...data, endDate: isoStr});
    }, [state]);
    useEffect(()=>{
        if(PageType){
            getCoupon(location.pathname.match(/\/.*\/([0-9]*)/)[1]);
        }
        getProducts();
    }, []);
    return (
        <div className={styles.CouponsEdit}>
            <div className={styles.header}>
                <Heading title='Coupons' pathTitle={PageType?'Edit Coupon':'New Coupon'}  link='/products/coupons'/>
                <div className={styles.func}>
                    <button className={styles.submit} onClick={()=>{onSubmit();setData({...data, name: makeid(11)})}}>+ Create coupon</button>
                </div>
            </div>
            <div className={styles.CouponsEdit_container}>
                <div className={styles.form}>
                    <h3 className={styles.h3}>Coupon code</h3>
                    <span>Generate a random coupon code oode or add a custom one below</span>
                    <div className={styles.input_wrapper}>
                        <input type='text' value={data.name} onChange={e=>{setData({...data, name: e.target.value})}} maxLength='11' className={styles.input} />
                        <Button content='Generate' func={()=>{setData({...data, name: makeid(11)})}} styleB='orange' size='small'/>
                    </div>
                </div>
                <div className={styles.otherSettings}>
                    <div className={styles.otherSetting}>
                        <div className={styles.top}>
                            <div className={styles.topHeader}>
                                <h3 className={styles.h3}>Payments</h3>
                                <span>Limit this coupon to certain select payment methods</span>
                            </div>
                            <Switch checked={switches.payments} onClick={()=>(setSwitches({...switches, payments: !switches.payments}))} />
                        </div>
                        <div className={switches.payments?styles.compShow:styles.compHide}>
                            <div className={styles.select}>
                                <select>
                                    {payments}
                                </select>
                            </div>
                        </div>
                    </div>
                    <div className={styles.otherSetting}>
                        <div className={styles.top}>
                            <div className={styles.topHeader}>
                                <h3 className={styles.h3}>Limit usage</h3>
                                <span>Limit coupon usage across all customers</span>
                            </div>
                            <Switch checked={switches.limit} onClick={()=>(setSwitches({...switches, limit: !switches.limit}))} />
                        </div>
                        <div className={switches.limit?styles.compShow:styles.compHide}>
                            <div className={styles.input_wrapper}>
                                <input type='number' value={data.maxUses} onChange={(e)=>{setData({...data, maxUses: parseInt(e.target.value)})}} className={styles.input} />
                            </div>
                        </div>
                        <div className={styles.top}>
                            <div className={styles.topHeader}>
                                <h3 className={styles.h3}>Expire coupon</h3>
                                <span>Expire the coupon code at a certain time</span>
                            </div>
                            <Switch checked={switches.expire} onClick={()=>(setSwitches({...switches, expire: !switches.expire}))} />
                        </div>
                        <div className={switches.expire?styles.compShow:styles.compHide}>
                            <div className={styles.input_wrapper}>
                                <input type='date' value={data.endDate?moment(data.endDate).format('yyyy-MM-DD'):'2000-01-01'}
                                    onChange={(e)=>{const isoStr = new Date(e.target.value).toISOString();setData({...data, endDate: isoStr})}} className={styles.input} />
                            </div>
                        </div>
                    </div>
                    <div className={styles.otherSetting}>
                        <div className={styles.top}>
                            <div className={styles.topHeader}>
                                <h3 className={styles.h3}>Apply storewide</h3>
                                <span>Limit this coupon to certain select products or to your entire store</span>
                            </div>
                            <Switch checked={switches.storewide} onChange={()=>(setSwitches({...switches, storewide: !switches.storewide}))} />
                        </div>
                        <div className={switches.storewide?styles.compShow:styles.compHide}>
                            <div className={styles.select}>
                                <Select options={options} isMulti value={products} onChange={(e)=>{setProductIds(e)}} />
                            </div>
                        </div>
                    </div>
                    <div className={styles.otherSetting}>
                        <h3 className={styles.h3}>Coupon value</h3>
                        <span>Select the value of the coupon and whether or not it’s percent-based or amount-based</span>
                        <div className={styles.couponInput_wrapper}>
                            <input type='number' value={data.discount} placeholder={type[1]} onChange={(e)=>{setData({...data, discount: e.target.value>0?e.target.value:1})}} className={styles.couponInput} />
                            <span>{type[0]}</span>
                        </div>
                        <div className={styles.radio}>
                            <div className={styles.form_radio}>
                                <input defaultChecked id="radio1" type="radio" onChange={()=>{setType(['$', 'Amount off']);setData({...data, isFixed: true})}} name="radio"/>
                                <label htmlFor="radio1">$ Fixed amount</label>
                            </div>
                            <div className={styles.form_radio}>
                                <input id="radio2" type="radio" onChange={()=>{setType(['%', 'Percent off']);setData({...data, isFixed: false})}} name="radio"/>
                                <label htmlFor="radio2">% Percentange</label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
