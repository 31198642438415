import * as React from "react";

function Icon(props) {
  return (
    <svg
      width={30}
      height={30}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M29.337 15.187a14.5 14.5 0 11-29 0 14.5 14.5 0 0129 0zM10.04 9.107a.907.907 0 00-1.283 1.284l4.797 4.796-4.797 4.796a.908.908 0 001.283 1.283l4.796-4.798 4.796 4.798a.907.907 0 101.283-1.283l-4.798-4.796 4.798-4.796a.907.907 0 10-1.284-1.283l-4.795 4.797-4.796-4.797z"
        fill="#E91C1C"
      />
    </svg>
  );
}

export default Icon;
