import * as React from "react";

function Icon(props) {
  return (
    <svg
      width={26}
      height={25}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect
        x={0.475}
        y={0.339}
        width={24.53}
        height={24.53}
        rx={8}
        fill="#FF4500"
      />
      <path
        d="M19.622 12.702c0-.839-.679-1.505-1.505-1.505-.407 0-.777.16-1.048.42-1.036-.74-2.455-1.221-4.033-1.283l.69-3.231 2.245.48a1.073 1.073 0 101.073-1.122c-.42 0-.79.247-.962.605l-2.504-.53a.262.262 0 00-.197.036.24.24 0 00-.111.173l-.765 3.601c-1.603.05-3.046.53-4.095 1.283a1.515 1.515 0 00-1.048-.42 1.503 1.503 0 00-.617 2.874 2.735 2.735 0 00-.037.457c0 2.318 2.701 4.205 6.031 4.205 3.33 0 6.032-1.874 6.032-4.205 0-.148-.013-.309-.037-.457a1.52 1.52 0 00.888-1.381zM9.286 13.775a1.074 1.074 0 111.073 1.073c-.592.012-1.073-.481-1.073-1.073zm6.007 2.849c-.74.74-2.146.79-2.553.79-.42 0-1.826-.062-2.553-.79a.276.276 0 010-.395.276.276 0 01.394 0c.469.469 1.456.63 2.159.63.703 0 1.701-.161 2.158-.63a.276.276 0 01.395 0 .3.3 0 010 .395zm-.198-1.764a1.074 1.074 0 111.073-1.073c0 .58-.48 1.073-1.073 1.073z"
        fill="#fff"
      />
    </svg>
  );
}

export default Icon;
