import React, { useEffect, useState } from 'react';
import styles from './Branding.module.css';
import { Button } from '../../../FunctionComponents/Button/Button';
import {uid} from 'react-uid';
import { Checkbox } from 'antd';
import { Business } from '../../../StyledComponents/Types/Types';
import Clip from '../../../../images/Clip';
import Instagram from '../../../../images/Instagram';
import Telegram from '../../../../images/Telegram';
import TikTok from '../../../../images/TikTok';
import TwitterIcon from '../../../../images/TwitterIcon';
import Reddit from '../../../../images/Reddit';
import DiscordIcon from '../../../../images/DiscordIcon';
import YouTube from '../../../../images/YouTube';
import Facebook from '../../../../images/Facebook';
import ReactMde from "react-mde";
import * as Showdown from "showdown";
import "react-mde/lib/styles/css/react-mde-all.css";
import "./mde-styles.css";
import RequestService from '../../../../api/post';
import { useDispatch, useSelector } from 'react-redux';

function loadSuggestions(text) {
    return new Promise((accept, reject) => {
      setTimeout(() => {
        const suggestions = [].filter((i) => i.preview.toLowerCase().includes(text.toLowerCase()));
        accept(suggestions);
      }, 250);
    });
  }
const converter = new Showdown.Converter({
    tables: true,
    simplifiedAutoLink: true,
    strikethrough: true,
    tasklists: true
  });

export const Links = (props) => {
    const [links, setLinks] = [props.links, props.setLinks];
    const linksData = [
        {
            name: 'Telegram',
            icon: <Telegram viewBox='0 0 26 25' />,
            link: 'https://t.me/example'
        },
        {
            name: 'Discord',
            icon: <DiscordIcon viewBox='0 0 26 25' />,
            link: 'https://discord.gg/example'
        },
        {
            name: 'TikTok',
            icon: <TikTok viewBox='0 0 26 25' />,
            link: 'https://tiktok.com/example'
        },
        {
            name: 'Instagram',
            icon: <Instagram viewBox='0 0 26 25' />,
            link: 'https://instagram.com/example'
        },
        {
            name: 'Twitter',
            icon: <TwitterIcon viewBox='0 0 26 25' />,
            link: 'https://twitter.com/example'
        },
        {
            name: 'YouTube',
            icon: <YouTube viewBox='0 0 26 25' />,
            link: 'https://youtube.com/example'
        },
        {
            name: 'Reddit',
            icon: <Reddit viewBox='0 0 26 25' />,
            link: 'https://reddit.com/example'
        },
        {
            name: 'Facebook',
            icon: <Facebook viewBox='0 0 26 25' />,
            link: 'https://facebook.com/example'
        }
    ];
    const linksItems = Object.keys(linksData).map((item, i)=>{
        return (
            <div key={uid(item)} className={styles.link}>
                <span className={styles.link_span}>{linksData[item].name} link</span>
                <div className={styles.link_input}>
                    {linksData[item].icon}
                    <input placeholder={linksData[item].link} type='text' value={links[i].link} onChange={(e)=>{
                        setLinks(links.map((el, index) => (index === i ? {...el, link: e.target.value} : el)));
                    }}/>
                </div>
            </div>
        )
    });
    return linksItems
}

export const Branding = () => {
    const settings = useSelector(state => state.settings);
    const message = useSelector(state => state.message);
    const dispatch = useDispatch();
    const [error, setError] = useState({});
    const [design, setDesign] = useState(localStorage.getItem('settings')?JSON.parse(localStorage.getItem('settings')).design:settings.design);
    const [logoImg, setLogoImg] = useState();
    const [selectedTab, setSelectedTab] = React.useState("write");
    const [terms, setTerms] = useState('');
    let [links, setLinks] = useState([
        {platform: 0, link: ''},
        {platform: 1, link: ''},
        {platform: 2, link: ''},
        {platform: 3, link: ''},
        {platform: 4, link: ''},
        {platform: 5, link: ''},
        {platform: 6, link: ''},
        {platform: 7, link: ''},
    ]);
    const handleChange = e => {
        const file = document.getElementById('file');
        const fileName_ = document.getElementById('fileName');
        file.innerText = '';
        fileName_.innerText = e.target.files[0].name;
        const formData = new FormData();
        formData.append('file', e.target.files[0]);
        setLogoImg(formData);
    }
    async function onSubmit(method){
        let response;
        switch(method){
            case 'design':
                response = await RequestService.DesignSettings(JSON.parse(localStorage.getItem('shop')).id, design);
                break;
            case 'logo':
                response = await RequestService.ShopsLogo(JSON.parse(localStorage.getItem('shop')).id, logoImg);
                break;
            case 'links':
                let array = [];
                links.forEach(element => {
                    if(element.link){
                        array.push(element);
                    }
                });
                const data = {
                    socialLinks: array
                }
                console.log(data);
                response = await RequestService.ShopsLinks(JSON.parse(localStorage.getItem('shop')).id, data);
                break;
            case 'terms':
                const terms_ = {
                    terms: terms
                };
                response = await RequestService.ShopsTerms(JSON.parse(localStorage.getItem('shop')).id, terms_);
                break;
        }
        if(response){
            localStorage.setItem('settings', JSON.stringify({...settings, design: design}));
            dispatch({
                type: "SET_SETTINGS", 
                payload: {...settings, design: design}
            });
            dispatch({
                type: "SET_MESSAGE", 
                payload: {isShow: true, status: true, text: 'Shop settings saved successfully'}
            });
        }
        else{
            dispatch({
                type: "SET_MESSAGE", 
                payload: {isShow: !message.isShow}
            });
            setError(design)
        }
    }
    useEffect(()=>{
        if(localStorage.getItem('settings')){
            setDesign(JSON.parse(localStorage.getItem('settings')).design);
            if(JSON.parse(localStorage.getItem('settings')).socialLinks){
                links.forEach((item, i) => {
                    const link = JSON.parse(localStorage.getItem('settings')).socialLinks[i];
                    if(link){
                        let links_ = links;
                        links_[link.platform] = {...links_[link.platform], link: link.link};
                        setLinks(links_);
                    }
                });
            }
            setTerms(JSON.parse(localStorage.getItem('settings')).preferences?
            JSON.parse(localStorage.getItem('settings')).preferences.terms
            :'');
        }
    // eslint-disable-next-line 
    }, [settings]);
    return (
        <div className={styles.Branding}>
            <div className={`${styles.block} ${styles.name}`}>
                <h3>Business color</h3>
                <span className={styles.span}>Edit or change your business color for buttons and tabs</span>
                <div className={styles.colorInput}>
                    <div className={styles.colorInput_input}>
                        <input type="color" onChange={(e)=>{setDesign({...design, primaryColor: e.target.value})}} value={design.primaryColor}/>
                    </div>
                    <span>{design.primaryColor}</span>
                </div>
                <h3>General design</h3>
                <div className={styles.checkBoxes}>
                    <div className={styles.checkBox}>
                        <Checkbox onChange={(e)=>{setDesign({...design, darkMode: !design.darkMode})}} checked={design.darkMode}/>
                        <span className={styles.span}>Force dark mode</span>
                    </div>
                    <div className={styles.checkBox}>
                        <Checkbox onChange={(e)=>{setDesign({...design, disableBusinessName: !design.disableBusinessName})}} checked={design.disableBusinessName}/>
                        <span className={styles.span}>Disable business name</span>
                    </div>
                    <div className={styles.checkBox}>
                        <Checkbox onChange={(e)=>{setDesign({...design, disableSearchBar: !design.disableSearchBar})}} checked={design.disableSearchBar}/>
                        <span className={styles.span}>Disable search bar</span>
                    </div>
                    <div className={styles.checkBox}>
                        <Checkbox onChange={(e)=>{setDesign({...design, centerProductTitle: !design.centerProductTitle})}} checked={design.centerProductTitle}/>
                        <span className={styles.span}>Center title for products</span>
                    </div>
                    <div className={styles.checkBox}>
                        <Checkbox onChange={(e)=>{setDesign({...design, disableBusinessLogo: !design.disableBusinessLogo})}} checked={design.disableBusinessLogo}/>
                        <span className={styles.span}>Disable business logo</span>
                    </div>
                    <div className={styles.checkBox}>
                        <Checkbox disabled={true} onChange={(e)=>{setDesign({...design, disableReviews: !design.disableReviews})}} checked={false}/>
                        <span className={styles.span}>Disable reviews</span>
                        <div className={styles.business}><Business /></div>
                    </div>
                    <div className={styles.checkBox}>
                        <Checkbox onChange={(e)=>{setDesign({...design, disableProductsSold: !design.disableProductsSold})}} checked={design.disableProductsSold}/>
                        <span className={styles.span}>Disable products sold</span>
                    </div>
                    <div className={styles.checkBox}>
                        <Checkbox onChange={(e)=>{setDesign({...design, hideOutOfStockProducts: !design.hideOutOfStockProducts})}} checked={design.hideOutOfStockProducts}/>
                        <span className={styles.span}>Hide products out of stock</span>
                    </div>
                </div>
                <div className={styles.bottom}>
                    <Button styleB='orange' func={()=>{onSubmit('design')}} content='Save' />
                </div>
            </div>
            <div className={`${styles.block} ${styles.name}`}>
                <h3>Business logo</h3>
                <span className={styles.span}>This logo will be visible on your storefront. Recommended size: 512 x 512</span>
                <div className={styles.input_file}>
                    <label className={styles.content}>
                        <div id='file' className={styles.clip}>
                            <Clip viewBox='0 0 22 34' />
                            <span>Only accepts png, jpg, or gif</span>
                        </div>
                        <span className={styles.name} id='fileName'></span>
                        <input onChange={handleChange} accept="image/*" type="file" name="image" />
                    </label>
                </div>
                <div className={styles.bottom}>
                    <Button styleB='orange' func={()=>{onSubmit('logo')}} content='Save' />
                </div>
            </div>
            <div className={`${styles.block} ${styles.name}`}>
                <h3>Social links</h3>
                <span className={styles.span}>We will display these link on the side of your storefront</span>
                <div className={styles.links}>
                    <Links links={links} setLinks={setLinks} />
                </div>
                <div className={styles.bottom}>
                    <span className={styles.span}>Please make sure you enter valid usernames</span>
                    <Button styleB='orange' func={()=>{onSubmit('links')}} content='Save' />
                </div>
            </div>
            <div className={`${styles.block} ${styles.name}`}>
                <h3>Terms of service</h3>
                <div className={styles.mde}>
                    <ReactMde
                        value={terms}
                        onChange={setTerms}
                        selectedTab={selectedTab}
                        onTabChange={setSelectedTab}
                        generateMarkdownPreview={(markdown) =>
                            Promise.resolve(converter.makeHtml(markdown))
                        }
                        loadSuggestions={loadSuggestions}
                        childProps={{
                            writeButton: {
                                tabIndex: -1
                            }
                        }}
                    />
                </div>
                <div className={styles.bottom}>
                    <span className={styles.span}>A terms of service is optional but strongly recommended</span>
                    <Button styleB='orange' func={()=>{onSubmit('terms')}} content='Save' />
                </div>
            </div>
        </div>
    )
}
