import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from "react-router-dom";
import App from './App';
import { legacy_createStore as createStore } from 'redux';
import { Provider } from 'react-redux';
import {
    SET_LOCATION,
    SET_ACCESS_TOKEN,
    SET_REFRESH_TOKEN,
    SET_EMAIL,
    SET_SHOPS,
    SET_SHOP,
    SET_SETTINGS,
    SET_MESSAGE
  } from './actions';

const defaultState = {
  message: {
    isShow: false,
    status: false,
    text: 'text'
  },
  email: localStorage.getItem('data')?JSON.parse(localStorage.getItem('data')).email:'demotest@gmail.com',
  shops: {},
  settings: {
    id: 1,
    name: "string",
    currency: "rub",
    timeZoneInfo: 3,
    onHold: false,
    design: {
      id: 1,
      primaryColor: "#ffffff",
      darkMode: false,
      disableBusinessName: false,
      disableSearchBar: false,
      centerProductTitle: false,
      disableBusinessLogo: false,
      disableReviews: true,
      disableProductsSold: false,
      hideOutOfStockProducts: false
    },
    gateways: {
      coinbaseCommerce: {
        apikey: "",
        webhookSecret: "",
        settings: {
          rules: {
            blockVpn: true
          }
        },
        isActive: true
      }
    },
    createdAt: "2022-05-28T16:37:27.193797Z"
  },
  shop: {name: 'ShopName'},
  location: {
    title: 'Dashboard',
    path: '/'
  }
}

const reducer = (state = defaultState, action) => {
  switch(action.type) {
    case SET_LOCATION:
      return {...state, location: action.payload}
    case SET_ACCESS_TOKEN:
      return {...state, accessToken: action.payload}
    case SET_REFRESH_TOKEN:
      return {...state, refreshToken: action.payload}
    case SET_EMAIL:
      return {...state, email: action.payload}
    case SET_SHOPS:
      return {...state, shops: action.payload}
    case SET_SHOP:
      return {...state, shop: action.payload}
    case SET_SETTINGS:
      return {...state, settings: action.payload}
    case SET_MESSAGE:
      return {...state, message: action.payload}
    default:
      return state
  }
}

export const store = createStore(reducer);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <Provider store={store}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </Provider>
);
