import React, { useState, useEffect } from 'react';
import styles from './Reply.module.css';
import { Completed } from '../../../StyledComponents/Statuses/Statuses';
import { useParams } from 'react-router-dom';
import {uid} from 'react-uid';
import ArrowSend from '../../../../images/ArrowSend';
import { CloseButton } from '../../../StyledComponents/Close/Close';
import { Link } from 'react-router-dom';
import RequestService from '../../../../api/post';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';

export const Reply = (props) => {
    const message = useSelector(state => state.message);
    const dispatch = useDispatch();
    const { id } = useParams();
    const [ticket, setTicket] = useState([]);
    const [messages, setMessages] = useState([]);
    const [newMessage, setNewMessage] = useState('');
    async function getTicket(){
        const response = await RequestService.TicketGet(JSON.parse(localStorage.getItem('shop')).id, id);
        try{
            setTicket(response.data.data?response.data.data:{});
            setMessages(response.data.data.messages.map(item => {
                return(
                    <div key={uid(item)} className={`${styles.message_wrapper} ${item.asMerchant?styles.right:styles.left}`}>
                        <div className={styles.message_content}>
                            <div className={styles.name}>
                                <span>{item.asMerchant?'You':'Customer'}</span>
                            </div>
                            <div className={styles.text}>
                                {item.content}
                            </div>
                        </div>
                        <div className={styles.date}>
                            {moment(item.createdAt).format("HH:mm DD MMM")}
                        </div>
                    </div>
                )
            }));
        }
        catch{
            console.log('error')
        }
    }
    async function onSubmit(){
        const data = {
            content: newMessage
        }
        const response = await RequestService.TicketPost(JSON.parse(localStorage.getItem('shop')).id, id, data);
        try{
            if(response){
                getTicket();
                const neResponse = await RequestService.TicketGet(JSON.parse(localStorage.getItem('shop')).id, id);
                setMessages(neResponse.data.data.messages.map(item => {
                    return(
                        <div key={uid(item)} className={`${styles.message_wrapper} ${item.asMerchant?styles.right:styles.left}`}>
                            <div className={styles.message_content}>
                                <div className={styles.name}>
                                    <span>{item.asMerchant?'You':'Customer'}</span>
                                </div>
                                <div className={styles.text}>
                                    {item.content}
                                </div>
                            </div>
                            <div className={styles.date}>
                                    {moment(item.createdAt).format("HH:mm DD MMM")}
                            </div>
                        </div>
                    )
                }));
                setNewMessage('');
            }
        }
        catch{
            console.log('error')
        }
    }
    useEffect(()=>{
        getTicket();
    }, []);
    useEffect(()=>{
        setTimeout(() => {
            getTicket();
        }, 300000);
    });
  return (
    <div>
        <div className={styles.header}>
            <h2 className={styles.h2}>Ticket: {ticket.title}</h2>
            <Link to='/tickets'><CloseButton/></Link>
        </div>
        <div className={styles.wrapper}>
            <div className={styles.content}>
                <span className={styles.title}>Order details</span>
                {ticket.invoiceId?
                <div className={styles.infoItem}>
                    <h3 className={styles.h3}>Invoice ID</h3>
                    <span>{ticket.invoiceId}</span>
                </div>
                :''}
                <div className={styles.infoItem}>
                    <h3 className={styles.h3}>Customer</h3>
                    <span>{ticket.customerInfo?ticket.customerInfo.customerForShop.customer.email:''}</span>
                </div>
                {ticket.product?
                <div className={styles.infoItem}>
                    <h3 className={styles.h3}>Product</h3>
                    <span>{ticket.product}</span>
                </div>
                :''}
                {ticket.value?
                <div className={styles.infoItem}>
                    <h3 className={styles.h3}>Value</h3>
                    <span>{ticket.value}</span>
                </div>
                :''}
                {ticket.gateway?
                <div className={styles.infoItem}>
                    <h3 className={styles.h3}>Gateway</h3>
                    <span>{ticket.gateway}</span>
                </div>
                :''}
                {ticket.country?
                <div className={styles.infoItem}>
                    <h3 className={styles.h3}>Country</h3>
                    <div className={styles.infoItem__country}>
                        <span>{ticket.country}</span>
                    </div>
                </div>
                :''}
                <div className={styles.infoItem}>
                    <h3 className={styles.h3}>Created At</h3>
                    <span>{moment(ticket.createdAt).fromNow()}</span>
                </div>
            </div>
            <div className={styles.chat_wrapper}>
                <div className={styles.chat}>
                    {messages}
                </div>
                <div className={styles.chat_send}>
                    <div className={styles.textarea_wrapper}>
                        <form >
                            <textarea value={newMessage} onChange={(e)=>{setNewMessage(e.target.value)}} placeholder='Type a message...' />
                            <div onClick={()=>{onSubmit()}} className={styles.send}>
                                <ArrowSend viewBox='0 0 22 14'/>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}
