import * as React from "react";

function Icon(props) {
  return (
    <svg
      width={8}
      height={8}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M4.728.742V3.71h2.954v.882H4.728V7.56h-.952V4.592H.822V3.71h2.954V.742h.952z"
        fill="#0C1525"
      />
    </svg>
  );
}

export default Icon;