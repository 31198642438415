import * as React from "react";

function Icon(props) {
  return (
    <svg
      width={16}
      height={17}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M1.5.707a1.5 1.5 0 00-1.5 1.5v11.5a1 1 0 001 1v-12.5a.5.5 0 01.5-.5H14a1 1 0 00-1-1H1.5z"
        fill="#0C1525"
      />
      <path
        d="M3.5 2.707a1.5 1.5 0 00-1.5 1.5v11a1.5 1.5 0 001.5 1.5h6.086a1.5 1.5 0 001.06-.44l4.915-4.915a1.5 1.5 0 00.439-1.06V4.208a1.5 1.5 0 00-1.5-1.5h-11zm-.5 1.5a.5.5 0 01.5-.5h11a.5.5 0 01.5.5v5.5h-4.5a1.5 1.5 0 00-1.5 1.5v4.5H3.5a.5.5 0 01-.5-.5v-11zM10 15.5v-4.293a.5.5 0 01.5-.5h4.293L10 15.498z"
        fill="#0C1525"
      />
    </svg>
  );
}

export default Icon;