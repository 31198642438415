import React from 'react';

export const Item = (props) => {
    function getDiffCount(array){
        const len = array.length-1;
        const x = array[len];
        const y = array[len-1];
        let diff = 0;
        let str = '';
        if(array[len]>array[len-1]){
            diff = ((x-y)/x)*100;
            str = '▲ '+diff.toFixed(1)
        }
        else if(array[len]<array[len-1]){
            diff = ((y-x)/x)*100;
            str = '▼ -'+diff.toFixed(1)
        }
        else{
            diff = 0;
            str = diff
        }
        return str;
    }
    function intToString(num) {
        num = num.toString().replace(/[^0-9.]/g, '');
        if (num < 1000) {
            return num;
        }
        let si = [
          {v: 1E3, s: "K"},
          {v: 1E6, s: "M"},
          {v: 1E9, s: "B"},
          {v: 1E12, s: "T"},
          {v: 1E15, s: "P"},
          {v: 1E18, s: "E"}
          ];
        let index;
        for (index = si.length - 1; index > 0; index--) {
            if (num >= si[index].v) {
                break;
            }
        }
        return (num / si[index].v).toFixed(2).replace(/\.0+$|(\.[0-9]*[1-9])0+$/, "$1") + si[index].s;
    }
    return (
        <div className={props.style}>
                <span>{props.name}</span>
                <h3>{props.isMoney?'$':''}{intToString(props.count)}</h3>
                <small>{getDiffCount(props.array)}% past day</small>
        </div>
    )
}
