import * as React from "react";

function Icon(props) {
  return (
    <svg
      width={15}
      height={17}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M4.556 1.345c1.085-.295 2.238-.56 2.928-.56s1.843.265 2.928.56c1.11.3 2.23.655 2.887.87a1.54 1.54 0 011.044 1.262c.596 4.477-.787 7.795-2.465 9.99a11.779 11.779 0 01-2.517 2.453c-.33.238-.681.447-1.048.625-.28.132-.58.24-.829.24-.248 0-.548-.108-.829-.24a7.172 7.172 0 01-1.048-.625 11.78 11.78 0 01-2.517-2.453C1.412 11.272.03 7.954.625 3.477A1.54 1.54 0 011.67 2.215a62.452 62.452 0 012.887-.87z"
        fill="#0C1525"
      />
    </svg>
  );
}

export default Icon;