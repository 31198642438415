import * as React from "react";

function Icon(props) {
  return (
    <svg
      width={25}
      height={25}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M.262 6.607a6 6 0 016-6h11.81a6 6 0 016 6V18.42a6 6 0 01-6 6H6.263a6 6 0 01-6-6V6.607z"
        fill="#6871ED"
      />
      <path
        d="M13.254 10.931c-1.203-.444-1.862-.79-1.862-1.334 0-.46.38-.724 1.055-.724 1.235 0 2.503.477 3.376.905l.494-3.047c-.692-.329-2.108-.872-4.068-.872-1.384 0-2.537.362-3.36 1.037-.857.708-1.301 1.73-1.301 2.965 0 2.24 1.367 3.195 3.59 4.002 1.433.51 1.91.873 1.91 1.433 0 .544-.46.857-1.3.857-1.038 0-2.75-.51-3.871-1.17l-.494 3.08c.955.544 2.734 1.104 4.579 1.104 1.466 0 2.685-.346 3.508-1.005.922-.724 1.4-1.795 1.4-3.179 0-2.29-1.4-3.245-3.656-4.052z"
        fill="#fff"
      />
    </svg>
  );
}

export default Icon;