import * as React from "react";

function Icon(props) {
  return (
    <svg
      width={17}
      height={17}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M2.705.575a.5.5 0 01.5-.5h10a.5.5 0 01.5.5c0 .538-.011 1.05-.034 1.536A3 3 0 1112.54 8c-.79 1.865-1.878 2.777-2.834 3.011v2.173l1.425.356c.194.048.377.135.537.255l1.838 1.379a.5.5 0 01-.3.9h-10a.5.5 0 01-.3-.9l1.838-1.379c.16-.12.343-.207.537-.255l1.425-.356v-2.173C5.75 10.778 4.662 9.866 3.872 8A3 3 0 112.74 2.11 33.083 33.083 0 012.705.575zm.1 2.54a2 2 0 10.72 3.935C3.19 6 2.935 4.704 2.804 3.115zM12.886 7.05a2 2 0 10.72-3.935c-.132 1.59-.387 2.885-.72 3.935zM3.71 1.075c.007.517.026 1.006.056 1.469.13 2.028.457 3.546.87 4.667.864 2.344 2.054 2.864 2.57 2.864a.5.5 0 01.5.5v2.61a1 1 0 01-.757.97l-1.426.356a.5.5 0 00-.179.085l-.638.479h7l-.637-.479a.502.502 0 00-.18-.085l-1.426-.356a1 1 0 01-.757-.97v-2.61a.5.5 0 01.5-.5c.516 0 1.707-.52 2.57-2.864.413-1.12.74-2.64.87-4.667.03-.463.05-.952.056-1.469H3.71z"
        fill="#000"
      />
    </svg>
  );
}

export default Icon;