import * as React from "react";

function Icon(props) {
  return (
    <svg
      width={17}
      height={13}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M16.29 5.781a2.5 2.5 0 01-1.455 2.272 3.51 3.51 0 00-.65-.824 1.5 1.5 0 10-.79-2.896.5.5 0 01-.626-.42 3 3 0 00-5.22-1.626c-.427-.02-.856.01-1.276.088a4.002 4.002 0 017.392.91 2.5 2.5 0 012.626 2.496z"
        fill="#0C1525"
      />
      <path
        d="M7.29 3.281a4.5 4.5 0 014.474 4h.027a2.5 2.5 0 110 5h-8.5a3 3 0 01-.247-5.99 4.502 4.502 0 014.247-3.01zm3.5 4.5a3.5 3.5 0 00-6.89-.873.5.5 0 01-.51.375 2 2 0 10-.1 3.998h8.5a1.5 1.5 0 10-.375-2.953.5.5 0 01-.624-.492v-.055z"
        fill="#0C1525"
      />
    </svg>
  );
}

export default Icon;