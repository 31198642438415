import React, { useEffect, useState } from 'react';
import styles from './Tickets.module.css';
import listStyles from './TicketsList.module.css';
import { Heading } from '../Heading/Heading';
import {uid} from 'react-uid';
import { SearchInput } from '../../FunctionComponents/Search/Search';
import { Route, useRouteMatch, Switch } from 'react-router-dom';
import { NoMatch } from '../../../App';
import { Reply } from './Reply/Reply';
import { New, Answered, AwaitingReply, Closed } from '../../StyledComponents/Statuses/Statuses';
import { Link } from 'react-router-dom';
import { Pagination } from '../../FunctionComponents/Pagination/Pagination';
import { useDispatch, useSelector } from 'react-redux';
import RequestService from '../../../api/post';
import moment from 'moment';


export const TicketsList = (props) =>{
    const statuses = [<New />, <Answered />, <AwaitingReply />, <Closed />];
    const tickets = props.tickets.map((item, i) => {
        return(
            <Link 
            to={`${props.path?props.path:'/tickets'}/${item.id}`} 
            className={`${listStyles.item} ${i+1<props.tickets.length?listStyles.notFinal:''}`} 
            key={uid(i)}>
                <div className={listStyles.value}>
                    <span className={listStyles.value__title}>{item.title}</span>
                </div>
                <div className={listStyles.value}>
                    <span>{item.customerInfo.customerForShop.customer.email}</span>
                </div>
                <div className={listStyles.value}>
                    <span>{moment(item.createdAt).format("HH:mm DD MMM")}</span>
                </div>
                <div className={listStyles.value}>
                    {statuses[item.status-1]}
                </div>
            </Link>
        );
    });
    return(
        <div className={listStyles.TicketsList}>
            <div className={listStyles.title}>
                <h4>TITLE</h4>
                <h4>EMAIL</h4>
                <h4>LAST UPDATE</h4>
                <h4>STATUS</h4>
            </div>
            <div className={listStyles.items}>
                {tickets}
            </div>
        </div>
    )
}
export const Tickets = (props) => {
    const message = useSelector(state => state.message);
    const dispatch = useDispatch();
    const [pageSize, setPageSize] = useState(10);
    const [current, setCurrent] = useState(1);
    const [minIndex, setMinIndex] = useState(0);
    const [maxIndex, setMaxIndex] = useState(pageSize);
    const [tickets, setTickets] = useState([]);
    console.log(tickets)
    async function getTickets(){
        const response = await RequestService.TicketsGet(JSON.parse(localStorage.getItem('shop')).id);
        try{
            if(!response.data.data){
                dispatch({
                    type: "SET_MESSAGE", 
                    payload: {isShow: !message.isShow}
                });
            }else{
                setTickets(response.data.data?response.data.data:{});
            }
        }
        catch{
            console.log('error')
        }
    }
    // const tickets = [
    //     {
    //         id: '45826b-0b6e79c8a0-f674ba',
    //         title: 'hello',
    //         email: 'chairmantest@gmail.com',
    //         update: '2022 23, Oct 16:16',
    //         status: <Answered />
    //     },
    //     {
    //         id: '45826b-0b6e79c8a0-f674bb',
    //         title: 'hello',
    //         email: 'chairmantest@gmail.com',
    //         update: '2022 23, Oct 16:16',
    //         status: <PendingM />
    //     },
    //     {
    //         id: '45826b-0b6e79c8a0-f674bc',
    //         title: 'hello',
    //         email: 'chairmantest@gmail.com',
    //         update: '2022 23, Oct 16:16',
    //         status: <CustomerReply />
    //     }
    // ];
    const {path} = useRouteMatch();
    useEffect(()=>{
        getTickets();
    }, []);
    return (
        <div className={styles.Tickets}>
            <Switch>
                <Route exact path={path}>
                    <div className={styles.header}>
                        <Heading title={props.title} link='/tickets'/>
                        <div className={styles.func}>
                            <SearchInput placeholder='Search for a tickets...' func={(e)=>{console.log(e)}} />
                        </div>
                    </div>
                    <div className={styles.content}>
                        <TicketsList tickets={tickets} path={path} />
                        <Pagination
                            setCurrent={setCurrent}
                            maxIndex={maxIndex}
                            minIndex={minIndex}
                            setMaxIndex={setMaxIndex}
                            setMinIndex={setMinIndex}
                            setPageSize={setPageSize}
                            content={tickets}
                            current={current}
                            pageSize={pageSize}
                            />  
                    </div>
                </Route>
                <Route path={`${path}/:id`}>
                    <Reply tickets={tickets} />
                </Route>
                <Route path='*'>
                    <NoMatch />
                </Route>
            </Switch>
        </div>
  )
}
