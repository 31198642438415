import React, { useState } from 'react';
import styles from './InfoCardEdit.module.css';
import ReactMde from "react-mde";
import * as Showdown from "showdown";
import "react-mde/lib/styles/css/react-mde-all.css";
import "./mde-styles.css";
import Clip from '../../../../../images/Clip';
import { Checkbox } from 'antd';
import RoundInfo from '../../../../../images/RoundInfo'

function loadSuggestions(text) {
    return new Promise((accept, reject) => {
      setTimeout(() => {
        const suggestions = [].filter((i) => i.preview.toLowerCase().includes(text.toLowerCase()));
        accept(suggestions);
      }, 250);
    });
  }
const converter = new Showdown.Converter({
    tables: true,
    simplifiedAutoLink: true,
    strikethrough: true,
    tasklists: true
});

const handleChange = e => {
    const file = document.getElementById('file');
    const fileName_ = document.getElementById('fileName');
    file.innerText = '';
    fileName_.innerText = e.target.value.replace('C:\\fakepath\\', '');
}
function onChange(e) {
    console.log(`checked = ${e.target.checked}`);
}
export const InfoCardEdit = () => {
    const [value, setValue] = useState("");
    const [selectedTab, setSelectedTab] = React.useState("write");
    return (
        <div className={styles.InfoCardEdit}>
            <div className={styles.mainInfo}>
                <div className={styles.title}>
                    <h3 className={styles.h3}>Title</h3>
                    <input placeholder='Info Card title' className={styles.input} />
                </div>
            </div>
            <div className={styles.editor}>
                <h3 className={styles.h3}>Description</h3>
                <ReactMde
                    value={value}
                    onChange={setValue}
                    selectedTab={selectedTab}
                    onTabChange={setSelectedTab}
                    generateMarkdownPreview={(markdown) =>
                        Promise.resolve(converter.makeHtml(markdown))
                    }
                    loadSuggestions={loadSuggestions}
                    childProps={{
                        writeButton: {
                            tabIndex: -1
                        }
                    }}
                />
            </div>
            <h3 className={styles.h3}>Image<small> (optional)</small></h3>
            <div className={styles.input_file}>
                <label className={styles.content}>
                    <div id='file' className={styles.clip}>
                        <Clip viewBox='0 0 22 34' />
                        <span>Only accepts png, jpg, or gif</span>
                    </div>
                    <span className={styles.name} id='fileName'></span>
                    <input onChange={handleChange} type="file" name="image" />
                </label>
            </div>
            <div className={styles.checkboxes}>
                <Checkbox onChange={onChange}>
                <span className={styles.span}>Unlisted<span data-tooltip="Test text" data-tooltip-position="top"><RoundInfo viewBox='0 0 11 11' /></span></span>
                </Checkbox>
                <Checkbox onChange={onChange}>
                <span className={styles.span}>Private<span data-tooltip="Test text" data-tooltip-position="top"><RoundInfo viewBox='0 0 11 11' /></span></span>
                </Checkbox>
            </div>
        </div>
    )
}
