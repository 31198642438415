import * as React from "react";

function Icon(props) {
  return (
    <svg
      width={25}
      height={25}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect
        x={0.426}
        y={0.11}
        width={24.53}
        height={24.53}
        rx={8}
        fill="#5865F2"
      />
      <path
        d="M16.807 8.808a.03.03 0 00-.015-.014 10.12 10.12 0 00-2.409-.71.038.038 0 00-.022.002.036.036 0 00-.017.015c-.11.19-.21.386-.3.586a9.458 9.458 0 00-2.704 0c-.09-.2-.192-.396-.305-.586a.038.038 0 00-.016-.015.04.04 0 00-.023-.003 10.09 10.09 0 00-2.408.711.034.034 0 00-.016.013c-1.534 2.182-1.954 4.31-1.748 6.412 0 .005.003.01.005.014a.04.04 0 00.01.012 10.01 10.01 0 002.955 1.422.04.04 0 00.023 0 .038.038 0 00.019-.013c.228-.296.43-.609.604-.936a.034.034 0 00-.007-.042.036.036 0 00-.014-.008 6.612 6.612 0 01-.922-.419.037.037 0 01-.02-.029c0-.006.002-.011.004-.017a.037.037 0 01.012-.014c.062-.044.124-.09.183-.136a.038.038 0 01.038-.005c1.937.841 4.033.841 5.947 0a.038.038 0 01.038.004c.06.047.121.093.184.137a.035.035 0 01.015.03.037.037 0 01-.018.03 6.207 6.207 0 01-.924.419.037.037 0 00-.022.02.034.034 0 00.002.03c.177.325.379.638.604.935a.04.04 0 00.041.013 9.976 9.976 0 002.96-1.421.035.035 0 00.01-.011.034.034 0 00.005-.015c.247-2.43-.413-4.54-1.75-6.411zm-6.078 5.13c-.583 0-1.063-.509-1.063-1.134 0-.626.47-1.136 1.063-1.136.597 0 1.073.514 1.064 1.136 0 .625-.471 1.135-1.064 1.135zm3.932 0c-.583 0-1.064-.509-1.064-1.134 0-.626.471-1.136 1.064-1.136.597 0 1.072.514 1.063 1.136 0 .625-.466 1.135-1.063 1.135z"
        fill="#fff"
      />
    </svg>
  );
}

export default Icon;
