import * as React from "react";

function Icon(props) {
  return (
    <svg
      width={21}
      height={16}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M8.996 15.402s-1.25 0-1.25-1.25 1.25-5 6.25-5 6.25 3.75 6.25 5-1.25 1.25-1.25 1.25h-10zm5-7.5a3.75 3.75 0 100-7.5 3.75 3.75 0 000 7.5z"
        fill="#0C1525"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.766 15.402a2.798 2.798 0 01-.27-1.25c0-1.694.85-3.438 2.42-4.65a7.906 7.906 0 00-2.42-.35c-5 0-6.25 3.75-6.25 5s1.25 1.25 1.25 1.25h5.27z"
        fill="#0C1525"
      />
      <path
        d="M5.871 7.902a3.125 3.125 0 100-6.25 3.125 3.125 0 000 6.25z"
        fill="#0C1525"
      />
    </svg>
  );
}

export default Icon;