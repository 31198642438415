import React from 'react';
import styles from './CustomFields.module.css';

export const CustomFields = () => {
  return (
    <div className={styles.CustomFields}>
        <h3 className={styles.h3}>Custom fields<small> (optional)</small></h3>
        <h3 className={styles.h3}>Name</h3>
        <input type='text' placeholder='e.g: What is your Discord ID?' />
        <h3 className={styles.h3}>Input type</h3>
        <div className={styles.select}>
            <select>
                <option value='number'>Number</option>
                <option value='text'>Text</option>
                <option value='hidden'>Hidden</option>
                <option value='largetextbox'>Large Textbox</option>
            </select>
        </div>
        <button className={styles.button}>Create custom field</button>
    </div>
  )
}
