import React, { useState, useEffect } from 'react';
import RequestService from '../../../api/post';
import styles from './Dashboard.module.css';
import { useDispatch, useSelector } from 'react-redux';
import flag from '../../../images/flag.png'
import { Item } from './Item';
import { uid } from 'react-uid';
import { ButtonLink } from '../../FunctionComponents/ButtonLink/ButtonLink';
import { Heading } from '../Heading/Heading';
import { OrdersList } from '../Orders/Orders';

export const Dashboard = (props) => {
    const state = useSelector(state => state);
    const message = useSelector(state => state.message);
    const dispatch = useDispatch();
    const [invoices, setInvoices] = useState({});
    async function getInvoices(){
        const response = await RequestService.InvoicesGet(JSON.parse(localStorage.getItem('shop')).id);
        setInvoices(response.data.data?response.data.data:{});
    }
    const items = {
        revenue: {
            isMoney: true,
            name: 'Revenue',
            count: 1200,
            array: [1153, 1200]
        },
        orders: {
            name: 'Orders',
            count: 201,
            array: [202, 201]
        },
        orderValue: {
            isMoney: true,
            name: 'Avg. Order Value',
            count: 0.69,
            array: [959, 1200]
        },
        views: {
            wrap: true,
            name: 'Views',
            count: 2600,
            array: [1470, 2600]
        },
        tickets: {
            name: 'Tickets',
            count: 25,
            array: [2562, 2500]
        },
        reviews: {
            name: 'Reviews',
            count: 5,
            array: [0, 1200]
        }
    }
    const types = ['1d', '1w', '1m', '1y', 'All'];
    const [focus, setFocus] = useState(0);
    //const [type, setType] = useState(0);
    const list = Object.keys(items).map((item, i) => {
        return(
            <Item 
                key={uid(i)} 
                name={items[item].name} 
                count={items[item].count} 
                array={items[item].array}
                isMoney={items[item].isMoney}    
                style={styles.item}
                wrap={items[item].wrap}
                className={`${(focus===i?styles.focus:'')} ${styles.option}`}
                wrapStyle={styles.wrap}
            />
        );
    });
    const type_list = types.map((item, i) => {
        return(
            <div 
                key={uid(i)} 
                onClick={()=>{
                    setFocus(i);
                    console.log(i)
                }}
                className={`${(focus===i?styles.focus:'')} ${styles.type_item}`}>
                {item}
            </div>
        );
    });
    async function getDashboard(){
        if(!state.email){
            const response = await RequestService.DashboardGet();
            console.log(response)
            dispatch({
                type: "SET_EMAIL", 
                payload: response.email
            });
        }
    }
    let isShop = false;
    useEffect(()=>{
        getDashboard();
    }, []);
    useEffect(()=>{
        if(localStorage.getItem('shop')){
            getInvoices();
        }else{
            console.log('bruh')
        }
    }, [state]);
    return (
        <div className={styles.Dashboard}>
            <div className={styles.header}>
                <Heading title={props.title}/>
                <div className={styles.func}>
                    <ButtonLink styleB='light' size='large' content='View orders' to='/invoices'/>
                    <ButtonLink styleB='light' size='large' content='Create product' to='/products/new'/>
                </div>
            </div>
            <div className={styles.dashboard}> 
                <div className={styles.content}>
                    {list}
                </div>
                <div className={styles.type}>
                    {type_list}
                </div>
            </div>
            <div className={styles.orders}>
                <h2 className={styles.h2}>Recent Orders</h2>
                {Object.keys(invoices).length>0?<OrdersList items={invoices} maxIndex={3} minIndex={0} />:<h3 className={styles.h3}>No orders</h3>}
            </div>
        </div>
  )
}
