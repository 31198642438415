import React from 'react';
import {uid} from 'react-uid';
import styles from './NavAccounts.module.css';
import Plus from '../../../images/Plus';
import { useSelector } from 'react-redux';

export const NavAccounts = (props) => {

  const state = useSelector(state => state);
  //props.array props.booleans
  let list = (
    <div className={styles.wrapper}>
    </div>
  );
  if(props.isShow && props.items.length){
    const options = [];
    for(let i=0; i<props.items.length; i++){
      if(props.items[i].id!==(localStorage.getItem('shop')&&localStorage.getItem('shop')!=='undefined'?JSON.parse(localStorage.getItem('shop')).id:0))
      options.push(
        <div key={uid(i)} onClick={()=>{props.setShop(i)}} className={styles.store}>
          <div className={styles.content}>
            <div className={styles.avatar}>
              <div className={styles.avatarWrapper}>
                {state.shops[i].design.cfImageId?<img src={`https://imagedelivery.net/${state.shops[i].design.cfImageId}/1600X1200`} alt=""/>:''}
              </div>
            </div>
            <span className={styles.name}>{props.items[i].name}</span>
          </div>
        </div>
      );
    }
    options.push(
      <a href='http://auth.sellpass.mistersandman.ru/setup' key={uid('create')} className={styles.create}>
        <div className={styles.content}>
          <div className={styles.avatar}>
            <Plus viewBox='0 0 8 8' />
          </div>
          <span className={styles.name}>Create Shop</span>
        </div>
      </a>
    );
    list = (
      <div className={styles.NavAccounts}>
          <div className={styles.wrapper}>
            {options}
          </div>
      </div>
    );
  }
  else{
    if(props.isShow){
      list = (
        <div className={styles.NavAccounts}>
          <div className={styles.wrapper}>
            <a href='http://auth.sellpass.io/setup' key={uid('create')} className={styles.create}>
              <div className={styles.content}>
                <div className={styles.avatar}>
                  <Plus viewBox='0 0 8 8' />
                </div>
                <span className={styles.name}>Create Shop</span>
              </div>
            </a>
          </div>
        </div>
      );
    }else{
      list = (
        <div className={styles.NavAccounts}>
        </div>
      );
    }
  }
  return <div>{list}</div>;
}
