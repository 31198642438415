import * as React from "react";

function Icon(props) {
  return (
    <svg
      width={12}
      height={13}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 1.207a.5.5 0 00-.5-.5h-6a.5.5 0 100 1h4.793L.146 11.852a.5.5 0 00.708.708L11 2.414v4.793a.5.5 0 001 0v-6z"
        fill="#0C1525"
      />
    </svg>
  );
}

export default Icon;