import React, { useEffect, useState } from 'react';
import styles from './Reply.module.css';
import flag from '../../../../images/flag.png';
import replyImg from '../../../../images/replyImg.png';
import {uid} from 'react-uid';
import Star from '../../../../images/Star';
import { Cancelled, Completed, Pending, New, Expired } from '../../../StyledComponents/Statuses/Statuses';
import { useParams } from 'react-router-dom';
import { Button } from '../../../FunctionComponents/Button/Button';
import RequestService from '../../../../api/post';
import { useDispatch, useSelector } from 'react-redux';
import getSymbolFromCurrency from 'currency-symbol-map';
import moment from 'moment';

export const Reply = () => {
    const message = useSelector(state => state.message);
    const dispatch = useDispatch();
    const params = useParams();
    const [reply, setReply] = useState('');
    const [ review, setReview] = useState({});
    const statuses = [<New />, <Pending />, <Completed />, <Cancelled />, <Expired />];
    // const items = [
    //     {
    //         id: 0,
    //         flag: <img src={flag} alt="flag" />,
    //         type: 'BTC',
    //         email: 'chairmantest@gmail.com',
    //         credit: '45826b-0b6e79c8a0-f674ba',
    //         product: '5 Boosts',
    //         quantity: '3',
    //         ip: '123.123.123.123',
    //         fraudScore: '100/100',
    //         isp: 'Comcast Cable',
    //         coupon: 'No Coupon',
    //         country: 'City, State (America/Chicago)',
    //         date: '06, Sep 2021 11:00',
    //         gateway: 'Coinbase Commerce',
    //         device: 'Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/101.0.4951.54 Safari/537.36 Edg/101.0.1210.39',
    //         value: '$5.50',
    //         converted_value: 'BTC - 0.000180432200',
    //         status: <Completed />,
    //         time: '11:00 06 Jan',
    //     }
    // ]
    async function getReview(){
        const response = await RequestService.ReviewGet(JSON.parse(localStorage.getItem('shop')).id, params.id);
        console.log(response)
        try{
            if(!response.data.data){
                dispatch({
                    type: "SET_MESSAGE", 
                    payload: {isShow: !message.isShow}
                });
            }else{
                setReview(response.data.data?response.data.data:{});
                setReply(response.data.data.sellerReply?response.data.data.sellerReply:'');
            }
        }
        catch{
            console.log('error')
        }
    }
    async function onSubmit(){
        const data = {
            answer: reply
        }
        const response = await RequestService.ReplyPost(JSON.parse(localStorage.getItem('shop')).id, params.id, data);
        try{
            if(!response.data.data){
                dispatch({
                    type: "SET_MESSAGE", 
                    payload: {isShow: !message.isShow}
                });
            }else{
                //setReply(response.data.data?response.data.data:{});
            }
        }
        catch{
            console.log('error')
        }
    }
    let stars = [];
    for(let i=0;i<5;i++){
        if(i<review.rating){
            stars.push(
                <div key={uid(i)} className={styles.star}>
                    <Star viewBox='0 0 17 16' />
                </div>
            );
        }
        else{
            stars.push(
                <div key={uid(i)} className={styles.star_disabled}>
                    <Star viewBox='0 0 17 16' />
                </div>
            );
        }
    }
    let status = '';
    switch(review.invoice?review.invoice.status:0){
        case(0):
            status = statuses[0];
            break;
        case(1):
            status = statuses[1];
            break;
        case(3):
            status = statuses[2];
            break;
        case(10):
            status = statuses[3];
            break;
        case(11):
            status = statuses[4];
            break;
        default:
            status = '';
    }
    const gateways = ['Coinbase Commerce', 'Stripe', 'PayPal', 'CashApp'];
    useEffect(()=>{
        getReview();
    }, []);
    return (
        <div>
            <div className={styles.header}>
                <h2 className={styles.h2}>Reply to review</h2>
            </div>
            <div className={styles.wrapper}>
                <span className={styles.title}>Order details</span>
                <div className={styles.content}>
                    <div className={styles.infoItem}>
                        <h3 className={styles.h3}>Invoice ID</h3>
                        <span>{review.invoice?review.invoice.id:''}</span>
                    </div>
                    <div className={styles.infoItem}>
                        <h3 className={styles.h3}>Customer</h3>
                        <span>{review.invoice?review.invoice.customerInfo.customerForShop.customer.email:'customer email not found'}</span>
                    </div>
                    <div className={styles.infoItem}>
                        <h3 className={styles.h3}>Product</h3>
                        <span>{review.invoice?(review.invoice.partInvoices.length>1?
                        'Cart':(review.invoice.partInvoices[0].product?
                            review.invoice.partInvoices[0].product.title:'Product Name')):'Product Name'}</span>
                    </div>
                    <div className={styles.infoItem}>
                        <h3 className={styles.h3}>Value</h3>
                        {review.invoice?
                        <span>{getSymbolFromCurrency(review.invoice.currency)}{review.invoice.endPrice}</span>:
                        'value not found'}
                    </div>
                    <div className={styles.infoItem}>
                        <h3 className={styles.h3}>Quantity</h3>
                        <span>{review.invoice?review.invoice.quantity:''}</span>
                        <span>{review.invoice?(review.invoice.partInvoices[0].quantity?
                            review.invoice.partInvoices[0].quantity:'0'):'0'}</span>
                    </div>
                    <div className={styles.infoItem}>
                        <h3 className={styles.h3}>Gateway</h3>
                        <span>{review.invoice?gateways[review.invoice.gateway.gatewayName]:'No Gateway' }</span>
                    </div>
                    <div className={styles.infoItem}>
                        <h3 className={styles.h3}>Country</h3>
                        <div className={styles.infoItem__country}>
                            <img src={flag} alt="flag" />
                            <span>Country</span>
                        </div>
                    </div>
                    <div className={styles.infoItem}>
                        <h3 className={styles.h3}>Created At</h3>
                        <span>{moment(reply.createdAt).format("DD, MMM YYYY HH:mm")}</span>
                    </div>
                </div>
                <div className={styles.reply}>
                    <div className={styles.info_wrapper}>
                        <div className={styles.info}>
                            <img src={replyImg} alt="reply_img" />
                            <div className={styles.text}>
                                {status}
                                <span>“{review.comment}”</span>
                            </div>
                        </div>
                        <div className={styles.rating}>
                            <span>Rating received:</span>
                            <div className={styles.stars}>
                                {stars}
                            </div>
                        </div>
                    </div>
                    <div className={styles.textarea_wrapper}>
                        <textarea placeholder='Type a reply to this review...' value={reply} onChange={(e)=>{setReply(e.target.value)}} />
                    </div>
                    <div className={styles.submit}>
                        <Button size='large' styleB='orange' func={()=>{onSubmit()}} content='Submit reply' />
                    </div>
                </div>
            </div>
        </div>
    )
}
