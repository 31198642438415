import React from 'react';
import styles from './Notifications.module.css';
import { Button } from '../../../FunctionComponents/Button/Button';
import {uid} from 'react-uid';
import { PRO } from '../../../StyledComponents/Types/Types';
import { Switch } from 'antd';
import Discord from '../../../../images/Discord';

export const Notifications = () => {
    const switchData = [
        'When a new order is created', 'When an order is completed','When a new ticket is opened by a customer',
        'When a customer replies to a ticket', 'When a product is out of stock', 'When a customer leaves a new review',
        'When CashApp required attention', 'Updates and ways to grow your shop', 'Subscription expiration notices'
    ];
    const switches = switchData.map((item)=>{
        return (
            <div key={uid(item)} className={styles.switch}>
                <Switch />
                <span className={styles.span}>{item}</span>
            </div>
        )
    });
    return (
        <div className={styles.Notifications}>
            <div className={`${styles.block} ${styles.name}`}>
                <h3>Notifications</h3>
                <span className={styles.span}>Receive an email or discord notification from Sellpass from the following events on your business</span>
                <div className={styles.switchList}>
                    {switches}
                </div>
                <h3>Discord Webhook URL</h3>
                <span className={styles.span}>Enter your channel webhook URL below to receive Sellpass notifications right in your Discord server</span>
                <div className={styles.test_input}>
                    <input placeholder='Webhook URL' type='text'/>
                </div>
                <div className={styles.bottom}>
                    <Button styleB='orange' content='Save' />
                </div>
            </div>
            <div className={`${styles.block} ${styles.name}`}>
                <div className={styles.horizon}>
                    <div className={styles.horizon_main}>
                        <PRO />
                        <h3>Hide customer info from Discord notifications</h3>
                        <span className={styles.span}>Enable this if you don't want your customers’ emails, ip, and order info to appear in notifications</span>
                    </div>
                    <Switch />
                </div>
                <div className={styles.bottom}>
                    <Button styleB='orange' content='Save' />
                </div>
            </div>
        </div>
    )
}
