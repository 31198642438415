import React from 'react';
import { Link } from 'react-router-dom';

import styles from './ButtonLink.module.css';

export const ButtonLink = (props) => {
  return (
    <Link className={`${styles.ButtonLink}
        ${props.styleB?(props.styleB==='orange'?styles.orange:styles.light):styles.light}
        ${props.size?(props.size==='large'?styles.large:styles.small):styles.small}
        `}
        to={props.to}
    >
    <span>{props.content}</span>
    </Link>
  )
}
