import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import {uid} from 'react-uid';
import styles from './Links.module.css';
import Home from '../../../../images/Home';
import BullphoneSolid from '../../../../images/BullphoneSolid';
import ArrowDownLong from '../../../../images/ArrowDownLong';
import Group from '../../../../images/Group';
import Star from '../../../../images/Star';
import Message from '../../../../images/Message';
import Shild from '../../../../images/Shild';
import Gears from '../../../../images/Gears';
import { openMenu } from '../../Header/Header';

export const links = {
  dashboard: {
    link: "/",
    img: <Home viewBox="0 0 18 18" />,
    name: 'Dashboard'
  },
  products: {
    link: "/products/all-products",
    img: <BullphoneSolid viewBox="0 0 18 18" />,
    name: 'Products'
  },
  groups: {
    link: "/products/groups",
    secondary: true,
    name: 'Groups'
  },
  categories: {
    link: "/products/categories",
    secondary: true,
    name: 'Categories'
  },
  coupons: {
    link: "/products/coupons",
    secondary: true,
    name: 'Coupons'
  },  
  orders: {
    link: "/invoices",
    img: <Group viewBox="0 0 18 18" />,
    name: 'Orders'
  },
  customers: {
    link: "/customers",
    secondaryOrders: true,
    name: 'Customers'
  },  
  reviews: {
    link: "/reviews",
    img: <Star viewBox="0 0 18 18" />,
    name: 'Reviews'
  },
  tickets: {
    link: "/tickets",
    img: <Message viewBox="0 0 18 18" />,
    name: 'Tickets'
  },
  blacklists: {
    link: "/blacklists",
    img: <Shild viewBox="0 0 18 18" />,
    name: 'Blacklists'
  },
  settings: {
    link: "/settings/general",
    img: <Gears viewBox="0 0 18 18" />,
    name: 'Settings'
  }
};
export const Links = () => {
  const dispatch = useDispatch();
  const secondaryItems =  document.getElementsByClassName(styles.secondary);
  const secondaryItemsOrders =  document.getElementsByClassName(styles.secondaryOrders);
  const [focus, setFocus] = useState(0);
  const list = Object.keys(links).map((item, i) => {
    return(
        <Link
            className={`${links[item].secondary?styles.secondary:''} ${links[item].secondaryOrders?styles.secondaryOrders:''} ${(focus===i?styles.focus:'')} ${styles.option}`}
            key={uid(i)}
            onClick={()=>{
              if(i<5 && i>0){
                for(let j=0;j<secondaryItems.length;j++){
                  secondaryItems[j].classList.add(styles.show);
                }
              }
              if(i<7 && i>4){
                for(let j=0;j<secondaryItemsOrders.length;j++){
                  secondaryItemsOrders[j].classList.add(styles.show);
                }
              }
              else{
                for(let j=0;j<secondaryItems.length;j++){
                  secondaryItems[j].classList.remove(styles.show);
                }
              }
                setFocus(i);
                dispatch({
                    type: "SET_LOCATION", 
                    payload: {
                        title: links[item].name,
                        path: links[item].link
                    }
                });
            }} 
            to={links[item].link}>
            <div className={styles.option}>
              <div className={styles.img}>{links[item].img}</div>
              <span className={styles.span}>{links[item].name}</span>
            </div>
            <div className={styles.arrow}>
              {(i===1||i===5)?<ArrowDownLong viewBox='0 0 11 5' />:''}
            </div>
        </Link>);
  });
  const location = useLocation();
  function setPath(){
      Object.keys(links).forEach((item, i) => {
        if(links[item].secondary||location.pathname.match('/products/groups/edit')||location.pathname.match('/products/coupons/edit')){
          if(location.pathname.match(/.*(\/.*)(\/.*)/)){
            if(location.pathname.match(/.*(\/.*)(\/.*)/)[1]===links[item].link.match(/.*(\/.*)/)[1]){
              if(i<5 && i>0){
                for(let j=0;j<secondaryItems.length;j++){
                  secondaryItems[j].classList.add(styles.show);
                }
              }
              if(i===6 || i===5){
                for(let j=0;j<secondaryItemsOrders.length;j++){
                  secondaryItemsOrders[j].classList.add(styles.show);
                }
              }
              setFocus(i);
              dispatch({
                  type: "SET_LOCATION", 
                  payload: {
                      title: links[item].name,
                      path: links[item].link
                  }
              });
            }
          }
          if(location.pathname.match(/.*(\/.*)/)[1]===links[item].link.match(/.*(\/.*)/)[1]){
              if(i<5 && i>0){
                for(let j=0;j<secondaryItems.length;j++){
                  secondaryItems[j].classList.add(styles.show);
                }
              }
              if(i===6 || i===5){
                for(let j=0;j<secondaryItemsOrders.length;j++){
                  secondaryItemsOrders[j].classList.add(styles.show);
                }
              }
              setFocus(i);
              dispatch({
                  type: "SET_LOCATION", 
                  payload: {
                      title: links[item].name,
                      path: links[item].link
                  }
              });
          }
        }
        else{
          if(location.pathname.match(/^\/(''|\w*)/)[0]===links[item].link.match(/^\/(''|\w*)/)[0]){
            dispatch({
                type: "SET_LOCATION", 
                payload: {
                    title: links[item].name,
                    path: links[item].link
                }
            });
            if(i<5 && i>0){
              for(let j=0;j<secondaryItems.length;j++){
                secondaryItems[j].classList.add(styles.show);
              }
              for(let j=0;j<secondaryItemsOrders.length;j++){
                secondaryItemsOrders[j].classList.remove(styles.show);
              }
            }
            else if(i===6 || i===5){
              for(let j=0;j<secondaryItemsOrders.length;j++){
                secondaryItemsOrders[j].classList.add(styles.show);
              }
              for(let j=0;j<secondaryItems.length;j++){
                secondaryItems[j].classList.remove(styles.show);
              }
            }
            else{
              for(let j=0;j<secondaryItems.length;j++){
                secondaryItems[j].classList.remove(styles.show);
              }
              for(let j=0;j<secondaryItemsOrders.length;j++){
                secondaryItemsOrders[j].classList.remove(styles.show);
              }
            }
            setFocus(i);
          }
        }
      });
  }
  
  useEffect(() => {
      setPath();
      // eslint-disable-next-line
  }, [location.pathname]);
  return (
    <div className={styles.Links}>
        {list}
    </div>
  )
}
