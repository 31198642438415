import React, { useState } from 'react';
import styles from './Products.module.css';
import { Heading } from '../Heading/Heading';
import Close from '../../../images/Close';
import { SearchInput } from '../../FunctionComponents/Search/Search';
import { ButtonLink } from '../../FunctionComponents/ButtonLink/ButtonLink';
import { Button } from '../../FunctionComponents/Button/Button';
import { Modal } from '../../FunctionComponents/Modal/Modal';
import { Route, useRouteMatch, Switch } from 'react-router-dom';
import { ProductsList } from './ProductsList/ProductsList';
import { ProductEdit } from './ProductEdit/ProductEdit';
import { Categories } from './Paragraphs/Categories/Categories';
import { Groups } from './Paragraphs/Groups/Groups';
import { Coupons } from './Paragraphs/Coupons/Coupons';
import { Redirect } from 'react-router-dom';
import RequestService from '../../../api/post';
import { useDispatch, useSelector } from 'react-redux';

export const ModalMessage = (props) =>{
    const message = useSelector(state => state.message);
    const dispatch = useDispatch();
    async function deleteProduct(productId){
        const response = await RequestService.ProductDelete(JSON.parse(localStorage.getItem('shop')).id, productId);
        if(response){
            dispatch({
                type: "SET_MESSAGE", 
                payload: {isShow: true, status: true, text: 'Product deleted successfully'}
            });
        }
        else{
            dispatch({
                type: "SET_MESSAGE", 
                payload: {isShow: !message.isShow}
            });
        }
    }
    return (
        <div className={styles.ModalMessage}>
            <div className={styles.ModalMessage_content}>
                <h3>Delete product</h3>
                <span>You are about to delete 5 boosts. Are you sure you want to delete this product?</span>
                <div className={styles.buttons}>
                    <Button func={props.close} content='Cancel' size='small' styleB='light' />
                    <Button func={()=>{deleteProduct(props.modalShow.item);props.close()}} content='Delete product' size='small' styleB='orange' />
                </div>
            </div>
            <Close onClick={props.close} viewBox='0 0 10 15' />
        </div>
    )
}
export const Products = (props) => {
    const[modalShow, setModalShow] = useState({
        isShow: false,
        item: 0
    });
    const {url, path} = useRouteMatch();
    return (
        <div className={styles.Products}>
            <Switch>
                <Route exact path={`${path}/all-products`}>
                    {modalShow.isShow?<Modal onClick={()=>{setModalShow({...modalShow, isShow: false})}} component={
                    <ModalMessage modalShow={modalShow} close={()=>{setModalShow({...modalShow, isShow: false})}} />}/>:''}
                    <div className={styles.header}>
                        <Heading title={props.title} link='/products/all-products'/>
                        <div className={styles.func}>
                            <div className={styles.search}>
                                <SearchInput placeholder='Search for a product...' func={(e)=>{console.log(e)}} />
                            </div>
                            <ButtonLink styleB='orange' size='large' to={`${url}/new`} content='+ Create product' />
                        </div>
                    </div>
                    <ProductsList delete={(i)=>{setModalShow({isShow: true, item: i})}} />
                </Route>
                <Route path={`${path}/new`}>
                    <ProductEdit title={props.title}/>
                </Route>
                <Route path={`${path}/edit/:id`}>
                    <ProductEdit title={props.title} edit={true}/>
                </Route>
                <Route path={`${path}/categories`}>
                    <Categories title={props.title}/>
                </Route>
                <Route path={`${path}/groups`}>
                    <Groups title={props.title}/>
                </Route>
                <Route path={`${path}/coupons`}>
                    <Coupons title={props.title}/>
                </Route>
                <Route path='*'>
                    <Redirect to="/products/all-products" />
                </Route>
            </Switch>
        </div>
  )
}
