import React, { useEffect, useState } from 'react';
import styles from './Reviews.module.css';
import listStyles from './ReviewsList.module.css';
import { Heading } from '../Heading/Heading';
import {uid} from 'react-uid';
import { SearchInput } from '../../FunctionComponents/Search/Search';
import { Route, useRouteMatch, Switch } from 'react-router-dom';
import { NoMatch } from '../../../App';
import { ButtonLink } from '../../FunctionComponents/ButtonLink/ButtonLink';
import Star from '../../../images/Star';
import Close from '../../../images/Close';
import { Button } from '../../FunctionComponents/Button/Button';
import { Modal } from '../../FunctionComponents/Modal/Modal';
import { Reply } from './Reply/Reply';
import { Pagination } from '../../FunctionComponents/Pagination/Pagination';
import RequestService from '../../../api/post';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';


export const ModalMessage = (props) =>{
    return (
        <div className={styles.ModalMessage}>
            <div className={styles.ModalMessage_content}>
                <div className={styles.top}>
                    <h3 className={styles.h3_modal}>Appeal review</h3>
                    <Close onClick={props.close} viewBox='0 0 10 15' />
                </div>
                <span>Reason</span>
                <div className={styles.select_container}>
                    <div className={styles.select}>
                        <select>
                            <option value=''>Select reason for appeal</option>
                        </select>
                    </div>
                </div>
                <span>Description</span>
                <div className={styles.textarea_wrapper}>
                    <textarea placeholder='Description' />
                </div>
                <div className={styles.buttons}>
                    <Button func={()=>{props.delete(props.modalShow.item);props.close()}} content='Appeal' size='small' styleB='orange' />
                </div>
            </div>
        </div>
    )
}
export const ReviewsList = (props) =>{
    const reviews = props.reviews.map((item, i) => {
        return(
            <div className={`${listStyles.item} ${i+1<props.reviews.length?listStyles.notFinal:''}`} key={uid(i)}>
                <div className={listStyles.value}>
                    <span>{item.invoiceId}</span>
                </div>
                <div className={listStyles.value}>
                    <span className={listStyles.reply__title}>{item.comment}</span>
                    <span className={listStyles.reply__text}>{item.reply}</span>
                </div>
                <div className={listStyles.rating}>
                    <Star viewBox='0 0 17 17' />
                    <span>{item.rating}</span>
                </div>
                <div className={listStyles.value}>
                        <span>{moment(item.createdAt).format("HH:mm DD MMM")}</span>
                </div>
                <div className={listStyles.action}>
                    <ButtonLink to={`${props.path?props.path:'/reviews'}/${item.id}`} content='Reply' />
                    {/* <Button func={props.show} content='Appeal' /> */}
                    <Button content='Appeal' />
                </div>
            </div>
        );
    });
    return(
        <div className={listStyles.ReviewsList}>
            <div className={listStyles.title}>
                <h4 className={listStyles.info}>INVOICE ID</h4>
                <h4>MESSAGE</h4>
                <h4>RATING</h4>
                <h4>TIME</h4>
                <h4>ACTION</h4>
            </div>
            <div className={listStyles.items}>
                {reviews}
            </div>
        </div>
    )
}
export const Reviews = (props) => {
    const message = useSelector(state => state.message);
    const dispatch = useDispatch();
    const [pageSize, setPageSize] = useState(10);
    const [current, setCurrent] = useState(1);
    const [minIndex, setMinIndex] = useState(0);
    const [maxIndex, setMaxIndex] = useState(pageSize);
    const [reviews, setReviews] = useState([]);
    const[modalShow, setModalShow] = useState({
        isShow: false,
        item: 0
    });
    async function getReviews(){
        const response = await RequestService.ReviewsGet(JSON.parse(localStorage.getItem('shop')).id);
        console.log(response)
        try{
            if(!response.data.data){
                dispatch({
                    type: "SET_MESSAGE", 
                    payload: {isShow: !message.isShow}
                });
            }else{
                setReviews(response.data.data?response.data.data:{});
            }
        }
        catch{
            console.log('error')
        }
    }
    function appeal(i){
        console.log(i)
    }
    const {path} = useRouteMatch();
    useEffect(()=>{
        getReviews()
    }, []);
    return (
        <div className={styles.Reviews}>
            <Switch>
                <Route exact path={path}>
                    {modalShow.isShow?<Modal onClick={()=>{setModalShow({...modalShow, isShow: false})}} component={
                    <ModalMessage delete={()=>{appeal()}} modalShow={modalShow} close={()=>{setModalShow(false)}} />}/>:''}
                    <div className={styles.header}>
                        <Heading title={props.title} link='/reviews'/>
                        <div className={styles.func}>
                            <SearchInput placeholder='Search for a reviews...' func={(e)=>{console.log(e)}} />
                        </div>
                    </div>
                    <div className={styles.content}>
                        <ReviewsList show={(i)=>{setModalShow({isShow: true, item: i})}} reviews={reviews} path={path} />
                        <Pagination
                            setCurrent={setCurrent}
                            maxIndex={maxIndex}
                            minIndex={minIndex}
                            setMaxIndex={setMaxIndex}
                            setMinIndex={setMinIndex}
                            setPageSize={setPageSize}
                            content={reviews}
                            current={current}
                            pageSize={pageSize}
                            />  
                    </div>
                </Route>
                <Route path={`${path}/:id`}>
                    <Reply reviews={reviews} />
                </Route>
                <Route path='*'>
                    <NoMatch />
                </Route>
            </Switch>
        </div>
  )
}
