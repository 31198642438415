import React, { useState, useEffect } from 'react';
import { Action } from '../../../FunctionComponents/Action/Action';
import Copy from '../../../../images/Copy';
import Share from '../../../../images/Share';
import Trash from '../../../../images/Trash';
import Pencil from '../../../../images/Pencil';
import {uid} from 'react-uid';
import listStyles from './ProductsList.module.css';
import RequestService from '../../../../api/post';
import { Serials, Service, File, Dynamic } from '../../../StyledComponents/Types/Types';
import ActionIcon from '../../../../images/Action';
import { Pagination } from '../../../FunctionComponents/Pagination/Pagination';
import getSymbolFromCurrency from 'currency-symbol-map';
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';


export const ProductsList = (props) =>{
    const message = useSelector(state => state.message);
    const dispatch = useDispatch();
    const history = useHistory();
    const [products, setProducts] = useState({});
    const [pageSize, setPageSize] = useState(10);
    const [current, setCurrent] = useState(1);
    const [minIndex, setMinIndex] = useState(0);
    const [maxIndex, setMaxIndex] = useState(pageSize);
    const [delimiter, setDelimiter] = useState('comma');
    async function getProducts(){
        const response = localStorage.getItem('shop')!=='undefined'?await RequestService.ProductsGet(JSON.parse(localStorage.getItem('shop')).id):'';
        try{
            if(!response.data.data){
                dispatch({
                    type: "SET_MESSAGE", 
                    payload: {isShow: !message.isShow}
                });
            }else{
                setProducts(response.data.data?response.data.data.reverse():{});
            }
        }
        catch{
            console.log('error')
        }
    }
    async function dublicateProduct(productId){
        const response = await RequestService.ProductGet(JSON.parse(localStorage.getItem('shop')).id, productId);
        const data = response.data.data;
        let product = {
            title: data.title,
            description: data.description,
            price: data.variants[0].price,
            currency: data.variants[0].currency,
            gatewaysList: {}, //need edit
            type: data.variants[0].productType,
            customerNote: data.variants[0].customerNote,
            onHold: data.onHold,
            private: data.private,
            terms: data.terms,
            unlisted: data.unlisted
        };
        switch(data.variants[0].productType){
            case 0:
                const delimiter = data.variants[0].asSerials.delimiter;
                if(delimiter==='newLine'||delimiter==='comma'){
                    setDelimiter(delimiter);
                }else{
                    setDelimiter(delimiter);
                }
                const serials = data.variants[0].asSerials.serials;
                let serialsString = '';
                serials.forEach((item, i) => {
                    serialsString += item
                    if(i<serials.length-1){
                        serialsString += delimiter
                    }
                });
                product = {...product, asSerials: {
                    delimiter: delimiter,
                    serials: serialsString,
                    maxAmount: data.variants[0].asSerials.maxAmount,
                    minAmount: data.variants[0].asSerials.minAmount
                }};
                break;
            case 2:
                product = {...product, asDynamic: {
                    externalUrl: data.variants[0].asDynamic.externalUrl,
                    maxAmount: data.variants[0].asDynamic.maxAmount,
                    minAmount: data.variants[0].asDynamic.minAmount,
                    stock: data.variants[0].asDynamic.stock
                }};
                break;
            case 3:
                product = {...product, asService: {
                    text: data.variants[0].asService.text,
                    maxAmount: data.variants[0].asService.maxAmount,
                    minAmount: data.variants[0].asService.minAmount,
                    stock: data.variants[0].asService.stock
                }};
                break;
        }
        //console.log(data)
        const duble = await RequestService.ProductNew(JSON.parse(localStorage.getItem('shop')).id, product);
        if(duble){
            dispatch({
                type: "SET_MESSAGE", 
                payload: {isShow: true, status: true, text: 'Product dublicated successfully'}
            });
        }
        else{
            dispatch({
                type: "SET_MESSAGE", 
                payload: {isShow: !message.isShow}
            });
        }
    }
    function openList(target){
        const items = document.getElementsByClassName(listStyles.action);
        for(let i=0;i<items.length;i++){
            if(target.id==='action_list' && target===items[i]){
                target.classList.toggle(listStyles.show)
            }
            else{
                items[i].classList.remove(listStyles.show)
            }
        }
    }
    function getType(i){
        switch(i) {
          case 0:
            return <Serials />
          case 1:
            return <File />
          case 2:
            return <Dynamic />
          case 3:
            return <Service />
          default:
            return ''
        }
    }
    const getItems = products?Object.keys(products).map((item, i) => {
        let items_;
        let stock;
        const actions = [
            {
                text: 'Edit',
                image: <Pencil viewBox="0 0 18 18" />,
                func: ()=>{history.push(`/products/edit/${products[item].id}`)}
            },
            {
                text: 'Duplicate',
                image: <Copy viewBox="0 0 18 18" />,
                func: ()=>{dublicateProduct(products[item].id)}
            },
            {
                text: 'Share',
                image: <Share viewBox="0 0 18 18" />
            },
            {
                text: 'Delete',
                image: <Trash viewBox="0 0 18 18" />,
                func: ()=>{props.delete(products[item].id)}
            }
        ];
        switch(products[item].variants[0].productType){
            case 0:
                stock = products[item].variants[0].asSerials.stock;
                break;
            case 2:
                stock = products[item].variants[0].asDynamic.stock;
                break;
            case 3:
                stock = products[item].variants[0].asService.stock;
                break;
            default:
                stock = 0;
                break;
        }
        if(i >= minIndex && i < maxIndex){
            items_ = (
                <div className={`${listStyles.item} ${i+1<products.length?listStyles.notFinal:''}`} key={uid(i)}>
                    <div className={listStyles.item_value}>
                        <span>{products[item].id}</span>
                    </div>
                    <div className={listStyles.item_value}>
                        <span>{products[item].title}</span>
                    </div>
                    <div className={listStyles.item_value}>
                        <span>{getSymbolFromCurrency(products[item].variants[0].currency)}{products[item].variants[0].price}</span>
                    </div>
                    <div className={listStyles.item_value}>
                        <span>{getType(products[item].variants[0].productType)}</span>
                    </div>
                    <div className={listStyles.item_value}>
                        <span>{stock<0?'∞':stock}</span>
                    </div>
                    <div className={listStyles.item_value__action}>
                        <div id='action_list' onClick={(e)=>{openList(e.currentTarget)}} className={listStyles.action}>
                            <div className={listStyles.action_icon}><ActionIcon /></div>
                            <Action index={i} className={listStyles.action_list} items={actions} />
                        </div>
                    </div>
                </div>
            )
        }
        return items_
    }):[];
    const [items, setItems] = useState(getItems);
    useEffect(()=>{
        document.addEventListener('click', e => {
        if (!(e.target).closest('#action_list')) {
                openList(e.target);
            }
            e.stopPropagation();
        });
        getProducts();
    }, [message]);

    useEffect(()=>{
        setItems(getItems)
    }, [products]);

    useEffect(()=>{
        setItems(getItems)
    }, [pageSize, current]);
    return(
        <div className={listStyles.ProductsList}>
            <div className={listStyles.wrapper}>
                <div className={listStyles.title}>
                    <h4>ID</h4>
                    <h4>PRODUCT NAME</h4>
                    <h4>PRICE</h4>
                    <h4>TYPE</h4>
                    <h4>STOCK</h4>
                    <h4 className={listStyles.action}>ACTION</h4>
                </div>
                <div className={listStyles.items}>
                    {items}
                </div>
            </div>
            <Pagination 
                setCurrent={setCurrent}
                maxIndex={maxIndex}
                minIndex={minIndex}
                setMaxIndex={setMaxIndex}
                setMinIndex={setMinIndex}
                setPageSize={setPageSize}
                content={products?products:''}
                current={current}
                pageSize={pageSize}
                />
        </div>
    )
}