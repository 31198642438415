import React from 'react';
import styles from './Types.module.css'

export const Serials = () => {
  return (
    <div className={`${styles.Serials} ${styles.status}`}>Serials</div>
  )
}
export const Service = () => {
    return (
        <div className={`${styles.Service} ${styles.status}`}>Service</div>
    )
}
export const Dynamic = () => {
  return (
  <div className={`${styles.IP} ${styles.status}`}>Dynamic</div>
  )
}
export const File = () => {
    return (
    <div className={`${styles.File} ${styles.status}`}>File</div>
    )
}
export const Email = () => {
  return (
    <div className={`${styles.Email} ${styles.status}`}>Email</div>
  )
}
export const Country = () => {
    return (
        <div className={`${styles.Country} ${styles.status}`}>Country</div>
    )
}
export const IP = () => {
    return (
    <div className={`${styles.IP} ${styles.status}`}>IP Address</div>
    )
}
export const Percentage = () => {
    return (
        <div className={`${styles.Percentage} ${styles.status}`}>Percentage</div>
    )
}
export const Amount = () => {
    return (
    <div className={`${styles.Amount} ${styles.status}`}>Amount</div>
    )
}
export const PRO = () => {
    return (
        <div className={`${styles.PRO} ${styles.status}`}>PRO</div>
    )
}
export const Business = () => {
    return (
    <div className={`${styles.Business} ${styles.status}`}>BUSINESS</div>
    )
}

