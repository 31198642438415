import * as React from "react";

function Icon(props) {
  return (
    <svg
      width={16}
      height={17}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M15.638 8.588c0-.517-.042-1.037-.131-1.545H8.333V9.97h4.108a3.52 3.52 0 01-1.52 2.312v1.9h2.45c1.44-1.325 2.267-3.281 2.267-5.595z"
        fill="#4285F4"
      />
      <path
        d="M8.332 16.02c2.051 0 3.781-.675 5.042-1.837l-2.451-1.9c-.682.463-1.562.726-2.588.726-1.984 0-3.667-1.338-4.27-3.138h-2.53v1.959a7.607 7.607 0 006.797 4.19z"
        fill="#0F9D58"
      />
      <path
        d="M4.062 9.87a4.556 4.556 0 010-2.911v-1.96H1.536a7.612 7.612 0 000 6.83l2.526-1.958z"
        fill="#F4B400"
      />
      <path
        d="M8.332 3.818a4.133 4.133 0 012.918 1.14l2.171-2.172A7.31 7.31 0 008.332.808 7.604 7.604 0 001.536 5l2.526 1.959c.601-1.803 2.286-3.141 4.27-3.141z"
        fill="#DB4437"
      />
    </svg>
  );
}

export default Icon;
