import React from 'react';
import {uid} from 'react-uid';
import styles from './Select.module.css';

export const Select = (props) => {
  //props.array props.booleans
  let list = (
    <div className={styles.wrapper}>
    </div>
  );
  if(props.isShow){
    const options = [];
    for(let i=0; i<props.items.length; i++){
      options.push(
        <div key={uid(i)} onClick={()=>{props.items[i].func?props.items[i].func(props.index):console.log('nope')}} className={styles.option}>
            {props.items[i].image}
            <span className={styles.span}>{props.items[i].text}</span>
          </div>
      );
    }
    list = (
      <div className={styles.Select}>
          <div className={styles.wrapper}>
            {options}
          </div>
      </div>
    );
  }
  else{
    list = (
      <div className={styles.Select}>
      </div>
    );
  }
  return <div>{list}</div>;
}
