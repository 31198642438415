import React from 'react';
import styles from './Modal.module.css';

export const Modal = (props) => {
  return (
    <div className={styles.Modal}>
      <div onClick={props.onClick} className={styles.shadow}></div>
      <div className={styles.content}>
        {props.component}
      </div>
    </div>
  )
}
