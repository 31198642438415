import React, { useEffect, useState } from 'react';
import styles from './Groups.module.css';
import { Heading } from '../../../Heading/Heading';
import Close from '../../../../../images/Close';
import { SearchInput } from '../../../../FunctionComponents/Search/Search';
import { ButtonLink } from '../../../../FunctionComponents/ButtonLink/ButtonLink';
import { Button } from '../../../../FunctionComponents/Button/Button';
import { Modal } from '../../../../FunctionComponents/Modal/Modal';
import { Route, useRouteMatch, Switch } from 'react-router-dom';
import { NoMatch } from '../../../../../App';
import { GroupsList } from './GroupsList/GroupsList';
import { GroupsEdit } from './GroupsEdit/GroupsEdit';
import { Pagination } from '../../../../FunctionComponents/Pagination/Pagination';
import RequestService from '../../../../../api/post';
import { useDispatch, useSelector } from 'react-redux';

export const ModalMessage = (props) =>{
    const message = useSelector(state => state.message);
    const dispatch = useDispatch();
    async function deleteGroup(){
        const response = await RequestService.GroupDelete(JSON.parse(localStorage.getItem('shop')).id, props.items.id);
        if(response){
            dispatch({
                type: "SET_MESSAGE", 
                payload: {isShow: true, status: true, text: 'Group deleted successfully'}
            });
        }
        else{
            dispatch({
                type: "SET_MESSAGE", 
                payload: {isShow: !message.isShow}
            });
        }
        props.close();
    }
    return (
        <div className={styles.ModalMessage}>
            <div className={styles.ModalMessage_content}>
                <h3>Delete product group</h3>
                <span>You are about to delete <b>{props.items.title}</b>. Are you sure you want to delete this group?</span>
                <div className={styles.buttons}>
                    <Button func={props.close} content='Cancel' size='small' styleB='light' />
                    <Button func={()=>{deleteGroup()}} content='Delete group' size='small' styleB='orange' />
                </div>
            </div>
            <Close onClick={props.close} viewBox='0 0 10 15' />
        </div>
    )
}
export const Groups = (props) => {
    const message = useSelector(state => state.message);
    const [pageSize, setPageSize] = useState(10);
    const [current, setCurrent] = useState(1);
    const [minIndex, setMinIndex] = useState(0);
    const [maxIndex, setMaxIndex] = useState(pageSize);
    const[modalShow, setModalShow] = useState({
        isShow: false,
        item: 0
    });
    const [items, setItems] = useState([]);
    async function getGroups(){
        const response = await RequestService.GroupsGet(JSON.parse(localStorage.getItem('shop')).id);
        setItems(response.data.data);
    }
    const {url, path} = useRouteMatch();
    useEffect(()=>{
        getGroups();
    }, [message]);
    return (
        <div className={styles.Groups}>
            <Switch>
                <Route exact path={`${path}`}>
                    {modalShow.isShow?<Modal onClick={()=>{setModalShow({...modalShow, isShow: false})}} component={
                    <ModalMessage items={items[modalShow.item]} modalShow={modalShow} close={()=>{setModalShow({...modalShow, isShow: false})}} />}/>:''}
                    <div className={styles.header}>
                        <Heading title={props.title} link='/products/groups'/>
                        <div className={styles.func}>
                            <div className={styles.search}>
                                <SearchInput placeholder='Search for a group...' func={(e)=>{console.log(e)}} />
                            </div>
                            <ButtonLink styleB='orange' size='large' to={`${url}/new`} content='+ Create product group' />
                        </div>
                    </div>
                    <GroupsList delete={(i)=>{setModalShow({isShow: true, item: i})}} items={items} />
                    <Pagination 
                        setCurrent={setCurrent}
                        maxIndex={maxIndex}
                        minIndex={minIndex}
                        setMaxIndex={setMaxIndex}
                        setMinIndex={setMinIndex}
                        setPageSize={setPageSize}
                        content={items}
                        current={current}
                        pageSize={pageSize}
                        />
                </Route>
                <Route path={`${path}/edit/:id`}>
                    <GroupsEdit title={props.title}/>
                </Route>
                <Route path={`${path}/new`}>
                    <GroupsEdit title={props.title}/>
                </Route>
                <Route path='*'>
                    <NoMatch />
                </Route>
            </Switch>
        </div>
  )
}
