import * as React from "react";

function Icon(props) {
  return (
    <svg
      width={11}
      height={11}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M5.527 9.93a4.197 4.197 0 110-8.395 4.197 4.197 0 010 8.395zm0 .6a4.797 4.797 0 100-9.595 4.797 4.797 0 000 9.594z"
        fill="#0C1525"
      />
      <path
        d="M6.085 4.886l-1.373.172-.05.228.27.05c.177.041.212.105.173.28l-.442 2.08c-.117.538.063.791.484.791.327 0 .707-.151.879-.359l.052-.249a.664.664 0 01-.41.147c-.166 0-.226-.115-.183-.32l.6-2.82zm.042-1.252a.6.6 0 11-1.2 0 .6.6 0 011.2 0z"
        fill="#0C1525"
      />
    </svg>
  );
}

export default Icon;