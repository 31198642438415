import * as React from "react";

function Icon(props) {
  return (
    <svg
      width={11}
      height={5}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.025 1.028a.423.423 0 01.2-.25.252.252 0 01.265.027L5.5 3.692 9.506.804a.272.272 0 01.131-.053.249.249 0 01.135.023c.044.02.084.054.119.097a.518.518 0 01.083.154.679.679 0 01.019.384.583.583 0 01-.068.17.398.398 0 01-.108.119l-4.163 3a.265.265 0 01-.155.053.265.265 0 01-.156-.053l-4.163-3a.489.489 0 01-.173-.289.704.704 0 01.018-.381z"
        fill="#0C1525"
      />
    </svg>
  );
}

export default Icon;