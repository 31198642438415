import React, { useEffect, useState } from 'react';
import styles from './Team.module.css';
import Trash from '../../../../images/Trash';
import Pencil from '../../../../images/Pencil';
import { Button } from '../../../FunctionComponents/Button/Button';
import { Action } from '../../../FunctionComponents/Action/Action';
import { Modal } from '../../../FunctionComponents/Modal/Modal';
import {uid} from 'react-uid';
import { Custom } from '../../../StyledComponents/Statuses/Statuses';
import { PRO } from '../../../StyledComponents/Types/Types';
import ActionIcon from '../../../../images/Action';
import { Checkbox } from 'antd';

export const ModalMessage = (props) =>{
    const itemsData = [
        {
            name: 'Administrator',
            description: 'User can access your entire store'
        },
        {
            name: 'Products',
            description: 'User can create and edit products'
        },
        {
            name: 'Blacklist',
            description: 'User can create and edit blacklists'
        },
        {
            name: 'Coupons',
            description: 'User can create and edit coupons'
        },
        {
            name: 'Invoices',
            description: 'User can view store invoices'
        },
        {
            name: 'Tickets',
            description: 'User can view and respond to tickets'
        },
        {
            name: 'Reviews',
            description: 'User can response and appeal reviews'
        },
        {
            name: 'Settings',
            description: 'User can access your store settings'
        }
    ];
    const items = itemsData.map((item) => {
        return(
            <div key={uid(item)} className={styles.checkBoxItem}>
                <div className={styles.checkBoxItem_main}>
                    <Checkbox />
                    <h3>{item.name}</h3>
                </div>
                <span className={styles.span}>{item.description}</span>
            </div>
        );
    });
    return (
        <div className={styles.ModalMessage}>
            <div className={styles.ModalMessage_content}>
                <h3>Invite team member</h3>
                <span className={styles.span}>Enter the email of the person you'd like to invite and the role you'd like to give them</span>
                <div className={styles.input}>
                    <input type='text' placeholder='example@gmail.com'/>
                </div>
                <div className={styles.checkBoxes}>
                    {items}
                </div>
                <div className={styles.name}>
                    <div className={`${styles.bottom} ${styles.buttons}`}>
                        <Button func={props.close} content='Cancel' size='small' styleB='light' />
                        <Button func={()=>{props.close()}} content='Send invite' size='small' styleB='orange' />
                    </div>
                </div>
            </div>
        </div>
    )
}
export const Team = () => {
    const[modalShow, setModalShow] = useState({
        isShow: false,
        item: 0
    });
    const itemsData =[
        {   
            name: JSON.parse(localStorage.getItem('shop'))?JSON.parse(localStorage.getItem('shop')).name:'shopName',
            email: JSON.parse(localStorage.getItem('data'))?JSON.parse(localStorage.getItem('data')).email:'demostore@gmail.com',
            role: 'Owner',
            status: <Custom text='6 minutes ago' />,
            action: '11:00 06 Jan',
        }
    ];
    const actions = [
        {
            text: 'Edit',
            image: <Pencil viewBox="0 0 18 18" />
        },
        {
            text: 'Delete',
            image: <Trash viewBox="0 0 18 18" />
        }
    ];
    function openList(target){
        const items = document.getElementsByClassName(styles.action);
        for(let i=0;i<items.length;i++){
            if(target.id==='action_list' && target===items[i]){
                target.classList.toggle(styles.show)
            }
            else{
                items[i].classList.remove(styles.show)
            }
        }
    };
    useEffect(()=>{
      document.addEventListener('click', e => {
        if (!(e.target).closest('#action_list')) {
            openList(e.target);
        }
        e.stopPropagation();
      });
    });
    const items = itemsData.map((item, i) => {
        return(
            <div className={`${styles.item} ${i+1<itemsData.length?styles.notFinal:''}`} key={uid(i)}>
                <div className={styles.item_info}>
                    <div className={styles.avatar} style={{background: JSON.parse(localStorage.getItem('shop'))?JSON.parse(localStorage.getItem('shop')).design.primaryColor:'#FFFFFF'}}></div>
                    <div className={styles.item_info_text}>
                        <h4>{item.name}</h4>
                        <span>{item.email}</span>
                    </div>
                </div>
                <div className={styles.item_value}>
                    <span>{item.role}</span>
                </div>
                <div className={styles.item_value}>
                    {item.status}
                </div>
                <div className={styles.item_value__action}>
                    <div id='action_list' onClick={(e)=>{openList(e.currentTarget)}} className={styles.action}>
                        <div className={styles.action_icon}><ActionIcon /></div>
                        <Action index={i} className={styles.action_list} items={actions} />
                    </div>
                </div>
            </div>
        );
    });
    return (
        <div className={styles.Team}>
            {modalShow.isShow?<Modal component={
            <ModalMessage items={items[modalShow.item]} modalShow={modalShow} close={()=>{setModalShow({...modalShow, isShow: false})}} />}/>:''}
            <div className={`${styles.block} ${styles.name}`}>
                <div className={styles.blockTitle}>
                    <div className={styles.blockTitle_text}>
                        <div className={styles.blockTitle_textTop}>
                            <h3>Team</h3>
                            <PRO />
                        </div>
                        <span className={styles.span}>Manage your businesses team members and their permissions</span>
                    </div>
                    <Button styleB='orange' func={()=>{setModalShow({...modalShow, isShow: true})}} content='Invite member' />
                </div>
                <div className={styles.purchaseList}>
                    <div className={styles.title}>
                        <h4 className={styles.info}>USER</h4>
                        <h4>ROLE</h4>
                        <h4>LAST LOGIN</h4>
                        <h4 className={styles.action}>ACTION</h4>
                    </div>
                    <div className={styles.items}>
                        {items}
                    </div>
                </div>
            </div>
        </div>
    )
}
