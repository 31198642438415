import React, { useEffect } from 'react';
import { Action } from '../../../../../FunctionComponents/Action/Action';
import Trash from '../../../../../../images/Trash';
import Pencil from '../../../../../../images/Pencil';
import {uid} from 'react-uid';
import listStyles from './GroupsList.module.css';
import ActionIcon from '../../../../../../images/Action';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

export const GroupsList = (props) =>{
    const history = useHistory();
    function openList(target){
        const items = document.getElementsByClassName(listStyles.action);
        for(let i=0;i<items.length;i++){
            if(target.id==='action_list' && target===items[i]){
                target.classList.toggle(listStyles.show)
            }
            else{
                items[i].classList.remove(listStyles.show)
            }
        }
    }
    const items = props.items.map((item, i) => {
        const actions = [
            {
                text: 'Edit',
                image: <Pencil viewBox="0 0 18 18" />,
                func: ()=>{history.push(`/products/groups/edit/${item.id}`)}
            },
            {
                text: 'Delete',
                image: <Trash viewBox="0 0 18 18" />,
                func: props.delete
            }
        ];
        return(
            <div className={`${listStyles.item} ${i+1<props.items.length?listStyles.notFinal:''}`} key={uid(i)}>
                <div className={listStyles.item_value}>
                    <span>{item.id}</span>
                </div>
                <div className={listStyles.item_value}>
                    <span>{item.title}</span>
                </div>
                <div className={listStyles.item_value}>
                    <span>{item.products.length}</span>
                </div>
                {/* onClick={(e)=>{openList(e.currentTarget)}} */}
                <div className={listStyles.item_value__action}>
                    <div id='action_list' onClick={(e)=>{openList(e.currentTarget)}} className={listStyles.action}>
                        <div className={listStyles.action_icon}><ActionIcon /></div>
                        <Action index={i} className={listStyles.action_list} items={actions} />
                    </div>
                </div>
            </div>
        );
    });
    useEffect(()=>{
      document.addEventListener('click', e => {
        if (!(e.target).closest('#action_list')) {
            openList(e.target);
        }
        e.stopPropagation();
      });
    });
    return(
        <div className={listStyles.GroupsList}>
            <div className={listStyles.title}>
                <h4>ID</h4>
                <h4>GROUP TITLE</h4>
                <h4>PRODUCTS COUNT</h4>
                <h4 className={listStyles.action}>ACTION</h4>
            </div>
            <div className={listStyles.items}>
                {items}
            </div>
        </div>
    )
}