import React, { useEffect, useState } from 'react';
import styles from './General.module.css';
import { Button } from '../../../FunctionComponents/Button/Button';
import {uid} from 'react-uid';
import { Checkbox } from 'antd';
import RequestService from '../../../../api/post';
import { useDispatch, useSelector } from 'react-redux';

export const General = () => {
    const state = useSelector(state => state);
    const message = useSelector(state => state.message);
    const settings_ = useSelector(state => state.settings);
    const [error, setError] = useState({});
    const dispatch = useDispatch();
    const [settings, setSettings] = useState(localStorage.getItem('settings')?JSON.parse(localStorage.getItem('settings')):state.settings)
    const currencies = [
        'USD', 'EUR', 'JPY', 'GBP', 'AUD', 'CAD', 'CHF', 'CNY', 'SEK', 'NZD',
        'PLN', 'ILS', 'HKD', 'ISK', 'PHP', 'DKK', 'HUF', 'CZK', 'RON', 'IDR',
        'INR', 'BRL', 'RUB', 'HRK', 'THB', 'MYR', 'BGN', 'NOK', 'ZAR', 'MXN',
        'SGD', 'KRW'
    ];
    const currencyOptions = currencies.map((item)=>{
        return <option key={uid(item)} value={item.toLowerCase()} >{item}</option>
    });
    async function onSubmit(field, name, data){
        console.log(data)
        if(typeof data==='string'){
            data = JSON.parse(`{"${name}": "${data}"}`);
        }else{
            data = JSON.parse(`{"${name}": ${data}}`);
        }
        const response = await RequestService.GeneralSettings(JSON.parse(localStorage.getItem('shop')).id, field, data);
        if(response){
            dispatch({
                type: "SET_SETTINGS", 
                payload: settings
            });
            dispatch({
                type: "SET_MESSAGE", 
                payload: {isShow: true, status: true, text: 'Shop settings saved successfully'}
            });
        }
        else{
            dispatch({
                type: "SET_MESSAGE", 
                payload: {isShow: !message.isShow}
            });
            setError(data)
        }
    }
    useEffect(()=>{
        setSettings(localStorage.getItem('settings')?JSON.parse(localStorage.getItem('settings')):state.settings);
    // eslint-disable-next-line 
    }, [settings_]);
    return (
        <div className={styles.General}>
            <div className={`${styles.block} ${styles.name}`}>
                <h3>Business name</h3>
                <span className={styles.span}>This is your store name across Sellpass, both in the dashboard and your storefront. You can change this once every 30 days</span>
                <input type='text' onChange={e=>{setSettings({...settings, name: e.target.value})}} defaultValue={settings.name} />
                <div className={styles.bottom}>
                    <span className={styles.span}>Please use 32 characters at maximum</span>
                    <Button func={()=>{onSubmit('name', 'newName', settings.name)}} styleB='orange' content='Save' />
                </div>
            </div>
            <div className={`${styles.block} ${styles.name}`}>
                <h3>Currency</h3>
                <span className={styles.span}>Set the currency for your store globally</span>
                <div className={styles.select}>
                    <select value={settings.currency} onChange={e=>{setSettings({...settings, currency: e.target.value})}}>
                        {currencyOptions}
                    </select>
                </div>
                <div className={styles.bottom}>
                    <Button func={()=>{onSubmit('currency', 'currency', settings.currency)}} styleB='orange' content='Save' />
                </div>
            </div>
            <div className={`${styles.block} ${styles.horizon}`}>
                <div className={styles.horizon_main}>
                    <Checkbox checked={settings.onHold} onChange={()=>{setSettings({...settings, onHold: !settings.onHold})}} />
                    <div>
                        <h3>Store maintenance</h3>
                        <span className={styles.span}>If enabled, your store will be placed on hold and a message will appear notifying your customers that you’ll be back soon</span>
                    </div>
                </div>
                <div className={styles.bottom}>
                    <Button func={()=>{onSubmit('status', 'onHold', settings.onHold)}} styleB='orange' content='Save' />
                </div>
            </div>
            <div className={`${styles.block} ${styles.name}`}>
                <h3>Store popup</h3>
                <span className={styles.span}>Set a custom message for your customers to see once they visit your storefront</span>
                <textarea defaultValue={settings.popup} onChange={e=>{setSettings({...settings, popup: e.target.value})}} />
                <div className={styles.bottom}>
                    <span className={styles.span}>This message will be shown to anyone who visits your storefront</span>
                    <Button func={()=>{onSubmit('popup', 'message', settings.popup)}} styleB='orange' content='Save' />
                </div>
            </div>
            <div className={`${styles.block} ${styles.name}`}>
                <h3>Business visibility</h3>
                <div className={styles.shopTypes}>
                    <div className={styles.shopType}>
                        <input id="shopType1" type='radio' name='shopType'/>
                        <label htmlFor="shopType1" className={styles.typeBlock}>
                            <h3>Public store</h3>
                            <span>A public store can be found and accessed by anyone. Products created in this store will be visible, and can be purchased by anyone</span>
                        </label>
                    </div>
                    <div className={styles.shopType}>
                        <input id="shopType2" type='radio' name='shopType'/>
                        <label htmlFor="shopType2" className={styles.typeBlock}>
                            <h3>Hidden store</h3>
                            <span>A hidden store cannot be found or accessed unless someone has a direct link to it. Products created in this store are visible</span>
                        </label>
                    </div>
                    <div className={styles.shopType}>
                        <input id="shopType3" type='radio' name='shopType'/>
                        <label htmlFor="shopType3" className={styles.typeBlock}>
                            <h3>Private store</h3>
                            <span>A private store can only be accessed by you. Products created in this store will not be visible, and cannot be purchased</span>
                        </label>
                    </div>
                </div>
                <div className={styles.bottom}>
                    <span className={styles.span}>This message will be shown to anyone who visits your storefront</span>
                    <Button styleB='orange' content='Save' />
                </div>
            </div>
            <div className={`${styles.block} ${styles.horizon}`}>
                <div>
                    <h3>Delete account</h3>
                    <span className={styles.span}>Permanently delete your Sellpass account and all of its data. This action is permanent, proceed with caution!</span>
                </div>
                <div className={styles.bottom}>
                    <Button styleB='orange' content='Contact Support' />
                </div>
            </div>
        </div>
    )
}
