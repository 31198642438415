import * as React from "react";

function Icon(props) {
  return (
    <svg
      width={26}
      height={25}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect
        x={0.475}
        y={0.339}
        width={24.53}
        height={24.53}
        rx={8}
        fill="#FF004F"
      />
      <path
        d="M15.765 10.633a5.57 5.57 0 003.248 1.039v-2.33c-.23 0-.458-.024-.682-.072v1.834a5.57 5.57 0 01-3.249-1.039v4.755a4.308 4.308 0 01-6.707 3.578 4.308 4.308 0 007.39-3.011v-4.754zm.841-2.351a3.247 3.247 0 01-.841-1.901v-.3h-.647c.163.928.718 1.72 1.488 2.2zm-6.726 8.29a1.97 1.97 0 012.167-3.07v-2.381a4.344 4.344 0 00-.682-.04v1.854a1.974 1.974 0 00-2.57 1.878c0 .77.442 1.435 1.085 1.76z"
        fill="#fff"
      />
      <path
        d="M15.082 10.065a5.57 5.57 0 003.249 1.04V9.27a3.261 3.261 0 01-1.725-.988 3.262 3.262 0 01-1.488-2.2H13.42v9.305a1.97 1.97 0 01-3.54 1.186 1.97 1.97 0 011.485-3.638v-1.854a4.307 4.307 0 00-2.99 7.317 4.308 4.308 0 006.707-3.578v-4.755z"
        fill="#fff"
      />
      <path
        d="M18.331 9.27v-.496a3.25 3.25 0 01-1.725-.492c.458.501 1.061.847 1.725.988zm-3.213-3.189a3.345 3.345 0 01-.036-.267v-.3h-2.344v9.306a1.97 1.97 0 01-2.858 1.753 1.97 1.97 0 003.54-1.186V6.08h1.698zm-3.753 5v-.527a4.308 4.308 0 00-2.99 7.844 4.308 4.308 0 012.99-7.316z"
        fill="#fff"
      />
    </svg>
  );
}

export default Icon;
