import * as React from "react";

function Icon(props) {
  return (
    <svg
      width={26}
      height={26}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect
        x={0.475}
        y={0.853}
        width={24.53}
        height={24.53}
        rx={8}
        fill="#37AEE2"
      />
      <path
        d="M6.747 12.761c1.264-.696 2.675-1.277 3.993-1.86a207.746 207.746 0 016.844-2.772c.447-.15 1.251-.295 1.33.368-.043.938-.22 1.871-.343 2.805-.309 2.051-.666 4.096-1.015 6.141-.12.682-.973 1.034-1.519.598-1.312-.886-2.635-1.764-3.93-2.67-.424-.432-.03-1.051.348-1.36 1.08-1.064 2.227-1.97 3.251-3.09.277-.667-.54-.104-.809.068-1.48 1.02-2.923 2.101-4.483 2.998-.797.438-1.726.063-2.522-.181-.714-.296-1.76-.594-1.145-1.045z"
        fill="#fff"
      />
    </svg>
  );
}

export default Icon;
