import React from 'react';
import { Link } from "react-router-dom";
import styles from './Header.module.css';
import Logo from '../../../images/Logo';
import { InfoButton } from './InfoButton/InfoButton';
import { Theme } from '../../FunctionComponents/Theme/Theme';
import { Notifications } from './Notifications/Notifications';
import { Account } from './Account/Account';

export const openMenu = () => {
  if(window.screen.width<1200){
    document.getElementById('navBar').classList.toggle(styles.show);
    document.getElementById('back').classList.toggle(styles.showBack);
    document.getElementById('buttons').classList.toggle(styles.hide);
    document.getElementsByClassName(styles.bMenu)[0].classList.toggle(styles.active);
  }
};

export const Header = () => {
  return (
    <div className={styles.Header}>
      <div className={styles.content}>
        <button className={styles.bMenu} onClick={openMenu} >
          <span></span>
          <span></span>
          <span></span>
        </button>
        <Link className={styles.logo} to='/'><Logo viewBox='0 0 119 24' /></Link>
        <div id='buttons' className={styles.header_buttons}>
          <Theme />
          <InfoButton />
          <Notifications />
          <Account />
        </div>
      </div>
    </div>
  )
}
