import React from 'react';
import { AccountNav } from './AccountNav/AccountNav';
import { Links } from './Links/Links';
import styles from './NavBar.module.css';

export const NavBar = () => {
  return (
    <div id='navBar' className={styles.NavBar}>
        <AccountNav />
        <Links />
    </div>
  )
}
