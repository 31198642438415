import * as React from "react";

function Icon(props) {
  return (
    <svg
      width={11}
      height={17}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M.465 4.544a.435.435 0 00.443.453h1.514c.254 0 .456-.207.489-.459.165-1.204.99-2.081 2.463-2.081 1.26 0 2.413.63 2.413 2.144 0 1.166-.687 1.702-1.772 2.517-1.236.897-2.214 1.946-2.144 3.647l.005.399a.459.459 0 00.46.452h1.488a.459.459 0 00.459-.46v-.192c0-1.318.501-1.702 1.854-2.728 1.118-.85 2.284-1.794 2.284-3.775 0-2.774-2.343-4.114-4.907-4.114C3.188.347.64 1.43.465 4.544zm2.859 10.58c0 .978.78 1.702 1.854 1.702 1.118 0 1.887-.724 1.887-1.702 0-1.014-.771-1.726-1.889-1.726-1.072 0-1.852.712-1.852 1.726z"
        fill="#FF3F19"
      />
    </svg>
  );
}

export default Icon;
