import * as React from "react";

function Icon(props) {
  return (
    <svg
      width={8}
      height={7}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M4.349 3.93l-2.83 2.83a.327.327 0 01-.24.1.341.341 0 01-.246-.096.341.341 0 01-.097-.247c0-.093.033-.173.1-.24l2.83-2.83-2.83-2.83a.331.331 0 01-.1-.243.34.34 0 01.1-.24.34.34 0 01.243-.1c.094 0 .174.034.24.1l2.83 2.83 2.83-2.83a.331.331 0 01.243-.1.34.34 0 01.24.583l-2.83 2.83 2.83 2.83a.34.34 0 010 .483.34.34 0 01-.24.1.331.331 0 01-.243-.1l-2.83-2.83z"
        fill="#0F0F0F"
      />
    </svg>
  );
}

export default Icon;