import React from 'react';
import { ComingSoon } from '../../../../StyledComponents/ComingSoon/ComingSoon';
import styles from './SEOInfo.module.css';

export const SEOInfo = () => {
  return (
    <div className={styles.SEOInfo}>
        <div className={styles.title}>
            <h3 className={styles.h3}>Search engine listing preview</h3>
            <ComingSoon />
        </div>
        <span>Customize the SEO title and description to optimize keywords and rankings on search results</span>
    </div>
  )
}
