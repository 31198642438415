import React, { useEffect, useState } from 'react';
import styles from './Payments.module.css';
import Stripe from '../../../../images/Stripe';
import PayPal from '../../../../images/PayPal';
import Commerce from '../../../../images/Commerce';
import CashApp from '../../../../images/CashApp';
import FlashIcon from '../../../../images/FlashIcon';
import TelegramIcon from '../../../../images/TelegramIcon';
import Discord from '../../../../images/Discord';
import Twitter from '../../../../images/Twitter';
import { PRO } from '../../../StyledComponents/Types/Types';
import { Checkbox, Switch } from 'antd';
import { Button } from '../../../FunctionComponents/Button/Button';
import {uid} from 'react-uid';
import { Attention } from '../../../FunctionComponents/Attention/Attention';
import { Modal } from '../../../FunctionComponents/Modal/Modal';
import { ModalMessage } from './ModalMessage';
import { useDispatch, useSelector } from 'react-redux';
import RequestService from '../../../../api/post';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';


export const Payments = () => {
    const settings = useSelector(state => state.settings);
    const message = useSelector(state => state.message);
    const dispatch = useDispatch();
    const [changeStatus, setChangeStatus] = useState(true);
    const [gateways, setGateways] = useState(localStorage.getItem('settings')?JSON.parse(localStorage.getItem('settings')).gateways:settings.gateways);
    console.log(gateways)
    const [coinbaseCommerce, setCoinbaseCommerce] = useState({
        apiKey: "",
        webhookSecret: "",
        gatewayRules: {
            blockVpn: false
        }
      });
    const [paypal, setPaypal] = useState({
        email: "",
        gatewayRules: {
            blockVpn: false
        }
    });
    const[modalShow, setModalShow] = useState({
        isShow: false,
        item: 0
    });
    const [switches, setSwitches] = useState({
        minCheckout: false,
        voidOrders: false,
        cart: false,
        connect: false
    });
    const paymentsData = [
        {
            name: 'Stripe',
            icon: <Stripe viewBox='0 0 26 25' />,
            text: 'Connect Stripe',
            status: typeof gateways['stripeConnect'] !== "undefined"
        },
        {
            name: 'PayPal',
            icon: <PayPal viewBox='0 0 30 29' />,
            text: 'Configure PayPal',
            status: typeof gateways['payPalEmail'] !== "undefined"
        },
        {
            name: 'Coinbase Commerce',
            icon: <Commerce viewBox='0 0 26 25' />,
            text: 'Configure Coinbase Commerce',
            status: typeof gateways['coinbaseCommerce'] !== "undefined"
        },
        {
            name: 'CashApp',
            icon: <CashApp viewBox='0 0 26 25' />,
            text: 'Connect CashApp',
            status: typeof gateways['cashapp'] !== "undefined"
        }
    ];
    function isConfigurated(obj){
        let answer = false;
        switch(obj){
            case 1:
                if(
                    paypal.email===gateways.payPalEmail.email &&
                    paypal.gatewayRules.blockVpn===gateways.payPalEmail.settings.rules.blockVpn
                ){
                    answer = false;
                }else{
                    answer = true;
                }
                break;
            case 2:
                if(
                    coinbaseCommerce.apiKey===gateways.coinbaseCommerce.apikey &&
                    coinbaseCommerce.webhookSecret===gateways.coinbaseCommerce.webhookSecret &&
                    coinbaseCommerce.gatewayRules.blockVpn===gateways.coinbaseCommerce.settings.rules.blockVpn
                ){
                    answer = false;
                }else{
                    answer = true;
                }
                break;
            case 3:
                if(gateways.stripeConnect){
                    answer = true;
                }else{
                    answer = false;
                }
                break;
            default:
                answer = false;
        }
        return answer
    }
    const paymentList = Object.keys(paymentsData).map((item, i)=>{
        return (
            <div key={uid(item)} className={styles.payment} onClick={()=>{i===0?window.open('https://connect.stripe.com/oauth/authorize?response_type=code&client_id=ca_IzL5yBLaPB8d6ocjrv9XKO3VsrpOiY1V&scope=read_write', '_blank'):setModalShow({isShow: true, item: i})}}>
                <span className={styles.payment_span}>{paymentsData[item].name}</span>
                <div className={styles.payment_button}>
                    {paymentsData[item].icon}
                    <span className={styles.payment_text}>{paymentsData[item].text}</span>
                    {paymentsData[item].status?<div className={`${styles.check} ${isConfigurated(i)?'':styles.checked}`}></div>:''}
                </div>
            </div>
        )
    });
    async function onSubmit(){
        if(paypal.email){
            const PayPal = await RequestService.PaymentsShopSettingsPayPal(JSON.parse(localStorage.getItem('shop')).id, paypal);
            if(PayPal){
                dispatch({
                    type: "SET_MESSAGE", 
                    payload: {isShow: true, status: true, text: 'Payments settings saved successfully'}
                });
                setChangeStatus(!changeStatus);
            }
            else{
                dispatch({
                    type: "SET_MESSAGE", 
                    payload: {isShow: !message.isShow}
                });
            }
        }
        if(coinbaseCommerce.apiKey&&coinbaseCommerce.webhookSecret){
            const coinbase = await RequestService.PaymentsShopSettingsCommerce(JSON.parse(localStorage.getItem('shop')).id, coinbaseCommerce);
            if(coinbase){
                dispatch({
                    type: "SET_MESSAGE", 
                    payload: {isShow: true, status: true, text: 'Payments settings saved successfully'}
                });
                setChangeStatus(!changeStatus);
            }
            else{
                dispatch({
                    type: "SET_MESSAGE", 
                    payload: {isShow: !message.isShow}
                });
            }
        }
    }
    async function setStripeConf(){
        const params = new Proxy(new URLSearchParams(window.location.search), {
            get: (searchParams, prop) => searchParams.get(prop),
          });
        const code = params.code;
        if(code && code.length>0){
            const data = {
                code: code,
                gatewayRules: {
                    blockVpn: true
                }
            }
            const response = await RequestService.PaymentsShopSettingsStripe(JSON.parse(localStorage.getItem('shop')).id, data);
            if(response){
                dispatch({
                    type: "SET_MESSAGE", 
                    payload: {isShow: true, status: true, text: 'Payments settings saved successfully'}
                });
                setChangeStatus(!changeStatus);
            }
            else{
                dispatch({
                    type: "SET_MESSAGE", 
                    payload: {isShow: !message.isShow}
                });
            }
        }
    }
    useEffect(()=>{
        setGateways(localStorage.getItem('settings')?JSON.parse(localStorage.getItem('settings')).gateways:settings.gateways);
        if(gateways.coinbaseCommerce){
            setCoinbaseCommerce({
                apiKey: gateways.coinbaseCommerce.apikey,
                webhookSecret: gateways.coinbaseCommerce.webhookSecret,
                gatewayRules: {
                    blockVpn: gateways.coinbaseCommerce.settings.rules.blockVpn
                }
            });
        }
        if(gateways.payPalEmail){
            setPaypal({
                email: gateways.payPalEmail.email,
                gatewayRules: {
                    blockVpn: Object.keys(gateways).length>0?gateways.payPalEmail.settings.rules.blockVpn:true
                }
        });
        }
    // eslint-disable-next-line 
    }, [settings]);
    useEffect(()=>{
        setGateways(localStorage.getItem('settings')?JSON.parse(localStorage.getItem('settings')).gateways:settings.gateways);
    // eslint-disable-next-line 
    }, [message, settings, changeStatus]);
    useEffect(()=>{
        setStripeConf();
    }, []);
    return (
            <div className={styles.Payments}>
                {modalShow.isShow?<Modal onClick={()=>{setModalShow({...modalShow, isShow: false})}} component={
                <ModalMessage 
                    item={modalShow.item}
                    close={()=>{setModalShow({...modalShow, isShow: false})}} 
                    modalShow={modalShow}
                    coinbaseCommerce={coinbaseCommerce}
                    setCoinbaseCommerce={setCoinbaseCommerce}
                    paypal={paypal}
                    setPaypal={setPaypal}
                    setModalShow={setModalShow}
                />}/>:''}
                <div className={`${styles.block} ${styles.name}`}>
                    <h3>Payments</h3>
                    <span className={styles.span}>Set up and enable payment method along with other checkout-related settings</span>
                    <div className={styles.payments}>
                        {paymentList}
                    </div>
                    <div className={styles.attention}>
                        <Attention icon={<FlashIcon viewBox='0 0 11 17' />} content='Upgrade to our Business plan to accept CashApp' />
                    </div>
                    <div className={styles.bottom}>
                        <Button func={onSubmit} styleB='orange' content='Save' />
                    </div>
                </div>
                <div className={`${styles.block} ${styles.half}`}>
                    <div className={styles.horizon}>
                        <div className={styles.horizon_main}>
                            <h3>Minimum checkout</h3>
                            <span className={styles.span}>Require a total minimum threshold for checkout</span>
                        </div>
                        <Switch checked={switches.minCheckout} onClick={()=>(setSwitches({...switches, minCheckout: !switches.minCheckout}))} />
                    </div>
                    <div className={switches.minCheckout?styles.compShow:styles.compHide}>
                        <div className={styles.countInput}>
                            <input type='number' step='0.01' defaultValue='0.01' />
                        </div>
                        <div className={styles.bottom}>
                            <Button styleB='orange' content='Save' />
                        </div>
                    </div>
                </div>
                <div className={`${styles.block} ${styles.half}`}>
                    <div className={styles.horizon}>
                        <div className={styles.horizon_main}>
                            <h3>Void orders</h3>
                            <span className={styles.span}>Automatically cancel orders if they surpass a certain limit</span>
                        </div>
                        <Switch checked={switches.voidOrders} onClick={()=>(setSwitches({...switches, voidOrders: !switches.voidOrders}))} />
                    </div>
                    <div className={switches.voidOrders?styles.compShow:styles.compHide}>
                        <div className={styles.voidInput}>
                            <input type='number' defaultValue='1' />
                        </div>
                        <div className={styles.bottom}>
                            <Button styleB='orange' content='Save' />
                        </div>
                    </div>
                </div>
                <div className={`${styles.block} ${styles.name}`}>
                    <div className={styles.cart}>
                        <PRO />
                    </div>
                    <div className={styles.horizon}>
                        <div className={styles.horizon_main}>
                            <h3>Shopping cart</h3>
                            <span className={styles.span}>Allow customers to bundle products under a single cart, while also setting the maximum amount they can checkout for</span>
                        </div>
                        <Switch checked={switches.cart} onClick={()=>(setSwitches({...switches, cart: !switches.cart}))} />
                    </div>
                    <div className={switches.cart?styles.compShow:styles.compHide}>
                        <div className={styles.cartInput}>
                            <input type='number' step='0.01' defaultValue='0.01' />
                        </div>
                        <div className={styles.bottom}>
                            <Button styleB='orange' content='Save' />
                        </div>
                    </div>
                </div>
                <div className={`${styles.block} ${styles.name}`}>
                    <div className={styles.horizon}>
                        <div className={styles.horizon_main}>
                            <h3>Require social connect</h3>
                            <span className={styles.span}>Make sure your customers connect their social media account before they can purchase</span>
                        </div>
                        <Switch checked={switches.connect} onClick={()=>(setSwitches({...switches, connect: !switches.connect}))} />
                    </div>
                    <div className={switches.connect?styles.compShow:styles.compHide}>
                        <div className={styles.soc}>
                            <div className={styles.dButton_wrapper}>
                                <div className={styles.dButton}>
                                    <Discord viewBox='0 0 18 14' />
                                    <span className={styles.span}>Discord</span>
                                    <Switch />
                                </div>
                                <div className={styles.dButton_checkBox}>
                                    <Checkbox />
                                    <span className={styles.span}>Redirect user to join discord server</span>
                                </div>
                            </div>
                            <div className={styles.dButton_wrapper}>
                                <div className={styles.dButton}>
                                    <TelegramIcon viewBox='0 0 20 17' />
                                    <span className={styles.span}>Telegram</span>
                                    <Switch />
                                </div>
                                <div className={styles.dButton_checkBox}>
                                    <Checkbox />
                                    <span className={styles.span}>Redirect user to join telegram server</span>
                                </div>
                            </div>
                            <div className={styles.dButton_wrapper}>
                                <div className={styles.dButton}>
                                    <Twitter viewBox='0 0 18 14' />
                                    <span className={styles.span}>Twitter</span>
                                    <Switch />
                                </div>
                                <div className={styles.dButton_checkBox}>
                                    <Checkbox />
                                    <span className={styles.span}>Redirect user to follow twitter profile</span>
                                </div>
                            </div>
                        </div>
                        <div className={styles.bottom}>
                            <Button styleB='orange' content='Save' />
                        </div>
                    </div>
                </div>
            </div>
    )
}
