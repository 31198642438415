import React, { useState } from 'react';
import styles from './Search.module.css';
import Search from '../../../images/Search.js';

export const SearchInput = (props) => {
    const [searchTerm, setSearchTerm] = useState('');
    const handleChange = event => {
      setSearchTerm(event.target.value);
      props.func(searchTerm);
    };
    return(
        <div className={styles.Search}>
            <Search viewBox="0 0 20 20" />
            <input 
                type="text"
                placeholder={props.placeholder}
                value={searchTerm}
                onChange={handleChange}
            />
        </div>
    )
}
