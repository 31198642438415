import * as React from "react";

function Icon(props) {
  return (
    <svg
      width={20}
      height={17}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M.693 7.869C2.686 6.771 4.912 5.854 6.99 4.933c3.578-1.509 7.17-2.992 10.797-4.372.706-.235 1.974-.465 2.099.58-.068 1.481-.349 2.953-.54 4.425-.488 3.237-1.052 6.463-1.602 9.69-.19 1.074-1.535 1.63-2.397.943-2.07-1.399-4.156-2.783-6.2-4.214-.67-.68-.048-1.657.55-2.143 1.705-1.68 3.513-3.108 5.129-4.875.436-1.053-.852-.166-1.277.106-2.334 1.609-4.611 3.316-7.072 4.73-1.258.691-2.723.1-3.98-.286C1.373 9.05-.278 8.58.694 7.869z"
        fill="#37AEE2"
      />
    </svg>
  );
}

export default Icon;
