import React, { useEffect, useState } from 'react';
import styles from './ProductEdit.module.css';
import { MainInfo } from './MainInfo/MainInfo';
import { TypeInfo } from './TypeInfo/TypeInfo';
import { AdditInfo } from './AdditInfo/AdditInfo';
import { SEOInfo } from './SEOInfo/SEOInfo';
import { ImageInput } from './ImageInput/ImageInput';
import { PayMethods } from './PayMethods/PayMethods';
import { CustomFields } from './CustomFields/CustomFields';
import { Heading } from '../../Heading/Heading';
import { Button } from '../../../FunctionComponents/Button/Button';
import { InfoCardEdit } from './InfoCardEdit/InfoCardEdit';
import RequestService from '../../../../api/post';
import { useForm, Controller } from "react-hook-form";
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

export const ProductEdit = (props) => {
    const state = useSelector(state => state);
    const message = useSelector(state => state.message);
    const dispatch = useDispatch();
    const [product, setProduct] = useState({});
    const [description, setDescription] = useState('Description');
    const [image, setImage] = useState();
    const { register, handleSubmit, control, getValues, formState: { errors }, watch, setValue } = useForm();
    const [custom, setCustom] = useState('');
    const [delimiter, setDelimiter] = useState('comma');
    const [rD, setRD] = useState(true);
    const location = useLocation();
    const PageType = location.pathname.match(/\/products\/(.*)\//)?location.pathname.match(/\/products\/(.*)\//)[1]==='edit':false;
    async function getProduct(productId){
        const response = await RequestService.ProductGet(JSON.parse(localStorage.getItem('shop')).id, productId);
        if(!response){
            dispatch({
                type: "SET_MESSAGE", 
                payload: {isShow: !message.isShow}
            });
        }
        const data = response.data.data;
        setValue('title', data.title);
        setValue('description', data.description);
        setValue('price', data.variants[0].price);
        setValue('currency', data.variants[0].currency);
        setValue('type', data.variants[0].productType);
        setValue('customerNote', data.variants[0].customerNote);
        setValue('onHold', data.onHold);
        setValue('private', data.private);
        setValue('terms', data.terms);
        setValue('unlisted', data.unlisted);
        switch(data.variants[0].productType){
            case 0:
                const delimiter = data.variants[0].asSerials.delimiter;
                if(delimiter==='newLine'||delimiter==='comma'){
                    setDelimiter(delimiter);
                }else{
                    setDelimiter('custom');
                    setCustom(delimiter);
                }
                const serials = data.variants[0].asSerials.serials;
                let serialsString = '';
                serials.forEach((item, i) => {
                    serialsString += item
                    if(i<serials.length-1){
                        serialsString += delimiter
                    }
                });
                setValue('asSerials.serials',serialsString);
                setValue('asSerials.maxAmount', data.variants[0].asSerials.maxAmount);
                setValue('asSerials.minAmount', data.variants[0].asSerials.minAmount);
                break;
            case 2:
                setValue('asDynamic.externalUrl', data.variants[0].asDynamic.externalUrl);
                setValue('asDynamic.maxAmount', data.variants[0].asDynamic.maxAmount);
                setValue('asDynamic.minAmount', data.variants[0].asDynamic.minAmount);
                setValue('asDynamic.stock', data.variants[0].asDynamic.stock);
                break;
            case 3:
                setValue('asService.text', data.variants[0].asService.text);
                setValue('asService.maxAmount', data.variants[0].asService.maxAmount);
                setValue('asService.minAmount', data.variants[0].asService.minAmount);
                setValue('asService.stock', data.variants[0].asService.stock);
                break;
        }
    }
    async function onSubmit(data){
        setDescription(description!==' '?description:'');
        data = {...data, type: parseInt(data.type)}
        data = {...data, description: description}
        switch(parseInt(data.type)){
            case 0:
                let delim;
                switch(delimiter){
                    case 'custom':
                        delim = custom;
                        break;
                    case 'newLine':
                        delim = '\n';
                        break;
                    case 'comma':
                        delim = ',';
                        break;
                    default:
                        delim = delimiter
                }
                data.asSerials = {...data.asSerials, delimiter: delim};
                delete data.asService;
                delete data.asDynamic;
                break;
            case 2:
                delete data.asService;
                delete data.asSerials;
                break;
            case 3:
                delete data.asDynamic;
                delete data.asSerials;
                break;
            default:
                console.log('default');
        }
        data = {...data, gatewaysList: Object.assign({}, data.gatewaysList)}
        console.log(data)
        if(PageType){
            const response = await RequestService.ProductEdit(JSON.parse(localStorage.getItem('shop')).id, location.pathname.match(/\/.*\/([0-9]*)/)[1], data);
            if(response){
                dispatch({
                    type: "SET_MESSAGE", 
                    payload: {isShow: true, status: true, text: 'Product edited successfully'}
                });
            }
            else{
                dispatch({
                    type: "SET_MESSAGE", 
                    payload: {isShow: !message.isShow}
                });
            }
        }else{
            const response = await RequestService.ProductNew(JSON.parse(localStorage.getItem('shop')).id, data);
            if(response){
                dispatch({
                    type: "SET_MESSAGE", 
                    payload: {isShow: true, status: true, text: 'Product created successfully'}
                });
            }
            else{
                dispatch({
                    type: "SET_MESSAGE", 
                    payload: {isShow: !message.isShow}
                });
            }
        }
    }

    async function newThumb(){
        console.log(image);
        const products = await RequestService.ProductsGet(JSON.parse(localStorage.getItem('shop')).id);
        console.log(products.data.data?products.data.data.reverse()[products.data.data.reverse().length-1].id:[])
        await RequestService.ProductImage(JSON.parse(localStorage.getItem('shop')).id, location.pathname.match(/\/.*\/([0-9]*)/)[1], image);
    }
    const types = {
        product:{
            title: PageType?'Edit Product':'New Product',
            switch: 'Switch to info card',
            submit: `${
                (PageType)?
                    'Edit product'
                    :
                    '+ Create product'
            }`
        },
        card:{
            title: 'New Info Card',
            switch: 'Switch to product',
            submit: '+ Create Info Card'
        }
    }
    const [type, setType] = useState('product');
    useEffect(()=>{
        if(PageType){
            getProduct(location.pathname.match(/\/.*\/([0-9]*)/)[1]);
        }
    }, []);
    useEffect(()=>{
        if(image && Object.keys(errors).length===0){
            console.log(Object.keys(errors).length===0)
            newThumb();
        }
    }, [state]);
    return (
        <div className={styles.ProductEdit}>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className={styles.header}>
                    <Heading title={props.title} pathTitle={type==='product'?types.product.title:types.card.title}  link='/products/all-products'/>
                    <div className={styles.func}>
                        <Button type="button" styleB='light' size='large' func={()=>{setType(type==='product'?'card':'product')}} content={type==='product'?types.product.switch:types.card.switch} />
                        <button type="submit" className={styles.submit}>{type==='product'?types.product.submit:types.card.submit}</button>
                    </div>
                </div>
                {type==='product'?
                    <div className={styles.ProductEdit_container}>
                            <div className={styles.leftBlocks}>
                                <MainInfo product={product} register={register} errors={errors} description={description} setDescription={setDescription} />
                                <TypeInfo 
                                    register={register} 
                                    getValues={getValues} 
                                    custom={custom} 
                                    setCustom={setCustom} 
                                    delimiter={delimiter} 
                                    setDelimiter={setDelimiter}
                                    rD={rD} 
                                    setRD={setRD}
                                    errors={errors}
                                    product={product}
                                    watch={watch}
                                />
                                <AdditInfo product={product} register={register} control={control} Controller={Controller} watch={watch} />
                                <SEOInfo />
                            </div>
                            <div className={styles.rightBlocks}>
                                <ImageInput product={product} setImage={setImage} />
                                <PayMethods product={product} control={control} Controller={Controller} />
                                <CustomFields product={product} />
                            </div>
                    </div>
                :
                <div className={styles.ProductEdit_container}>
                    <InfoCardEdit />
                </div>
                }
            </form>
        </div>
    )
}
