import * as React from "react";

function Icon(props) {
  return (
    <svg
      width={26}
      height={25}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect
        x={0.475}
        y={0.11}
        width={24.53}
        height={24.53}
        rx={8}
        fill="#1977F3"
      />
      <path
        d="M17.202 16.107l.531-3.47h-3.328v-2.252c0-.95.464-1.876 1.956-1.876h1.514V5.555s-1.374-.234-2.687-.234c-2.741 0-4.534 1.66-4.534 4.67v2.645H7.605v3.47h3.049v8.389a12.252 12.252 0 003.75 0v-8.388h2.798z"
        fill="#fff"
      />
    </svg>
  );
}

export default Icon;
