import React from 'react';
import {uid} from 'react-uid';
import styles from './List.module.css';
import moment from 'moment';
import { Link } from 'react-router-dom';

export const List = (props) => {
  //props.array props.booleans
  let list = (
    <div className={styles.wrapper}>
    </div>
  );
  if(props.isShow && props.items.length){
    const options = [];
    for(let i=0; i<props.items.length; i++){
      options.push(
        <Link to={props.items[i].link} key={uid(i)} onClick={()=>{props.readNotification(props.items[i].id);props.setIsShow()}} className={`${styles.option} ${props.items[i].isRead?styles.read:''}`}>
          <div className={styles.content}>
            <h3 className={styles.title}>{props.items[i].title}</h3>
            <div className={styles.span}>{props.items[i].message}</div>
            <span>{moment(props.items[2].date).fromNow()}</span>
          </div>
          {/* <div onClick={()=>{props.removeItem('once', i)}} id={props.id} className={styles.close}>{props.image} </div> */}
        </Link>
      );
    }
    list = (
      <div className={styles.List}>
        <div className={styles.notifyContent}>
          <div className={styles.wrapper}>
            {options}
          </div>
          <div onClick={()=>{props.deleteInvoices()}} className={styles.clear}>
              <span>Clear all notifications</span>
          </div>
        </div>
      </div>
    );
  }
  else{
    list = (
      <div className={styles.List}>
      </div>
    );
  }
  return <div>{list}</div>;
}
