import React, { useEffect } from 'react';
import styles from './Message.module.css';
import Success from '../../../images/Success';
import Error from '../../../images/Error';
import { useDispatch, useSelector } from 'react-redux';

export const Message = () => {
    const state = useSelector(state => state.message);
    const dispatch = useDispatch();
    function hideMessage(){
        dispatch({
            type: "SET_MESSAGE", 
            payload: {...state, isShow: false}
        });
    };
    useEffect(()=>{
        if(state.isShow){
            setTimeout(hideMessage, 3500);
        }
    }, [state]);
    return (
    <div className={`${styles.Message} ${state.isShow?styles.show:''} ${state.status?styles.success:styles.error}`}>
        {state.status?<Success viewBox='0 0 30 30'/>:<Error viewBox='0 0 30 30'/>}
        <div className={styles.content}>
            <h2>{state.status?'SUCCESS':'ERROR'}</h2>
            <span>{state.status?state.text:'An error occured while trying to access one of your resources'}</span>
        </div>
    </div>
    )
}
