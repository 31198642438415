import * as React from "react";

function Icon(props) {
  return (
    <svg
      width={17}
      height={13}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.205 11.075a.5.5 0 01-.5.5h-8a.5.5 0 01-.5-.5v-9a.5.5 0 01.5-.5h8a.5.5 0 01.5.5v2a.5.5 0 001 0v-2a1.5 1.5 0 00-1.5-1.5h-8a1.5 1.5 0 00-1.5 1.5v9a1.5 1.5 0 001.5 1.5h8a1.5 1.5 0 001.5-1.5v-2a.5.5 0 00-1 0v2z"
        fill="#000"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.06 6.93a.501.501 0 000-.709l-3-3a.5.5 0 10-.709.708l2.147 2.146H5.705a.5.5 0 100 1h8.793l-2.147 2.146a.5.5 0 10.708.708l3-3z"
        fill="#000"
      />
    </svg>
  );
}

export default Icon;