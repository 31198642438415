import React from 'react';
import { Button } from '../../../FunctionComponents/Button/Button';
import styles from './Payments.module.css';
import { Attention } from '../../../FunctionComponents/Attention/Attention';
import QuestionMark from '../../../../images/QuestionMark';
import Commerce from '../../../../images/Commerce';
import CashApp from '../../../../images/CashApp';
import PayPal from '../../../../images/PayPal';
import CopyIcon from '../../../../images/CopyIcon';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import { Switch } from 'antd';

export const ModalMessage = (props) =>{
    const [coinbaseCommerce, setCoinbaseCommerce] = [props.coinbaseCommerce, props.setCoinbaseCommerce];
    const [paypal, setPaypal] = [props.paypal, props.setPaypal];
    const modalShow = props.modalShow;
    const setModalShow = props.setModalShow;
    const attentionContent = (
        <span>
            Note: do not forget to add our webhook endpoint to your Coinbase Commerce account as specified in the <Link>help article</Link>.<br/><br/>
            If you don't do so, payments will fail.
        </span>
    );
    const attentionContentCA = (
        <span>
            If you change your CashApp Tag, you must change it here as well.<br/><br/>
            If you change/remove the Sellpass email address on your CashApp account, payments will not be automatically processed.<br/><br/>
            You will need to re-verify the email and enter a new confirmation code.
        </span>
    );
    const payments = {
        0: (
            <div className={styles.ModalMessage_content}>
                <h3>Contact Support</h3>
                <span className={styles.span}>Please contact our support team via Discord by creating a ticket if you require anymore assistance with integrating any payment method to our website.</span>
                <div className={styles.buttons}>
                    <Button func={props.close} content='Cancel' size='small' styleB='light' />
                    <Button func={props.close} content='Join Discord' size='small' styleB='orange' />
                </div>
            </div>
        ),
        1: (
            <div className={styles.ModalMessage_content}>
                <h3>Configure PayPal</h3>
                <span className={styles.span}>Please enter the email address used in your PayPal account</span>
                <div className={styles.customInput}>
                    <div className={styles.customInput_input}>
                        <PayPal viewBox='0 0 23 24' />
                        <input type='text' placeholder='Email' onChange={(e)=>{
                                setPaypal({...paypal, email: e.target.value});
                            }}
                            value={paypal.email}/>
                    </div>
                </div>
                <div className={styles.switchH}>
                    <Switch onChange={(e)=>{
                            setPaypal({...paypal, gatewayRules: {...paypal.gatewayRules, blockVpn: e}})
                        }}
                    checked={paypal.gatewayRules.blockVpn}/>
                    <span className={styles.span}>Block buyers using a VPN/Proxy</span>
                </div>
                <div className={styles.buttons}>
                    <Button func={()=>{setModalShow({...modalShow, item: 0})}} content='I need help' size='small' styleB='light' />
                    <Button func={props.close} content='Done' size='small' styleB='orange' />
                </div>
            </div>
        ),
        2: (
            <div className={styles.ModalMessage_content}>
                <h3>Configure Coinbase Commerce</h3>
                <h4>Step 1</h4>
                <span className={styles.span}>Coinbase Commerce API Key</span>
                <div className={styles.customInput}>
                    <div className={styles.customInput_input}>
                        <Commerce viewBox='0 0 23 24' />
                        <input type='password' onChange={(e)=>{
                                setCoinbaseCommerce({...coinbaseCommerce, apiKey: e.target.value});
                            }}
                            value={coinbaseCommerce.apiKey}/>
                    </div>
                </div>
                <h4>Step 2</h4>
                <span className={styles.span}>Coinbase Commerce Webhook Secret</span>
                <div className={styles.customInput}>
                    <div className={styles.customInput_input}>
                        <Commerce viewBox='0 0 23 24' />
                        <input type='password' onChange={(e)=>{
                                setCoinbaseCommerce({...coinbaseCommerce, webhookSecret: e.target.value});
                            }}
                            value={coinbaseCommerce.webhookSecret}/>
                    </div>
                </div>
                <Attention icon={<QuestionMark viewBox='0 0 11 17' />} content={attentionContent} />
                <div className={styles.switchH}>
                <Switch onChange={(e)=>{
                        setCoinbaseCommerce({...props.coinbaseCommerce, gatewayRules: {...props.coinbaseCommerce.gatewayRules, blockVpn: e}})
                    }}
                    checked={coinbaseCommerce.gatewayRules.blockVpn}/>
                    <span className={styles.span}>Block buyers using a VPN/Proxy</span>
                </div>
                <div className={styles.buttons}>
                    <Button func={()=>{setModalShow({...modalShow, item: 0})}} content='I need help' size='small' styleB='light' />
                    <Button func={props.close} content='Done' size='small' styleB='orange' />
                </div>
            </div>
        ),
        3: (
            <div className={styles.ModalMessage_content}>
                <h3>Configure CashApp</h3>
                <h4>Step 1</h4>
                <span className={styles.span}>CashApp Email</span>
                <div className={styles.customInput}>
                    <div className={styles.customInput_input}>
                        <CashApp viewBox='0 0 26 27' />
                        <input type='text' value='dA2Fasxfa@c.sellpass.io'/>
                        <div className={styles.customSVG}>
                            <CopyIcon viewBox='0 0 14 16' />
                        </div>
                    </div>
                </div>
                <span className={styles.span}>This email must be added as a email to your CashApp account. Sellpass will send you an email with the confirmation code to confirm the CashApp Email</span>
                <h4>Step 2</h4>
                <span className={styles.span}>Confirmation Code</span>
                <div className={styles.customInput}>
                    <div className={styles.customInput_input}>
                        <CashApp viewBox='0 0 26 27' />
                        <input type='text' value='251052'/>
                        <div className={styles.customSVG}>
                            <CopyIcon viewBox='0 0 14 16' />
                        </div>
                    </div>
                </div>
                <span className={styles.span}>Your confirmation code will appear above automatically, enter it into CashApp where prompted</span>
                <h4>Step 3</h4>
                <span className={styles.span}>CashApp Tag</span>
                <div className={styles.customInput}>
                    <div className={styles.customInput_input}>
                        <CashApp viewBox='0 0 26 27' />
                        <input type='text' value='$example'/>
                        <div className={styles.customSVG}>
                            <CopyIcon viewBox='0 0 14 16' />
                        </div>
                    </div>
                </div>
                <span className={styles.span}>Enter your CashApp Tag above for payments to be sent</span>
                <Attention icon={<QuestionMark viewBox='0 0 11 17' />} content={attentionContentCA} />
                <div className={styles.switchH}>
                    <Switch />
                    <span className={styles.span}>Block buyers using a VPN/Proxy</span>
                </div>
                <div className={styles.buttons}>
                    <Button func={()=>{setModalShow({...modalShow, item: 0})}} content='I need help' size='small' styleB='light' />
                    <Button func={props.close} content='Done' size='small' styleB='orange' />
                </div>
            </div>
        ),
        
    };
    return (
        <div className={styles.ModalMessage}>
            {payments[props.item]}
        </div>
    )
}
