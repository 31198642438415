import * as React from "react";

function Icon(props) {
  return (
    <svg
      width={22}
      height={6}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M2.537.903a2 2 0 012 2v.408a2 2 0 01-2 2H2.13a2 2 0 01-2-2v-.408a2 2 0 012-2h.408zM11.003.903a2 2 0 012 2v.408a2 2 0 01-2 2h-.408a2 2 0 01-2-2v-.408a2 2 0 012-2h.408zM19.469.903a2 2 0 012 2v.408a2 2 0 01-2 2h-.409a2 2 0 01-2-2v-.408a2 2 0 012-2h.409z"
        fill="#0C1525"
      />
    </svg>
  );
}

export default Icon;