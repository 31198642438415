import React, { useState, useEffect } from 'react';
import { themeChange } from 'theme-change';
import styles from './Theme.module.css';
import Moon from '../../../images/Moon.js';
import Sun from '../../../images/Sun.js';

export const Theme = () => {
  let moon = <Moon viewBox="0 0 18 18" />;
  let sun = <Sun viewBox="0 0 18 18" />;
  const [icon, setIcon] = useState(localStorage.getItem('theme')?(localStorage.getItem('theme')==='light'?moon:sun):moon);
  function iconChange(){
    if(localStorage.getItem('theme') === 'light'){
      setIcon(moon);
    }
    else if(localStorage.getItem('theme') === 'dark'){
      setIcon(sun);
    }
  }
  useEffect(() => {
    themeChange(false);
  }, []);
  return (
    <div onClick={iconChange} id='theme' className={styles.Theme} data-toggle-theme="dark,light">
        {icon}
    </div>
  )
}
