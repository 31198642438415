import React, { useState, useEffect } from 'react';
import styles from './TypeInfo.module.css';
import { Switch } from 'antd';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';

export const Serials = (props) => {
    const register = props.register;
    const [custom, setCustom] = [props.custom, props.setCustom];
    const [delimiter, setDelimiter] = [props.delimiter, props.setDelimiter];
    const [rD, setRD] = [props.rD, props.setRD];
    const serials = register("asSerials.serials");
    return (
        <div className={styles.Serials}>
            <h3 className={styles.h3}>Serials list</h3>
            <div className={styles.textarea_wrapper}>
                {/* onChange={(e)=>{serialsChange(e)}}  */}
                <textarea {...serials} onChange={(e) => {serials.onChange(e)}} maxLength="64" className={styles.input} />
            </div>
            <h3 className={styles.h3}>Stock delimiter</h3>
            <div className={styles.delimiter}>
                <div className={styles.select}>
                    <select defaultValue={delimiter} onChange={(e)=>{setDelimiter(e.target.value)}}>
                        <option value='comma'>Comma</option>
                        <option value='newLine'>New Line</option>
                        <option value='custom'>Custom</option>
                    </select>
                </div>
                <Switch defaultChecked onChange={()=>{setRD(!rD)}} />
                <span>Remove duplicate serials</span>
            </div>
            <div className={`${styles.custom} ${delimiter==='custom'?styles.show:''}`}>
                    <h3 className={styles.h3}>Custom Stock Delimiter</h3>
                    {/* {...register('asSerials.delimiter', {value: ''})} */}
                    <input type='text' value={custom} onChange={e=>{setCustom(e.target.value)}} />
                </div>
            <div className={styles.quantity}>
                <div className={styles.quantityType}>
                    <h3 className={styles.h3}>Minimum quantity</h3>
                    <input {...register('asSerials.minAmount', { valueAsNumber: true, })} type='number' defaultValue={0} placeholder='Minimum quantity' step="1" min="0" />
                </div>
                <div className={styles.quantityType}>
                    <h3 className={styles.h3}>Maximum quantity</h3>
                    <input {...register('asSerials.maxAmount', { valueAsNumber: true, })} type='number' defaultValue={0} placeholder='Maximum quantity' step="1" min="0" />
                </div>
            </div>
        </div>
    )
}
export const Service = (props) => {
    const register = props.register;
    const isEdit = props.isEdit;
    const [isStock, setIsStock] = useState(isEdit?isEdit:false);
    return (
        <div className={styles.Service}>
            <h3 className={styles.h3}>Service info</h3>
            <div className={styles.textarea_wrapper}>
                <textarea {...register('asService.text', {})} />
            </div>
            <div className={styles.amount}>
                <div className={styles.amountSwitch}>
                    <Switch onChange={()=>{setIsStock(!isStock)}} />
                    <span>Set how many times this service can be sold</span>
                </div>
                <div className={`${styles.amountInput} ${isStock?styles.show:''}`}>
                    <h3 className={styles.h3}>Stock amount<small> (leave -1 for unlimited times)</small></h3>
                    <input type='number' {...register('asService.stock', { valueAsNumber: true, value: -1})}  placeholder='0' step="1" min="-1" />
                </div>
            </div>
            <div className={styles.quantity}>
                <div className={styles.quantityType}>
                    <h3 className={styles.h3}>Minimum quantity</h3>
                    <input {...register('asService.minAmount', { valueAsNumber: true})} type='number' defaultValue={0} placeholder='Minimum quantity' step="1" min="0" />
                </div>
                <div className={styles.quantityType}>
                    <h3 className={styles.h3}>Maximum quantity</h3>
                    <input {...register('asService.maxAmount', { valueAsNumber: true})} type='number' defaultValue={0} placeholder='Maximum quantity' step="1" min="0" />
                </div>
            </div>
        </div>
    )
}
export const Dynamic = (props) => {
    const register = props.register;
    const isEdit = props.isEdit;
    const [isStock, setIsStock] = useState(isEdit?isEdit:false);
    return (
        <div className={styles.Dynamic}>
            <h3 className={styles.h3}>Dynamic webhook URL</h3>
            <input {...register('asDynamic.externalUrl')} type='text' placeholder='Webhook URL'/>
            <div className={styles.amount}>
                <div className={styles.amountSwitch}>
                    <Switch checked={isStock} onChange={()=>{setIsStock(!isStock)}} />
                    <span>Set how many times this service can be sold</span>
                </div>
                <div className={`${styles.amountInput} ${isStock?styles.show:''}`}>
                    <h3 className={styles.h3}>Stock amount<small> (leave -1 for unlimited times)</small></h3>
                    <input type='number' {...register('asDynamic.stock', { valueAsNumber: true, value: -1})}  placeholder='0' step="1" min="-1" />
                </div>
            </div>
            <div className={styles.quantity}>
                <div className={styles.quantityType}>
                    <h3 className={styles.h3}>Minimum quantity</h3>
                    <input {...register('asDynamic.minAmount', { valueAsNumber: true})} type='number' defaultValue={0} placeholder='Minimum quantity' step="1" min="0" />
                </div>
                <div className={styles.quantityType}>
                    <h3 className={styles.h3}>Maximum quantity</h3>
                    <input {...register('asDynamic.maxAmount', { valueAsNumber: true})} type='number' defaultValue={0} placeholder='Maximum quantity' step="1" min="0" />
                </div>
            </div>
        </div>
    )
}

export const TypeInfo = (props) => {
    const register = props.register;
    const getValues = props.getValues;
    const errors = props.errors;
    const watch = props.watch;
    const [custom, setCustom] = [props.custom, props.setCustom];
    const [delimiter, setDelimiter] = [props.delimiter, props.setDelimiter];
    const location = useLocation();
    const isEdit = location.pathname.match(/\/products\/(.*)\//)?location.pathname.match(/\/products\/(.*)\//)[1]==='edit':false;
    const [rD, setRD] = [props.rD, props.setRD];
    const [focus, setFocus] = useState();
    const types = [
        <Serials 
            register={register} 
            getValues={getValues} 
            custom={custom} 
            setCustom={setCustom} 
            delimiter={delimiter} 
            setDelimiter={setDelimiter}
            rD={rD} 
            setRD={setRD}
        />,
        <Service register={register} getValues={getValues} isEdit={isEdit} />,
        <Dynamic register={register} getValues={getValues} isEdit={isEdit} />
    ];
    useEffect(()=>{
        if(isEdit){
            switch (watch('type')){
                case 0:
                    setFocus(0);
                    break;
                case 3:
                    setFocus(1);
                    break;
                case 2:
                    setFocus(2);
                    break;
            }
            //setFocus(watch('type')?watch('type'):watch('type'));
            // eslint-disable-next-line
        }
    });
    return (
        <div className={styles.TypeInfo}>
            <div className={styles.types}>
                <h3 className={styles.h3}>Product type</h3>
                <div className={`${errors.type && 'error'} ${styles.buttons}`}>
                    <div className={styles.radio} onClick={()=>{setFocus(0)}}>
                        <input id='buttonType1' {...register('type', { required: true })} name='type' type="radio" checked={watch('type')=='0'?true:false}  value='0'/>
                        <label htmlFor="buttonType1">Serials</label>
                    </div>
                    <div className={styles.radio} onClick={()=>{setFocus(1)}}>
                        <input id='buttonType2' {...register('type', { required: true })} name='type' type="radio" checked={watch('type')=='3'?true:false} value='3'/>
                        <label htmlFor="buttonType2">Service</label>
                    </div>
                    <div className={styles.radio} onClick={()=>{setFocus(2)}}>
                        <input id='buttonType3' {...register('type', { required: true })} name='type' type="radio" checked={watch('type')=='2'?true:false} value='2'/>
                        <label htmlFor="buttonType3">Dynamic</label>
                    </div> 
                </div>
            </div>
            {errors.type && <p>Type is required</p>}
            {types[focus]}
        </div>
    )
}
