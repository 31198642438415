import * as React from "react";

function Icon(props) {
  return (
    <svg
      width={26}
      height={25}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect x={0.475} y={0.11} width={24.53} height={24.53} rx={8} fill="red" />
      <path
        d="M19.67 8.802a1.836 1.836 0 00-1.28-1.313c-1.127-.31-5.65-.31-5.65-.31s-4.522 0-5.65.31a1.836 1.836 0 00-1.278 1.313c-.302 1.158-.302 3.574-.302 3.574s0 2.416.302 3.574a1.836 1.836 0 001.279 1.313c1.127.31 5.65.31 5.65.31s4.522 0 5.65-.31a1.836 1.836 0 001.278-1.313c.302-1.158.302-3.574.302-3.574s0-2.416-.302-3.574z"
        fill="#fff"
      />
      <path d="M11.386 14.635v-4.519l3.615 2.26-3.615 2.26z" fill="red" />
    </svg>
  );
}

export default Icon;
