import React from 'react';
import styles from './ImageInput.module.css';
import Clip from '../../../../../images/Clip';

export const ImageInput = (props) => {
    const setImage = props.setImage;
    const handleChange = e => {
        const file = document.getElementById('file');
        const fileName_ = document.getElementById('fileName');
        file.innerText = '';
        fileName_.innerText = e.target.files[0].name;
        const formData = new FormData();
        formData.append('file', e.target.files[0]);
        setImage(formData);
    }
  return (
    <div className={styles.ImageInput}>
        <h3 className={styles.h3}>Image<small> (optional)</small></h3>
        <div className={styles.input_file}>
            <label className={styles.content}>
                <div id='file' className={styles.clip}>
                    <Clip viewBox='0 0 22 34' />
                    <span>Only accepts png, jpg, or gif</span>
                </div>
                <span className={styles.name} id='fileName'></span>
                <input onChange={handleChange} type="file" name="image" />
            </label>
        </div>
    </div>
  )
}
