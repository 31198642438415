import React, { useEffect, useState } from 'react';
import styles from './Coupons.module.css';
import { Heading } from '../../../Heading/Heading';
import Close from '../../../../../images/Close';
import { SearchInput } from '../../../../FunctionComponents/Search/Search';
import { ButtonLink } from '../../../../FunctionComponents/ButtonLink/ButtonLink';
import { Button } from '../../../../FunctionComponents/Button/Button';
import { Modal } from '../../../../FunctionComponents/Modal/Modal';
import { Route, useRouteMatch, Switch } from 'react-router-dom';
import { NoMatch } from '../../../../../App';
import { CouponsList } from './CouponsList/CouponsList';
import { CouponsEdit } from './CouponsEdit/CouponsEdit';
import { Pagination } from '../../../../FunctionComponents/Pagination/Pagination';
import RequestService from '../../../../../api/post';
import { useDispatch, useSelector } from 'react-redux';

export const ModalMessage = (props) =>{
    console.log(props.items)
    const message = useSelector(state => state.message);
    const dispatch = useDispatch();
    async function deleteGroup(){
        const response = await RequestService.CouponDelete(JSON.parse(localStorage.getItem('shop')).id, props.items.id);
        if(response){
            dispatch({
                type: "SET_MESSAGE", 
                payload: {isShow: true, status: true, text: 'Coupon deleted successfully'}
            });
        }
        else{
            dispatch({
                type: "SET_MESSAGE", 
                payload: {isShow: !message.isShow}
            });
        }
        props.close();
    }
    return (
        <div className={styles.ModalMessage}>
            <div className={styles.ModalMessage_content}>
                <h3>Delete coupon</h3>
                <span>You are about to delete <b>{props.items.name}</b>. Are you sure you want to delete this coupon?</span>
                <div className={styles.buttons}>
                    <Button func={props.close} content='Cancel' size='small' styleB='light' />
                    <Button func={()=>{deleteGroup()}} content='Delete coupon' size='small' styleB='orange' />
                </div>
            </div>
            <Close onClick={props.close} viewBox='0 0 10 15' />
        </div>
    )
}
export const Coupons = (props) => {
    const message = useSelector(state => state.message);
    const dispatch = useDispatch();
    const [pageSize, setPageSize] = useState(10);
    const [current, setCurrent] = useState(1);
    const [minIndex, setMinIndex] = useState(0);
    const [maxIndex, setMaxIndex] = useState(pageSize);
    const [items, setItems] = useState([]);
    const[modalShow, setModalShow] = useState({
        isShow: false,
        item: 0
    });
    async function getCoupons(){
        const response = localStorage.getItem('shop')!=='undefined'?await RequestService.CouponsGet(JSON.parse(localStorage.getItem('shop')).id):'';
        try{
            if(!response.data.data){
                dispatch({
                    type: "SET_MESSAGE", 
                    payload: {isShow: !message.isShow}
                });
            }else{
                setItems(response.data.data?response.data.data.reverse():{});
            }
        }
        catch{
            console.log('error')
        }
    }
    const {url, path} = useRouteMatch();
    useEffect(()=>{
        getCoupons();
    }, [message]);
    return (
        <div className={styles.Coupons}>
            <Switch>
                <Route exact path={`${path}`}>
                    {modalShow.isShow?<Modal onClick={()=>{setModalShow({...modalShow, isShow: false})}} component={
                    <ModalMessage items={items[modalShow.item]} modalShow={modalShow} close={()=>{setModalShow({...modalShow, isShow: false})}} />}/>:''}
                    <div className={styles.header}>
                        <Heading title={props.title} link='/products/coupons'/>
                        <div className={styles.func}>
                            <div className={styles.search}>
                                <SearchInput placeholder='Search for a coupon...' func={(e)=>{console.log(e)}} />
                            </div>
                            <ButtonLink styleB='orange' size='large' to={`${url}/new`} content='+ Create coupon' />
                        </div>
                    </div>
                    <CouponsList delete={(i)=>{setModalShow({isShow: true, item: i})}} items={items} />
                    <Pagination
                        setCurrent={setCurrent}
                        maxIndex={maxIndex}
                        minIndex={minIndex}
                        setMaxIndex={setMaxIndex}
                        setMinIndex={setMinIndex}
                        setPageSize={setPageSize}
                        content={items}
                        current={current}
                        pageSize={pageSize}
                        />
                </Route>
                <Route path={`${path}/edit/:id`}>
                    <CouponsEdit title={props.title}/>
                </Route>
                <Route path={`${path}/new`}>
                    <CouponsEdit title={props.title}/>
                </Route>
                <Route path='*'>
                    <NoMatch />
                </Route>
            </Switch>
        </div>
  )
}
