import * as React from "react";

function Icon(props) {
  return (
    <svg
      width={15}
      height={17}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M7.16 16.982a2 2 0 002-2h-4a2 2 0 002 2zm0-14.082l-.797.161A4.002 4.002 0 003.16 6.982c0 .628-.134 2.197-.459 3.742-.16.767-.376 1.566-.663 2.258h10.244c-.287-.692-.502-1.49-.663-2.258-.325-1.545-.459-3.114-.459-3.742a4.003 4.003 0 00-3.203-3.92L7.16 2.9V2.9zm6.22 10.082c.223.447.481.801.78 1h-14c.3-.199.557-.553.78-1 .9-1.8 1.22-5.12 1.22-6 0-2.42 1.72-4.44 4.005-4.9a1 1 0 111.99 0 5.002 5.002 0 014.005 4.9c0 .88.32 4.2 1.22 6z"
        fill="#0C1525"
      />
    </svg>
  );
}

export default Icon;