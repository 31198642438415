import axios from 'axios';
import Cookies from 'universal-cookie';

const cookies = new Cookies();
if(!localStorage.getItem('data')||localStorage.getItem('data')==='undefined'){
  if(!cookies.get('data')){
    window.location = "http://auth.sellpass.io/";
    //window.location = "http://localhost:3001";
  }else{
    localStorage.setItem('data', JSON.stringify(cookies.get('data')));
    cookies.remove('data')
  }
}
const instance = axios.create({
    baseURL: process.env.REACT_APP_API_HOST
});

let isRefreshing = false;
let failedQueue = [];

const processQueue = (error, tokens = null) => {
  failedQueue.forEach(prom => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve(tokens);
    }
  })
  
  failedQueue = [];
}

instance.interceptors.request.use(
  (config) => {
    const token = JSON.parse(localStorage.getItem('data')).accessToken;
    if (token) {
      config.headers["Authorization"] = 'Bearer ' + token;
    }
    return config;
  }
);


instance.interceptors.response.use(
  (res) => {
    return res;
  },
  async (error) => {
  const originalRequest = error.config;

  if (error.response.status === 401 && !originalRequest._retry) {
      
      if (isRefreshing) {
        return new Promise(function(resolve, reject) {
          failedQueue.push({resolve, reject})
        }).then(token => {
          originalRequest.headers['Authorization'] = 'Bearer ' + token;
          return axios(originalRequest);
        }).catch(err => {
          return Promise.reject(err);
        })
      }

    originalRequest._retry = true;
    isRefreshing = true;

    const accessToken = localStorage.getItem('data')?JSON.parse(localStorage.getItem('data')).accessToken:'';
    const refreshToken = localStorage.getItem('data')?JSON.parse(localStorage.getItem('data')).refreshToken:'';
    return new Promise(function (resolve, reject) {
      instance.post("auth/token/refresh/", { accessToken: accessToken, refreshToken: refreshToken })
        .then(({data}) => {
            localStorage.setItem('data', JSON.stringify(data.data));
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + data.data.accessToken;
            originalRequest.headers['Authorization'] = 'Bearer ' + data.data.accessToken;
            processQueue(null, data);
            resolve(axios(originalRequest));
        })
        .catch((err) => {
            processQueue(err, null);
            reject(err);
            window.location = "http://auth.sellpass.io/";
            localStorage.removeItem('data');
            //localStorage.removeItem('shop');
            //console.log('bruh');
            //window.location = "http://localhost:3001";
        })
        .finally(() => {
          isRefreshing = false;
        })
    })
  }

  return Promise.reject(error);
});
export default class RequestService {
    //get
    static async DashboardGet(){
        const response = await instance.get('/self/dashboard/general')
        .catch(err => {
          console.log(err); 
        });
        return response.data
    }
    static async ShopsGet(){
        const response = await instance.get('/self/shops');
        return response
    }
    static async ProductsGet(shopId){
        const response = await instance.get('/self/'+shopId+'/products').catch(err => {
          console.log('aasd'); 
        });
        return response
    }
    static async InvoicesGet(shopId){
        const response = await instance.get('/self/'+shopId+'/invoices').catch(err => {
          console.log(err); 
        });
        return response
    }
    static async InvoiceGet(shopId, invoiceId){
        const response = await instance.get('/self/'+shopId+'/invoices/'+invoiceId).catch(err => {
          console.log(err); 
        });
        return response
    }
    static async TicketsGet(shopId){
        const response = await instance.get('/self/'+shopId+'/tickets').catch(err => {
          console.log(err); 
        });
        return response
    }
    static async TicketGet(shopId, ticketId){
        const response = await instance.get('/self/'+shopId+'/tickets/'+ticketId).catch(err => {
          console.log(err); 
        });
        return response
    }
    static async ReviewsGet(shopId){
        const response = await instance.get('/self/'+shopId+'/feedbacks').catch(err => {
          console.log(err); 
        });
        return response
    }
    static async ReviewGet(shopId, reviewId){
        const response = await instance.get('/self/'+shopId+'/feedbacks/'+reviewId).catch(err => {
          console.log(err); 
        });
        return response
    }
    static async ProductGet(shopId, productId){
        const response = await instance.get('/self/'+shopId+'/products/'+productId)
        return response
    }
    static async CouponsGet(shopId){
        const response = await instance.get('/self/'+shopId+'/coupons/')
        return response
    }
    static async CouponGet(shopId, couponId){
        const response = await instance.get('/self/'+shopId+'/coupons/'+couponId)
        return response
    }
    static async SettingsGet(shopId){
        const response = await instance.get('/self/'+shopId+'/settings')
        return response
    }
    static async NotificationsGet(shopId){
        const response = await instance.get('/self/'+shopId+'/notifications')
        return response
    }
    static async GroupsGet(shopId){
        const response = await instance.get('/self/'+shopId+'/groups')
        return response
    }
    static async GroupGet(shopId, groupId){
        const response = await instance.get('/self/'+shopId+'/groups/'+groupId)
        return response
    }
    static async BlacklistGet(shopId){
        const response = await instance.get('/self/'+shopId+'/blacklist');
        return response
    }
    static async BlacklistItemGet(shopId, blacklistId){
        const response = await instance.get('/self/'+shopId+'/blacklist/'+blacklistId);
        return response
    }
    //post
    static async ProductNew(shopId, data){
        const response = await instance.post('/self/'+shopId+'/products', data);
        return response
    }
    static async BlacklistNew(shopId, data){
        const response = await instance.post('/self/'+shopId+'/blacklist', data);
        return response
    }
    static async NotificationsRead(shopId, notificationId){
        const response = await instance.post('/self/'+shopId+'/notifications/'+notificationId+'/read');
        return response
    }
    static async CouponNew(shopId, data){
        const response = await instance.post('/self/'+shopId+'/coupons/', data);
        return response
    }
    static async GroupNew(shopId, data){
      let answer = false;
        await instance.post('/self/'+shopId+'/groups', data)
        .then(data=>{
          answer = data 
        })
        .catch(err => {
          console.log("Error");
          answer = false 
        });
        return answer
    }
    static async PasswordNew(passwordPair){
        const response = await instance.post('/self/user/password/change', passwordPair);
        return response
    }
    static async TicketPost(shopId, ticketId, data){
        const response = await instance.post('/self/'+shopId+'/tickets/'+ticketId+'/message', data);
        return response
    }
    static async ReplyPost(shopId, replyId, data){
        const response = await instance.post('/self/'+shopId+'/feedbacks/'+replyId+'/answer', data);
        return response
    }
    //put
    static async GeneralSettings(shopId, field, data){
        const response = await instance.put('/self/'+shopId+'/settings/'+field, data);
        return response
    }
    static async DesignSettings(shopId, data){
        const response = await instance.put('/self/'+shopId+'/settings/design', data);
        return response
    }
    static async BlacklistItemPut(shopId, blacklistId, data){
        const response = await instance.put('/self/'+shopId+'/blacklist/'+blacklistId, data);
        return response
    }
    static async PaymentsShopSettingsCommerce(shopId, data){
        const response = await instance.put('/self/'+shopId+'/settings/payments/coinbasecommerce', data);
        return response
    }
    static async PaymentsShopSettingsPayPal(shopId, data){
        const response = await instance.put('/self/'+shopId+'/settings/payments/paypalemail', data);
        return response
    }
    static async PaymentsShopSettingsStripe(shopId, data){
        const response = await instance.put('/self/'+shopId+'/settings/payments/stripeconnect', data);
        return response
    }
    static async ShopsLogo(shopId, data){
        const response = await instance.put('/self/'+shopId+'/settings/design/logo', data);
        return response
    }
    static async ShopsTerms(shopId, data){
        const response = await instance.put('/self/'+shopId+'/settings/terms', data);
        return response
    }
    static async ShopsLinks(shopId, data){
        const response = await instance.put('/self/'+shopId+'/settings/design/links', data);
        return response
    }
    static async ProductEdit(shopId, productId, data){
        const response = await instance.put('/self/'+shopId+'/products/'+productId, data)
        return response
    }
    static async GroupEdit(shopId, groupId, data){
        const response = await instance.put('/self/'+shopId+'/groups/'+groupId, data)
        return response
    }
    static async ProductImage(shopId, productId, data){
        const response = await instance.put('/self/'+shopId+'/products/'+productId+'/thumbnail', data);
        return response
    }
    //delete
    static async ProductDelete(shopId, productId){
        const response = await instance.delete('/self/'+shopId+'/products/'+productId)
        return response
    }
    static async BlacklistItemDelete(shopId, blacklistId){
        const response = await instance.delete('/self/'+shopId+'/blacklist/'+blacklistId);
        return response
    }
    static async NotificationsDelete(shopId){
        const response = await instance.delete('/self/'+shopId+'/notifications')
        return response
    }
    static async GroupDelete(shopId, groupId){
        const response = await instance.delete('/self/'+shopId+'/groups/'+groupId)
        return response
    }
    static async CouponDelete(shopId, couponId){
        const response = await instance.delete('/self/'+shopId+'/coupons/'+couponId)
        return response
    }
    static async BlacklistItemGet(shopId, blacklistId){
        const response = await instance.get('/self/'+shopId+'/blacklist/'+blacklistId);
        return response
    }
}