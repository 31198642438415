import React from 'react';
import styles from './AdditInfo.module.css';
import { Checkbox } from 'antd';
import RoundInfo from '../../../../../images/RoundInfo';
import ReactMde from "react-mde";
import * as Showdown from "showdown";
import "react-mde/lib/styles/css/react-mde-all.css";
import "../MainInfo/mde-styles.css";
import { useEffect } from 'react';

function loadSuggestions(text) {
    return new Promise((accept, reject) => {
      setTimeout(() => {
        const suggestions = [].filter((i) => i.preview.toLowerCase().includes(text.toLowerCase()));
        accept(suggestions);
      }, 250);
    });
}
const converter = new Showdown.Converter({
    tables: true,
    simplifiedAutoLink: true,
    strikethrough: true,
    tasklists: true
});

export const AdditInfo = (props) => {
  const register = props.register;
  const control = props.control;
  const Controller = props.Controller;
  const [value, setValue] = React.useState("");
  const watch = props.watch;
  const [selectedTab, setSelectedTab] = React.useState("write");
  return (
    <div className={styles.AdditInfo}>
        <h3 className={styles.h3}>Additional info</h3>
        <div className={styles.checkboxes}>
            <Controller
                name="unlisted"
                control={control}
                defaultValue={false}
                render={({field: { onChange, value, defaultValue }}) => 
                <Checkbox defaultChecked={defaultValue} checked={value} onChange={onChange}>
                  <span className={styles.span}>Unlisted<span data-tooltip="Cannot be found, accessed, or purchased by anyone without a direct link." data-tooltip-position="top"><RoundInfo viewBox='0 0 11 11' /></span></span>
                </Checkbox>}
            />
            <Controller
                name="private"
                control={control}
                defaultValue={false}
                render={({field: { onChange, value, defaultValue }}) => 
                <Checkbox defaultChecked={defaultValue} checked={value} onChange={onChange}>
                  <span className={styles.span}>Private<span data-tooltip="Cannot be found, accessed, or purchased by anyone." data-tooltip-position="top"><RoundInfo viewBox='0 0 11 11' /></span></span>
                </Checkbox>}
            />
            <Controller
                name="onHold"
                control={control}
                defaultValue={false}
                render={({field: { onChange, value, defaultValue }}) => 
                <Checkbox defaultChecked={defaultValue} checked={value} onChange={onChange}>
                  <span className={styles.span}>On Hold<span data-tooltip="Customers won't be able to purchase your product, but it will be visible." data-tooltip-position="top"><RoundInfo viewBox='0 0 11 11' /></span></span>
                </Checkbox>}
            />
            <Controller
                name="terms"
                control={control}
                defaultValue={false}
                render={({field: { onChange, value, defaultValue }}) => 
                <Checkbox defaultChecked={defaultValue} checked={value} onChange={onChange}>
                  <span className={styles.spanTerms}>Terms of Service</span>
                </Checkbox>}
            />
        </div>
        <div className={`${watch('terms')?styles.editor:styles.editorHide}`}>
            <ReactMde
                value={value}
                onChange={setValue}
                selectedTab={selectedTab}
                onTabChange={setSelectedTab}
                generateMarkdownPreview={(markdown) =>
                    Promise.resolve(converter.makeHtml(markdown))
                }
                loadSuggestions={loadSuggestions}
                childProps={{
                    writeButton: {
                        tabIndex: -1
                    }
                }}
            />
        </div>
        <h3 className={styles.h3}>Warranty<span data-tooltip="Set a warranty for your products. Customers won't be able to leave a negative review if they don't contact you within the limit." data-tooltip-position="top"><RoundInfo viewBox='0 0 11 11' /></span></h3>
        <div className={styles.select_container}>
          <div className={styles.select}>
              <select>
                  <option value='0'>No warranty</option>
                  <option value='-1'>Life Time</option>
                  <option value='900'>15 minutes</option>
                  <option value='3600'>1 hour</option>
                  <option value='7200'>2 hours</option>
                  <option value='43200'>12 hours</option>
                  <option value='86400'>24 hours</option>
                  <option value='604800'>7 days</option>
                  <option value='1209600'>14 days</option>
                  <option value='2592000'>30 days</option>
                  <option value='31536000'>365 days</option>
                  <option value='1'>Input manually</option>
              </select>
          </div>
        </div>
        <div className={styles.title}>
          <h3 className={styles.h3}>Note to customer</h3>
          <small>&nbsp;(optional)</small>
          <span data-tooltip="Set a custom note for your customers to receive after their purchase." data-tooltip-position="top"><RoundInfo viewBox='0 0 11 11' /></span>
        </div>
        <div className={styles.textarea_wrapper}>
            <textarea {...register('customerNote')} placeholder='Thanks for your purchase! Here’s a 20% off coupon for your next purchase!' />
        </div>
    </div>
  )
}
