import * as React from "react";

function Icon(props) {
  return (
    <svg
      width={18}
      height={18}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M15.015 1.474a1.072 1.072 0 011.073 1.073v12.87a1.072 1.072 0 01-1.073 1.072H2.145a1.072 1.072 0 01-1.073-1.072V2.547a1.073 1.073 0 011.073-1.073h12.87zM2.145.402A2.145 2.145 0 000 2.547v12.87a2.145 2.145 0 002.145 2.145h12.87a2.145 2.145 0 002.145-2.145V2.547A2.145 2.145 0 0015.015.402H2.145z"
        fill="#0C1525"
      />
      <path
        d="M9.577 7.467l-2.456.308-.088.408.483.089c.315.075.378.188.309.503l-.792 3.72c-.208.961.113 1.414.867 1.414.585 0 1.263-.27 1.571-.642l.095-.446c-.215.189-.528.264-.736.264-.295 0-.402-.207-.326-.572l1.073-5.046zm.076-2.24a1.073 1.073 0 11-2.145 0 1.073 1.073 0 012.145 0z"
        fill="#0C1525"
      />
    </svg>
  );
}

export default Icon;