import * as React from "react";

function Icon(props) {
  return (
    <svg
      width={17}
      height={16}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M3.857 15.345c-.386.198-.824-.15-.746-.592l.83-4.73L.418 6.667C.088 6.353.26 5.78.7 5.717l4.898-.696L7.783.694c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.44.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592l-4.391-2.256-4.39 2.256h.002z"
        fill="#0C1525"
      />
    </svg>
  );
}

export default Icon;