import React from 'react';
import styles from './PayMethods.module.css';
import { Checkbox } from 'antd';
import Stripe from '../../../../../images/Stripe';
import Commerce from '../../../../../images/Commerce';
import PayPal from '../../../../../images/PayPal';
import CashApp from '../../../../../images/CashApp';
import {uid} from 'react-uid';

function onChange(e) {
    console.log(`checked = ${e.target.checked}`);
}
export const PayMethodList = (props) => {
    const control = props.control;
    const Controller = props.Controller;
    const list = Object.keys(props.methods).map((item, i) => {
        return(
            <div key={uid(item)} className={styles.PayMethod__item}>
                <Controller
                    name={'gatewaysList.'+i}
                    control={control}
                    defaultValue={false}
                    render={({field: { onChange, value, defaultValue }}) => 
                    <Checkbox defaultChecked={defaultValue} checked={value} onChange={onChange}>
                        <span className={styles.span}>{props.methods[item].name}</span>
                    </Checkbox>}
                />
                {props.methods[item].img}
            </div>
        )
    });
    return (
        <div className={styles.PayMethod}>
            {list}
        </div>
    )
}

export const PayMethods = (props) => {
    const control = props.control;
    const Controller = props.Controller;
    const methods = {
        commerce: {
            name: 'Commerce',
            img: <Commerce viewBox='0 0 23 23' />
        },
        stipe: {
            name: 'Stripe',
            img: <Stripe viewBox='0 0 25 25' />
        },
        paypal: {
            name: 'PayPal',
            img: <PayPal viewBox='0 0 18 21' />
        },
        cashapp: {
            name: 'CashApp',
            img: <CashApp viewBox='0 0 26 26' />
        },
    }
  return (
    <div className={styles.PayMethods}>
        <h3 className={styles.h3}>Payment methods</h3>
        <PayMethodList methods={methods} control={control} Controller={Controller} />
    </div>
  )
}
