import * as React from "react";

function Icon(props) {
  return (
    <svg
      width={17}
      height={17}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M12.327.743a.501.501 0 01.708 0l3 3a.5.5 0 010 .708l-10 10a.5.5 0 01-.168.11l-5 2a.5.5 0 01-.65-.65l2-5a.5.5 0 01.11-.168l10-10zm-.939 2.354l2.293 2.293 1.293-1.293-2.293-2.293-1.293 1.293zm1.586 3l-2.293-2.293-6.5 6.5v.293h.5a.5.5 0 01.5.5v.5h.5a.5.5 0 01.5.5v.5h.293l6.5-6.5zm-9.76 5.175l-.107.106-1.528 3.82L5.4 13.672l.106-.106a.5.5 0 01-.325-.468v-.5h-.5a.5.5 0 01-.5-.5v-.5h-.5a.5.5 0 01-.468-.325z"
        fill="#0C1525"
      />
    </svg>
  );
}

export default Icon;