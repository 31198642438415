import React, { useEffect } from 'react';
import { useRouteMatch, Redirect, Link, Switch, Route, useLocation, useHistory } from 'react-router-dom';
import { Heading } from '../Heading/Heading';
import ArrowRight from '../../../images/ArrowRight';
import styles from './Settings.module.css';
import { General } from './General/General';
import { Branding } from './Branding/Branding';
import { Billing } from './Billing/Billing';
import { Security } from './Security/Security';
import { Team } from './Team/Team';
import { Notifications } from './Notifications/Notifications';
import { Payments } from './Payments/Payments';
import { useDispatch, useSelector } from 'react-redux';
import RequestService from '../../../api/post';

export const Settings = (props) => {
    const state = useSelector(state => state);
    const message = useSelector(state => state.message);
    const dispatch = useDispatch();
    const {path} = useRouteMatch();
    const location = useLocation();
    const subsection = location.pathname.match(/.*(\/.*)/)[1];
    async function getSettings(){
        const response = await RequestService.SettingsGet(JSON.parse(localStorage.getItem('shop')).id);
        localStorage.setItem('settings', JSON.stringify(response.data.data));
        dispatch({
            type: "SET_SETTINGS", 
            payload: response.data.data
        });
    }

    const history = useHistory();
    function handleChange(value) {
        history.push(`${value}`);
        value = "";
    }
    useEffect(()=>{
        getSettings();
    // eslint-disable-next-line 
    }, []);
  return (
    <div className={styles.Settings}>
        <div className={styles.header}>
            <Heading title={props.title} link='/settings/general'/>
        </div>
        <div className={styles.select_navBar}>
            <select value={subsection.replace('/', '')} onChange={e => handleChange(e.target.value)}>
                <optgroup label="SHOP">
                    <option value='general'>General</option>
                    <option value='branding'>Branding</option>
                    <option value='payments'>Payments</option>
                    <option value='team'>Team</option>
                    <option value='notifications'>Notifications</option>
                </optgroup>
                <optgroup label="ACCOUNT">
                    <option value='security'>Security</option>
                    <option value='billing'>Billing</option>
                </optgroup>
            </select>
        </div>
        <div className={styles.wrapper}>
            <div className={styles.navBar}>
                <h2>SHOP</h2>
                <Link to={`${path}/general`} className={`${styles.item} ${subsection==='/general'?styles.focus:''}`}>
                    <h3>General</h3>
                    <ArrowRight viewBox='0 0 12 13' />
                </Link>
                <Link to={`${path}/branding`} className={`${styles.item} ${subsection==='/branding'?styles.focus:''}`}>
                    <h3>Branding</h3>
                    <ArrowRight viewBox='0 0 12 13' />
                </Link>
                <Link to={`${path}/payments`} className={`${styles.item} ${subsection==='/payments'?styles.focus:''}`}>
                    <h3>Payments</h3>
                    <ArrowRight viewBox='0 0 12 13' />
                </Link>
                <Link to={`${path}/team`} className={`${styles.item} ${subsection==='/team'?styles.focus:''}`}>
                    <h3>Team</h3>
                    <ArrowRight viewBox='0 0 12 13' />
                </Link>
                <Link to={`${path}/notifications`} className={`${styles.item} ${subsection==='/notifications'?styles.focus:''}`}>
                    <h3>Notifications</h3>
                    <ArrowRight viewBox='0 0 12 13' />
                </Link>
                <h2>ACCOUNT</h2>
                <Link to={`${path}/security`} className={`${styles.item} ${subsection==='/security'?styles.focus:''}`}>
                    <h3>Security</h3>
                    <ArrowRight viewBox='0 0 12 13' />
                </Link>
                <Link to={`${path}/billing`} className={`${styles.item} ${subsection==='/billing'?styles.focus:''}`}>
                    <h3>Billing</h3>
                    <ArrowRight viewBox='0 0 12 13' />
                </Link>
            </div>
            <div className={styles.content}>
                <Switch>
                    <Route exact path={`${path}/general`}>
                        <General />
                    </Route>
                    <Route exact path={`${path}/branding`}>
                        <Branding />
                    </Route>
                    <Route exact path={`${path}/payments`}>
                        <Payments />
                    </Route>
                    <Route exact path={`${path}/team`}>
                        <Team />
                    </Route>
                    <Route exact path={`${path}/notifications`}>
                        <Notifications />
                    </Route>
                    <Route exact path={`${path}/security`}>
                        <Security />
                    </Route>
                    <Route exact path={`${path}/billing`}>
                        <Billing />
                    </Route>
                    <Route path='*'>
                        <Redirect to={`${path}/general`} />
                    </Route>
                </Switch>
            </div>
        </div>
    </div>
  )
}
