import * as React from "react";

function Icon(props) {
  return (
    <svg
      width={17}
      height={17}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M8.203 4.83a3.246 3.246 0 100 6.492 3.246 3.246 0 000-6.493zM5.957 8.074a2.246 2.246 0 114.492 0 2.246 2.246 0 01-4.492 0z"
        fill="#000"
      />
      <path
        d="M9.999 1.418c-.527-1.79-3.065-1.79-3.592 0l-.094.32a.873.873 0 01-1.255.52l-.292-.16c-1.64-.893-3.433.901-2.54 2.54l.159.292a.873.873 0 01-.52 1.255l-.32.094c-1.79.527-1.79 3.065 0 3.592l.32.094a.872.872 0 01.52 1.255l-.16.292c-.892 1.64.9 3.434 2.54 2.54l.293-.159a.873.873 0 011.255.52l.094.32c.527 1.79 3.065 1.79 3.592 0l.094-.32a.872.872 0 011.255-.52l.292.16c1.64.893 3.434-.902 2.54-2.54l-.16-.293a.873.873 0 01.52-1.255l.32-.094c1.79-.527 1.79-3.065 0-3.592l-.32-.094a.873.873 0 01-.52-1.255l.16-.292c.894-1.64-.901-3.433-2.54-2.54l-.292.16a.873.873 0 01-1.255-.52l-.094-.32zm-2.633.283c.246-.835 1.428-.835 1.674 0l.094.32a1.873 1.873 0 002.693 1.114l.29-.16c.765-.415 1.6.42 1.185 1.185l-.16.292a1.874 1.874 0 001.117 2.692l.318.094c.835.246.835 1.428 0 1.674l-.32.094a1.873 1.873 0 00-1.114 2.693l.16.291c.415.764-.42 1.6-1.185 1.184l-.291-.159a1.873 1.873 0 00-2.693 1.116l-.094.318c-.246.835-1.428.835-1.674 0l-.094-.319a1.873 1.873 0 00-2.692-1.115l-.292.16c-.764.415-1.6-.42-1.184-1.185l.159-.29a1.872 1.872 0 00-1.115-2.695l-.32-.094c-.834-.246-.834-1.428 0-1.674l.32-.094a1.873 1.873 0 001.115-2.69l-.16-.293c-.415-.764.42-1.6 1.185-1.184l.292.16A1.873 1.873 0 007.272 2.02l.094-.319z"
        fill="#000"
      />
    </svg>
  );
}

export default Icon;