import * as React from "react";

function Icon(props) {
  return (
    <svg
      width={26}
      height={25}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect
        x={0.475}
        y={0.11}
        width={24.53}
        height={24.53}
        rx={8}
        fill="#C837AB"
      />
      <path
        d="M12.739 5.883c-1.763 0-1.985.008-2.677.04-.691.03-1.163.14-1.576.3-.427.167-.789.389-1.15.75-.361.36-.583.723-.75 1.15-.16.412-.27.884-.3 1.575-.032.693-.04.914-.04 2.677s.008 1.984.04 2.677c.031.69.14 1.162.3 1.575.167.427.389.79.75 1.15.36.361.723.584 1.15.75.412.16.884.27 1.575.301.693.032.914.04 2.677.04s1.984-.008 2.676-.04c.692-.031 1.164-.14 1.577-.301a3.176 3.176 0 001.15-.75c.36-.36.582-.723.749-1.15.16-.413.269-.884.301-1.575.031-.693.04-.914.04-2.677s-.009-1.984-.04-2.677c-.032-.69-.142-1.163-.301-1.575a3.187 3.187 0 00-.75-1.15 3.173 3.173 0 00-1.15-.75c-.413-.16-.885-.27-1.576-.3-.693-.032-.913-.04-2.677-.04h.002zm-.583 1.17h.583c1.733 0 1.939.006 2.623.037.633.03.977.135 1.206.224.303.118.519.258.746.485.227.228.367.444.485.747.09.229.195.572.224 1.205.031.685.038.89.038 2.623 0 1.732-.007 1.938-.038 2.622-.029.633-.135.977-.224 1.205a2.008 2.008 0 01-.485.746 2.008 2.008 0 01-.746.486c-.229.09-.573.195-1.206.224-.684.03-.89.037-2.623.037-1.734 0-1.94-.006-2.624-.037-.633-.03-.976-.136-1.205-.224a2.012 2.012 0 01-.747-.486 2.012 2.012 0 01-.486-.746c-.088-.229-.194-.572-.223-1.205-.031-.685-.037-.89-.037-2.624s.006-1.938.037-2.622c.029-.633.135-.977.223-1.206.118-.303.26-.52.486-.746.228-.228.444-.368.747-.486.229-.09.572-.195 1.205-.224.6-.027.831-.035 2.041-.037v.002zm4.048 1.078a.779.779 0 100 1.558.779.779 0 000-1.558zm-3.465.91a3.334 3.334 0 100 6.668 3.334 3.334 0 000-6.668zm0 1.17a2.164 2.164 0 110 4.328 2.164 2.164 0 010-4.328z"
        fill="#fff"
      />
    </svg>
  );
}

export default Icon;
